import { DateTime } from 'luxon';

import { useAppSelector } from 'src/app/store';
import { useUserRoles } from 'src/features/auth/useUserRoles';
import { makeDispatchTimeRange } from 'src/features/dispatch/common/makeDispatchTimeRange';

import { useTripsActivatedWillCallCountRetrieveQuery } from '../external/bambi-api/bambiApi';

export function useUnassignedWillCallTripBadge() {
  const POOLING_INTERVAL = 60000; // 1 minute

  const isAddATripFormOpen = useAppSelector((state) => state.addATrip.isOpen);

  const { isRideOrderingPortalUser } = useUserRoles();
  const today = DateTime.now().toISODate();
  const range = makeDispatchTimeRange(today);

  const { data: unassignedWillCallTrips } =
    useTripsActivatedWillCallCountRetrieveQuery(
      {
        rangeEnd: range.end,
        rangeStart: range.start,
      },
      {
        pollingInterval: POOLING_INTERVAL,
        skip: isRideOrderingPortalUser || isAddATripFormOpen,
      }
    );

  return {
    unassignedWillCallTrips,
  };
}
