import { ReactNode } from 'react';

import useSDKEventHandler from './useSdkEventHandler';

export default function SendbirdConnectionManager({
  children,
}: {
  children: ReactNode;
}) {
  useSDKEventHandler();
  return <>{children}</>;
}
