import { minutesToSeconds, secondsToMinutes } from 'date-fns';
import { useFormContext } from 'react-hook-form';

import { FormFieldController } from 'src/common/FormField/FormFieldController';

import { FormField } from './FormField';
import { TooltipPresentationOptions } from './FormField/FormFieldHelperText';

interface SecondsToMinutesFieldProps {
  fieldPath: string;
  disabled?: boolean;
  label: string;
  tooltipHelper?: {
    text: string;
    presentation?: TooltipPresentationOptions;
  };
  inlineHelperText?: string;
}

export default function SecondsToMinutesField({
  fieldPath,
  disabled,
  label,
  tooltipHelper,
  inlineHelperText,
}: SecondsToMinutesFieldProps) {
  const form = useFormContext();
  const { setValue } = form;

  tooltipHelper = tooltipHelper && {
    text: tooltipHelper.text,
    presentation: {
      type: 'tooltip',
      ...tooltipHelper.presentation,
    },
  };

  return (
    <FormFieldController name={fieldPath}>
      {({ field, fieldState }) => {
        return (
          <>
            <FormField
              label={label}
              error={fieldState.error?.message?.toString()}
              helperText={tooltipHelper}
              type="number"
              inputProps={{
                id: fieldPath,
                ...field,
                disabled,
                onChange: (e) => {
                  const value = e.target.value;
                  setValue(
                    fieldPath,
                    value ? minutesToSeconds(parseInt(value)) : ''
                  );
                },
                value: field.value ? secondsToMinutes(field.value) : '',
              }}
            />
            {inlineHelperText && (
              <p className="pt-1 text-xs text-gray-500">{inlineHelperText}</p>
            )}
          </>
        );
      }}
    </FormFieldController>
  );
}
