import { PropsWithChildren, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { states } from 'src/common/data/states';
import { FormField } from 'src/common/FormField';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormFieldContainer } from 'src/common/FormField/FormFieldContainer';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { FormFieldSet } from 'src/common/FormField/FormFieldSet';
import { Button } from 'src/common/primitives/Button';
import { Select, SelectOption } from 'src/common/primitives/Select';
import { MultiSelect } from 'src/common/primitives/Select/MultiSelect/MultiSelect';

import { FormValues } from '../FormValues';
import { useNonDriverMembers } from './useNonDriverMembers';
import { validateDriversLicense } from './validateDriversLicense/validateDriversLicense';

interface DriverProfileFormStep1Props extends PropsWithChildren {
  nextButtonLabel?: string;
  validate?: boolean;
  onNextClick: () => void;
  onCancel: () => void;
}
export function DriverProfileFormStep1({
  nextButtonLabel,
  validate = true,
  onNextClick,
  onCancel,
  children,
}: DriverProfileFormStep1Props) {
  const [searchQuery, setSearchQuery] = useState<string>();
  const { members } = useNonDriverMembers(searchQuery);
  const form = useFormContext<FormValues>();

  const onNavigateToStep2 = async () => {
    if (!validate) {
      return onNextClick();
    }

    const isValid = await form.trigger();
    if (isValid) {
      onNextClick();
    }
  };
  const getSelectedOption = (val: string): SelectOption[] => {
    const member = members.find((member) => member.id === val);
    if (member) {
      return [
        {
          value: member.id,
          label: `${member.first_name} ${member.last_name}`,
        },
      ];
    }

    const { member_id, first_name, last_name } = form.getValues();
    return [
      {
        value: member_id || '',
        label: `${first_name} ${last_name}`,
      },
    ];
  };

  const editable = !form.getValues().deleted_at;

  return (
    <div className="mt-4 flex flex-col gap-4">
      <FormFieldController
        name="member_id"
        rules={{ required: 'Driver is required' }}
      >
        {({ field, fieldState }) => {
          return (
            <FormFieldContainer colSpan={12}>
              <DefaultFormFieldLayout
                label="Select a driver"
                error={fieldState.error?.message?.toString()}
                inputProps={{ id: 'member_id' }}
              >
                <MultiSelect
                  allowNew={false}
                  query={searchQuery}
                  searchable
                  placeholder="Select a member to add as a driver"
                  options={
                    field.value
                      ? getSelectedOption(field.value)
                      : members.map((member) => ({
                          value: member.id,
                          label: `${member.first_name} ${member.last_name}`,
                        }))
                  }
                  disabled={!!form.getValues().id}
                  selected={field.value ? getSelectedOption(field.value) : []}
                  onChange={(value) => {
                    const singleValue = value[0] as SelectOption | undefined;
                    const member = members.find(
                      (member) => member.id === singleValue?.value
                    );
                    form.setValue('first_name', member?.first_name || '');
                    form.setValue('last_name', member?.last_name || '');
                    field.onChange(singleValue?.value);
                  }}
                  setQuery={setSearchQuery}
                  // TODO not supported in multiselect
                  // error={fieldState.error?.message?.toString()}
                />
              </DefaultFormFieldLayout>
            </FormFieldContainer>
          );
        }}
      </FormFieldController>
      <FormFieldSet
        legend="License Information"
        Fields={
          <>
            <FormFieldController
              name="license_number"
              rules={{
                validate: (value) => {
                  if (!value) {
                    return 'License Number is required';
                  }

                  const state = form.getValues('license_state');

                  if (state && value) {
                    const isValid = validateDriversLicense(value, state);
                    if (!isValid) {
                      return `Invalid license number for ${state}`;
                    }
                  }

                  return true;
                },
              }}
            >
              {({ field, fieldState }) => {
                return (
                  <FormField
                    disabled={!editable}
                    label="License Number"
                    error={fieldState.error?.message?.toString()}
                    type="text"
                    inputProps={{
                      id: 'license-number',
                      placeholder: 'e.g. 123455678',
                      ...field,
                      disabled: !editable,
                      readOnly: !editable,
                      onChange: (e) => {
                        const cleanedValue = e.target.value.replace(
                          /[-\s]/g,
                          ''
                        );
                        field.onChange(cleanedValue);
                      },
                    }}
                    helperText="Enter without dashes or spaces"
                  />
                );
              }}
            </FormFieldController>
            <FormFieldController
              name="license_dob"
              rules={{ required: 'Date of Birth is required' }}
            >
              {({ field, fieldState }) => {
                return (
                  <FormField
                    disabled={!editable}
                    label="Driver Date of Birth"
                    error={fieldState.error?.message?.toString()}
                    type="date"
                    inputProps={{
                      ...field,
                      disabled: !editable,
                      readOnly: !editable,
                      id: 'date-of-birth',
                      placeholder: 'Select a date',
                    }}
                  />
                );
              }}
            </FormFieldController>
            <FormFieldController
              name="license_state"
              rules={{ required: 'Issued State is required' }}
            >
              {({ field, fieldState }) => {
                return (
                  <FormFieldContainer colSpan={6}>
                    <DefaultFormFieldLayout
                      label="Issued State"
                      error={fieldState.error?.message?.toString()}
                      inputProps={{ id: 'issued-state' }}
                    >
                      <Select
                        disabled={!editable}
                        placeholder="Select State"
                        options={states}
                        {...field}
                        onChange={(value?: SelectOption) => {
                          field.onChange(value?.value);
                        }}
                        error={fieldState.error?.message?.toString()}
                      />
                    </DefaultFormFieldLayout>
                  </FormFieldContainer>
                );
              }}
            </FormFieldController>
            <FormFieldController
              name="license_expiration_date"
              rules={{ required: 'Expiration Date is required' }}
            >
              {({ field, fieldState }) => {
                return (
                  <FormField
                    disabled={!editable}
                    label="Expiration Date"
                    error={fieldState.error?.message?.toString()}
                    type="date"
                    inputProps={{
                      ...field,
                      disabled: !editable,
                      readOnly: !editable,
                      id: 'expiration-date',
                      placeholder: 'Select a date',
                    }}
                  />
                );
              }}
            </FormFieldController>
            <FormFieldController name="can_be_scheduled">
              {({ field, fieldState }) => {
                return (
                  <FormField
                    disabled={!editable}
                    label="Is this driver active?"
                    error={fieldState.error?.message?.toString()}
                    type="switch"
                    inputProps={{
                      disabled: !editable,
                      readOnly: !editable,
                      id: 'can_be_scheduled',
                      onChange: (value) => {
                        field.onChange(value);
                      },
                      value: '',
                      checked: field.value,
                    }}
                  />
                );
              }}
            </FormFieldController>
          </>
        }
      />

      {children}
      <div className="grid grid-cols-2 gap-2">
        <Button
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          className="w-full flex-grow"
          variant="primary"
          onClick={onNavigateToStep2}
        >
          {nextButtonLabel ? nextButtonLabel : 'Next'}
        </Button>
      </div>
    </div>
  );
}
