import { FeeLabelPDF } from './components/FeeLabelPDF';
import { PriceSummaryData } from './types';

export function StairFeeDetailsPDF({
  data,
}: {
  data?: PriceSummaryData | null;
}) {
  return (
    <>
      <FeeLabelPDF label={'Fee Type'} value={data?.fee_type} />
      <FeeLabelPDF label={'Minimum Steps'} value={data?.minimum_steps} />
      <FeeLabelPDF label={'Steps'} value={data?.steps} />
    </>
  );
}
