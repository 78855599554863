import { useCallback, useEffect, useState } from 'react';

import { keyBy } from 'lodash-es';

import { useFeature } from 'src/app/FeatureToggle';
import { useAppDispatch } from 'src/app/store';
import {
  InvoiceNestedLineItem,
  InvoiceRead,
  InvoiceTripsRead,
  LineItemTypeEnum,
  useLazyInvoicesTripsListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { HydratedTripLineItem } from './forms/cells/types';

export interface HydratedInvoice extends Omit<InvoiceRead, 'line_items'> {
  line_items: HydratedTripLineItem[];
}

type UseHydratedInvoice = {
  isLoading: boolean;
  data: HydratedInvoice | null;
  error: string | null;
};

export function useHydratedInvoice(
  invoice: InvoiceRead | null
): UseHydratedInvoice {
  const [isLoading, setLoading] = useState(true);
  const [hydratedInvoice, setHydratedInvoice] = useState<HydratedInvoice>();
  const {
    isEnabled: isPricingFeatureEnabled,
    isLoading: isPricingfeatureLoading,
  } = useFeature('pricing version 1');
  const [error, setError] = useState(null);

  const hydrate = useInvoiceHydrator(invoice, isPricingFeatureEnabled);

  useEffect(() => {
    if (!invoice || isPricingfeatureLoading) {
      return;
    }

    // Support for invoices created from trip rows, these invoices are prehydrated
    const areLineItemsHydrated = invoice.line_items.some((lineItem) => {
      return lineItem.trip && typeof lineItem.trip !== 'string';
    });

    if (areLineItemsHydrated) {
      setLoading(false);
      setHydratedInvoice(invoice as unknown as HydratedInvoice);
      return;
    }

    setLoading(true);

    hydrate()
      .then((result) => {
        // No items were able to be hydrated
        if (!result) {
          setHydratedInvoice({
            ...invoice,
            line_items: [],
          });
          return;
        }

        // Filter out line items that couldn't be hydrated, can happen when trip is deleted after invoiced
        const lineItems: HydratedTripLineItem[] = [];

        result.forEach((settledItem) => {
          if (!settledItem.hydrated) {
            return;
          }

          lineItems.push(settledItem as HydratedTripLineItem);
        });

        setHydratedInvoice({
          ...invoice,
          line_items: lineItems,
        });
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, hydrate, invoice, isPricingfeatureLoading]);

  if (!invoice) {
    return {
      isLoading: false,
      data: null,
      error: null,
    };
  }

  return {
    isLoading,
    data: hydratedInvoice ?? null,
    error,
  };
}

interface PricingSummaryLineItemStub
  extends Omit<InvoiceNestedLineItem, 'line_item_type'> {
  line_item_type?: LineItemTypeEnum | 'price_summary';
  created_at: string;
}

export function useInvoiceHydrator(
  invoice: InvoiceRead | null,
  isPricingFeatureEnabled: boolean
) {
  const [fetchTripsAndSummary] = useLazyInvoicesTripsListQuery();
  const dispatch = useAppDispatch();

  const hydrate = useCallback(async () => {
    if (!invoice) {
      return;
    }

    // Pregenerate list with pricing summary item
    const items: Array<InvoiceNestedLineItem | PricingSummaryLineItemStub> = [];
    invoice.line_items.forEach((item) => {
      items.push(item);

      if (item.line_item_type === 'trip' && isPricingFeatureEnabled) {
        items.push({
          ...item,
          line_item_type: 'price_summary',
          created_at: new Date().toLocaleString(),
          description: '',
        });
      }
    });

    try {
      const data = await fetchTripsAndSummary({ id: invoice.id }).unwrap();
      const tripMap = keyBy(data, 'id');

      return items.map((item) => {
        if (!item.trip) {
          return {
            ...item,
            hydrated: false,
          };
        }

        // Possible that the line item contains a trip that wasn't able to be
        // fetched for some reason, so cast as undefined in this case
        const tripResult = tripMap[item.trip] as InvoiceTripsRead | undefined;

        if (!tripResult) {
          return {
            ...item,
            hydrated: false,
          };
        }

        const pricingResult =
          isPricingFeatureEnabled && item.line_item_type === 'price_summary'
            ? tripResult.price_summary
            : null;

        return {
          ...item,
          trip: tripResult,
          invoice: invoice.id,
          price_summary: pricingResult,
          hydrated: true,
        };
      });
    } catch (e) {
      dispatch(
        show({
          type: 'error',
          title: 'Failed to fetch trip data for invoice',
          description: formatServerError(e),
        })
      );
      return;
    }
  }, [dispatch, fetchTripsAndSummary, invoice, isPricingFeatureEnabled]);

  return hydrate;
}
