import { useCallback, useState } from 'react';

import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';
import { DateRange } from 'react-day-picker';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { useAppDispatch } from 'src/app/store';
import DateRangePicker from 'src/common/date/DateRangePicker';
import { formatDateAsTodayOrMonthDay } from 'src/common/date/formatDateAsTodayOrMonthDay';
import { useLazyGetInvoicesExportListAsCsvQuery } from 'src/common/external/bambi-api/emptyApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { MultiSelectPayerField } from 'src/common/MultiSelectPayerContextField';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { setExportedCondensedReportData } from '../../../invoice.slice';
import { InvoiceExportCondensedReport } from '../../../types';

type InvoiceExportForm = {
  dateRange: DateRange;
  payers: string[];
};

interface CondensedPDFStep1Props {
  onClose?: () => void;
  next: () => void;
  prev: () => void;
}

export function CondensedPDFStep1({
  onClose,
  next,
  prev,
}: CondensedPDFStep1Props) {
  const form = useForm<Partial<InvoiceExportForm>>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [trigger] = useLazyGetInvoicesExportListAsCsvQuery();
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    onClose?.();
    form.reset();
  }, [onClose, form]);

  const handleSubmit = form.handleSubmit(async (data) => {
    if (!data.dateRange?.from) {
      setError('Please select a start date');
      return;
    }
    setLoading(true);

    try {
      setError(undefined);
      const rangeStart = DateTime.fromJSDate(data.dateRange.from).toISODate();

      const toDate =
        data.dateRange.to && DateTime.fromJSDate(data.dateRange.to).toISODate();

      const sameDayRange = rangeStart === toDate;

      const rangeEnd = sameDayRange ? undefined : toDate;

      const result = await trigger({
        rangeStart,
        rangeEnd,
        tripPayerIn: data.payers,
        format: 'pdf',
        outputType: 'condensed',
      }).unwrap();

      if (!result?.records.length) {
        setError('No results found');
        return;
      }

      dispatch(
        setExportedCondensedReportData(result as InvoiceExportCondensedReport)
      );

      next();
    } catch (error) {
      const errMsg = 'There was an error downloading the data.';
      setError(errMsg);
    } finally {
      setLoading(false);
    }
  });

  // TODO support sort by: passenger, trip date

  return (
    <div>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {loading ? (
        <div className="flex w-full flex-row justify-center">
          <div className="flex flex-col items-center gap-2">
            <LoadingIndicator />
            Gathering data
          </div>
        </div>
      ) : (
        <FormProvider {...form}>
          <div className="mt-2 flex flex-col gap-4">
            <strong>Filters:</strong>
            <div>
              <div className="text-sm font-medium text-gray-700">
                Select the date range *
              </div>
              <div className="flex items-center justify-between gap-2 pt-2">
                <Controller
                  control={form.control}
                  name="dateRange"
                  render={({ field }) => {
                    let fromDate = '';
                    let toDate = '';

                    if (field.value?.from) {
                      fromDate = formatDateAsTodayOrMonthDay(
                        DateTime.fromJSDate(field.value.from).toISODate(),
                        true
                      );
                    }

                    if (field.value?.to) {
                      toDate = formatDateAsTodayOrMonthDay(
                        DateTime.fromJSDate(field.value.to).toISODate(),
                        true
                      );
                    }

                    return (
                      <DateRangePicker
                        side="left"
                        triggerElement={
                          <Button>
                            <AdjustmentsHorizontalIcon className="mr-2 h-4 w-4" />{' '}
                            {field.value
                              ? ` ${fromDate}${toDate ? ' - ' + toDate : ''}`
                              : 'Select Date'}
                          </Button>
                        }
                        onDateRangeChange={(r) => {
                          field.onChange(r);
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <MultiSelectPayerField
              fieldPath="payers"
              fieldLabel="Payers (leave empty to select all payers)"
              rules={{}}
              canSelectAll={false}
            />
          </div>
          {/* TODO, eventually support secondary sort here */}
          {/* <div className="mt-2 flex flex-col gap-4">
            <strong>Sorting:</strong>
          </div>
          <div>TODO</div> */}
          <div className="grid grid-cols-2 gap-4 pt-6">
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              dataTestId="select-pdf-data"
              variant="primary"
              onClick={handleSubmit}
            >
              Gather data
            </Button>
          </div>
        </FormProvider>
      )}
    </div>
  );
}
