import { FeeLabelPDF } from './components/FeeLabelPDF';
import { PriceSummaryData } from './types';

export function WaitTimeDetailsPDF({
  data,
}: {
  data?: PriceSummaryData | null;
}) {
  return (
    <>
      <FeeLabelPDF label="Period" value={data?.period} />
      <FeeLabelPDF label="Free Minutes" value={data?.free_minutes} />
      <FeeLabelPDF label="Minutes" value={data?.minutes} />
    </>
  );
}
