import { View } from '@react-pdf/renderer';

import { FeeLabelPDF } from './components/FeeLabelPDF';

export function AdditionalRequirementDetailsPDF({
  label,
  property,
}: {
  label: string;
  property?: number | string;
}) {
  return (
    <>
      <View />
      <View />
      <FeeLabelPDF label={label} value={property} />
    </>
  );
}
