import { useAppSelector } from 'src/app/store';
import { convertISODateTimePartsToUTCISO } from 'src/common/date/convertISODateTimePartsToUTCISO';

import { Trip } from '../../FormValues';

export default function useWaitAndReturnPricingData(
  trips: Trip[],
  tripIndex: number
) {
  const selectedTrip = useAppSelector((state) => state.addATrip.selectedTrip);

  if (selectedTrip?.is_wait_and_return_inbound) {
    return {
      tripId: selectedTrip.id,
    };
  }

  if (
    trips.length > 1 &&
    trips[tripIndex].is_wait_and_return_inbound &&
    trips[tripIndex - 1]?.is_wait_and_return_outbound
  ) {
    const pickupDate = trips[tripIndex - 1].timing.date;
    const pickupTime = trips[tripIndex - 1].timing.pickup_time;
    return {
      firstLegScheduledPickupAt: convertISODateTimePartsToUTCISO({
        date: pickupDate,
        time: pickupTime,
      }),
      firstLegPickupPlaceId: trips[tripIndex - 1].pickupLocation.address.value,
      firstLegDropoffPlaceId:
        trips[tripIndex - 1].dropoffLocation.address.value,
    };
  }

  return null;
}
