import { useEffect, useState } from 'react';

import { useAppSelector } from 'src/app/store';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import { useTripsRequestedCountRetrieveQuery } from '../external/bambi-api/bambiApi';

export const useRequestedTripBadge = (poll = true) => {
  const POOLING_INTERVAL = 60000; // 1 minute
  const isAddATripFormOpen = useAppSelector((state) => state.addATrip.isOpen);
  const { isRideOrderingPortalUser } = useUserRoles();
  const [showRequestedTripBadge, setShowRequestedTripBadge] = useState(false);

  const { data: requestedTrips, isLoading: isRequestedCountLoading } =
    useTripsRequestedCountRetrieveQuery(undefined, {
      skip: isRideOrderingPortalUser || isAddATripFormOpen,
      pollingInterval: poll ? POOLING_INTERVAL : undefined,
    });

  useEffect(() => {
    if (
      !isRideOrderingPortalUser &&
      !isRequestedCountLoading &&
      requestedTrips?.count
    ) {
      setShowRequestedTripBadge(true);
    } else {
      setShowRequestedTripBadge(false);
    }
  }, [
    isRideOrderingPortalUser,
    isRequestedCountLoading,
    requestedTrips?.count,
  ]);

  return {
    showRequestedTripBadge,
    requestedTrips,
  };
};
