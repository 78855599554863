import { useMemo } from 'react';

import { useAppSelector } from 'src/app/store';
import { ModalProps } from 'src/common/primitives/Modal';
import { Wizard, WizardStep } from 'src/common/wizard/Wizard';

import { CondensedPDFStep1 } from './steps/CondensedPDFStep1';
import { CondensedPDFStep2 } from './steps/CondensedPDFStep2';

interface DownloadInvoicesCondensedPDFProps {
  onClose: ModalProps['onClose'];
}

export function DownloadInvoicesCondensedPDF({
  onClose,
}: DownloadInvoicesCondensedPDFProps) {
  const skipExportGatherDataStep = useAppSelector(
    (state) => state.invoice.skipExportGatherDataStep
  );

  const steps = useMemo(() => {
    const allSteps: WizardStep[] = [
      {
        title: 'Select data',
        render: ({ next, prev }) => (
          <CondensedPDFStep1 next={next} prev={prev} onClose={onClose} />
        ),
      },
      {
        title: 'Generation options',
        render: ({ next, prev }) => (
          <CondensedPDFStep2 next={next} prev={prev} onClose={onClose} />
        ),
      },
    ];

    if (skipExportGatherDataStep) {
      allSteps.shift();
    }

    return allSteps;
  }, [onClose, skipExportGatherDataStep]);

  return <Wizard testId="download-invoices-condensed-pdf" steps={steps} />;
}
