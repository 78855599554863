import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { useCallback, useRef } from 'react';

import {
  Eventcalendar,
  luxonTimezone,
  MbscCalendarEvent,
  MbscCalendarEventData,
  MbscEventcalendarView,
  MbscResource,
} from '@mobiscroll/react';
import { IValidateProps } from '@mobiscroll/react/dist/src/core/util/datetime';
import * as luxon from 'luxon';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch, useAppSelector } from 'src/app/store';
import { useAuth } from 'src/features/auth/useAuth';

import { dispatcherSlice } from '../dispatcher.slice';
import { AssignmentEmptyState } from './AssignmentEmptyState';
import { DispatchCalendarAssignment } from './DispatchCalendarAssignment';
import { DispatchCalendarHeader } from './DispatchCalendarHeader';
import { DispatchCalendarEvent } from './DispatchCalenderEvent';
import { getMobiscrollSelectedDate } from './getMobiscrollSelectedDate';
import useDynamicCss, { css } from './useDynamicCss';
import { useOnEventCreated } from './useOnEventCreated';
import { useOnEventUpdated } from './useOnEventUpdated';

luxonTimezone.luxon = luxon;
// Important -- prevents "Invalid unit zone" error
// Mobiscroll needs to determine the version I believe
// due to using a custom createObject implementation
// luxon changed the signature of createObject in v2
luxonTimezone.version = 3;

// TODO: Move into dispatcher state as CADSettings - Note hack below
const view: MbscEventcalendarView = {
  schedule: {
    type: 'day',
    allDay: false,
    timeCellStep: 30,
    timeLabelStep: 60,
  },
};

const selectSelectedDate = (state: RootState) => state.dispatcher.selectedDate;

type DispatchCalendarProps = {
  resources: MbscResource[];
  events: MbscCalendarEvent[];
  invalids: IValidateProps[];
  // Some bambi specific config might become apparent but for now just mirroring mobiscroll's props
  dragAndDropConfig?: {
    dragToMove?: boolean;
    externalDrop?: boolean;
  };
};

export function DispatchCalendar({
  resources = [],
  events = [],
  invalids = [],
  dragAndDropConfig = {
    dragToMove: false,
    externalDrop: false,
  },
}: DispatchCalendarProps) {
  const dispatch = useAppDispatch();
  const calendarRef = useRef<any>();
  useDynamicCss(calendarRef);
  const selectedDate = useSelector(selectSelectedDate);
  const previewEvents = useAppSelector(
    (state) => state.dispatcher.previewEvents
  );
  const { currentOrganizationHeadQuarters } = useAuth();

  // selecting the first trip in event is good enough
  // to navigate to the trip in the calendar
  const selectedTripEvent =
    previewEvents && previewEvents.length > 0
      ? events.find((event) => event.id === previewEvents[0].trip.id)
      : undefined;

  if (selectedTripEvent && calendarRef.current?.navigateToEvent) {
    calendarRef.current?.navigateToEvent(selectedTripEvent);
  }

  const renderHeader = useCallback(() => <DispatchCalendarHeader />, []);
  const renderResource = useCallback(
    (resource: MbscResource) => (
      <DispatchCalendarAssignment assignment={resource.assignment} />
    ),
    []
  );
  const renderEvent = useCallback(
    (event: MbscCalendarEventData) => <DispatchCalendarEvent event={event} />,
    []
  );

  const isEventCalendarScrollable = useAppSelector(
    (state) => state.dispatcher.isEventCalendarScrollable
  );

  const onEventUpdated = useOnEventUpdated();
  const onEventCreated = useOnEventCreated();
  if (!view.schedule) throw new Error('No scheduler view configured');

  // Make Mobiscroll only show 1 day
  // When resources exist this isn't necessary, but if no resources exist
  // it falls back to showing m-f
  // if (resources?.length < 1) {
  //   const viewDay = DateTime.fromISO(selectedDate).day;
  //   view.schedule.startDay = viewDay;
  //   view.schedule.endDay = viewDay;
  // }
  return (
    <>
      <style>{css}</style>
      <div
        className={`dispatch-calendar-root isolate flex h-full max-h-[90vh] max-w-full flex-col gap-2 px-1 pt-3 ${
          isEventCalendarScrollable ? 'scrollable' : ''
        }`}
        data-testid="dispatch-calendar-root"
      >
        {resources.length < 1 && <AssignmentEmptyState />}
        <Eventcalendar
          ref={calendarRef}
          theme="ios"
          themeVariant="light"
          view={view}
          data={events.length > 0 ? events : [{ id: 1, resource: 1 }]}
          resources={resources.length > 0 ? resources : undefined}
          invalid={invalids}
          renderHeader={renderHeader}
          renderResource={renderResource}
          renderScheduleEvent={renderEvent}
          width="100%"
          selectedDate={getMobiscrollSelectedDate(selectedDate)}
          refDate={getMobiscrollSelectedDate(selectedDate)}
          dataTimezone="utc"
          timezonePlugin={luxonTimezone}
          displayTimezone={currentOrganizationHeadQuarters?.time_zone_name}
          showEventBuffer
          dragToMove={dragAndDropConfig.dragToMove}
          // We don't want to edit time just yet - no one has asked for it
          // You'll need to update onEventUpdated to persist the new times
          dragInTime={false}
          externalDrop={dragAndDropConfig.externalDrop}
          // The difference between onEventUpdate and onEventUpdated is only clear when seeing both together
          // onEventUpdate is called before the event is rendered -- Returning false will prevent the event from being updated
          // onEventUpdated is called after the event is rendered successfully
          // There is also onEventUpdateFailed which is called if the event update fails
          // See https://mobiscroll.com/docs/react/eventcalendar/api#event-onEventUpdated
          onEventUpdated={onEventUpdated}
          onEventDragStart={() => {
            dispatch(dispatcherSlice.actions.onCalendarEventDragStart());
          }}
          onEventDragEnter={(newEvent) => {
            // Timeout is important. onEventCreate (not created) is called too late
            // onEventDragEnter is technically too early
            // onEventDragEnd is likely called when the element still exists, but
            // the user needs to see where the event is going to be placed before
            // dropping it.
            // Not a huge fan, but this works with selectedDate, etc.
            // We may have to take firmer control of mobiscroll's scroll behavior
            // The element has a class "mbsc-schedule-grid-scroll".
            // I've created a post on mobiscroll's forum to see if someone has a better idea
            // https://forum.mobiscroll.com/t/eventcalendar-react-external-dropping-of-event-that-already-has-a-time-and-scrolling-to-that-event/2215
            // I've tried:
            // - Using mobiscroll's navigate and navigateToEvent methods
            // - Scrolling to newEvent.domEvent.target - target is an element under where the
            //   cursor is when the event enters the calendar, so not useful
            // - Setting mbsc-schedule-grid-scroll to the position of the calendar event which is
            //   how I discovered the above. It worked, but scrollIntoView is what we want
            let runCounter = 0;
            const runLimit = 50;
            (function maybeScroll() {
              const newEventElement = document.querySelector(
                `#event-${newEvent.event.id}`
              );

              if (newEventElement) {
                newEventElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                });
                return;
              }
              if (runCounter > runLimit) {
                return;
              }
              requestAnimationFrame(maybeScroll);
              runCounter += 1;
            })();
          }}
          onEventDragEnd={() => {
            dispatch(dispatcherSlice.actions.onCalendarEventDragEnd());
          }}
          onEventCreated={onEventCreated}
        />
      </div>
    </>
  );
}
