import { TrashIcon } from '@radix-ui/react-icons';
import { createColumnHelper } from '@tanstack/react-table';

import { HelperTextPopover } from 'src/common/FormField/FormFieldHelperText';
import { Button } from 'src/common/primitives/Button';

import { LineItemDescriptionDisplayCell } from './cells/LineItemDescriptionDisplayCell';
import { LineItemDescriptionEditCell } from './cells/LineItemDescriptionEditCell';
import { PriceDisplayCell } from './cells/PriceDisplayCell';
import { PriceEditCell } from './cells/PriceEditCell';
import { TripDisplayCell } from './cells/TripDisplayCell';
import { TripPriceSummaryCell } from './cells/TripPriceSummaryCell';
import { shouldDisplayTripSummaryPrice } from './cells/utils';
import { InvoiceNestedLineItemWithId } from './InvoiceFormContext';

const columnHelper = createColumnHelper<InvoiceNestedLineItemWithId>();

const itemsColumn = columnHelper.display({
  header: 'Items',
  meta: {
    enableHeaderMenu: false,
  },
  cell({
    row,
    table: {
      options: { meta },
    },
  }) {
    const lineItem = row.original;

    if (
      lineItem.line_item_type === 'price_summary' &&
      lineItem.trip.price_summary
    ) {
      return shouldDisplayTripSummaryPrice(
        lineItem.trip.price_summary,
        lineItem.trip
      ) ? (
        <TripPriceSummaryCell summary={lineItem.trip.price_summary} />
      ) : null;
    }

    if (lineItem.line_item_type !== 'trip') {
      if (lineItem.line_item_type === 'price_summary') {
        return null;
      }

      if (meta?.isEditingRow?.(lineItem)) {
        return (
          <LineItemDescriptionEditCell
            key={`${lineItem.id}-lineitem-description-edit`}
            lineItem={lineItem}
          />
        );
      }

      return (
        <LineItemDescriptionDisplayCell
          key={`${lineItem.id}-lineitem-description-display`}
          lineItem={lineItem}
        />
      );
    }

    return (
      <TripDisplayCell
        key={`${lineItem.id}-lineitem-trip-display`}
        trip={lineItem.trip}
      />
    );
  },
});

const priceColumn = columnHelper.accessor('amount_cents', {
  header: 'Price',
  meta: {
    enableHeaderMenu: false,
    columnClassName: 'align-bottom',
    headerWidth: 75,
  },
  cell({
    getValue,
    row,
    table: {
      options: { meta },
    },
  }) {
    const price = getValue();
    const lineItem = row.original;
    const isTrip = lineItem.line_item_type === 'trip';
    const isEditing = meta?.isEditingRow?.(lineItem);

    if (lineItem.line_item_type === 'price_summary') {
      const dynamicTripPrice = lineItem.trip.price_summary?.total ?? 0;
      const tripPrice = price;
      const displayTripSummaryPrice = shouldDisplayTripSummaryPrice(
        lineItem.trip.price_summary ?? null,
        lineItem.trip
      );
      const displayPrice = displayTripSummaryPrice
        ? dynamicTripPrice
        : tripPrice;

      const isPriceSummaryMissing = !lineItem.trip.price_summary;

      const popoverText = isPriceSummaryMissing
        ? 'No price summary applied to trip.'
        : `Price breakdown unavailable: The total price for this trip doesn't match the system's calculated breakdown. Please review or update the trip price.`;

      return (
        <div className="flex flex-row gap-1">
          <PriceDisplayCell
            key={`${lineItem.id}-lineitem-price-display`}
            isTrip={false}
            displayPrice={displayPrice}
            tripId={lineItem.trip.id}
            lineItem={lineItem}
          />
          {!displayTripSummaryPrice || isPriceSummaryMissing ? (
            <div
              className="print:hidden"
              data-testid="price-summary-override-tooltip"
            >
              <HelperTextPopover
                isStatic
                text={popoverText}
                textPosition="absolute w-[250px] whitespace-normal"
              />
            </div>
          ) : null}
        </div>
      );
    }

    if (isTrip || !isEditing) {
      return (
        <PriceDisplayCell
          key={`${lineItem.id}-lineitem-price-display`}
          isTrip={lineItem.line_item_type === 'trip'}
          tripId={lineItem.trip.id}
          lineItem={lineItem}
        />
      );
    }

    return (
      <PriceEditCell
        key={`${lineItem.id}-lineitem-price-edit`}
        lineItem={lineItem}
      />
    );
  },
});

const managementColumn = columnHelper.display({
  header: ' ',
  meta: {
    enableHeaderMenu: false,
  },
  cell({
    row,
    table: {
      options: { meta },
    },
  }) {
    const lineItem = row.original;

    if (lineItem.line_item_type === 'price_summary') {
      return null;
    }

    if (lineItem.line_item_type === 'trip' || !meta?.isEditingRow?.(lineItem)) {
      return null;
    }

    return (
      <Button
        className="flex gap-2"
        onClick={() => meta.deleteRow?.(row.index, row.original)}
      >
        <TrashIcon className="w-5" />
      </Button>
    );
  },
});

export const allColumns = [itemsColumn, priceColumn, managementColumn];
