import { DataGrid } from 'src/common/DataGrid';

import { InvoiceRefundWithPaymentMethod } from './cells/useHydratePaymentRefunds';
import { columns } from './refundColumns';

interface RefundsDataGridProps {
  refunds: InvoiceRefundWithPaymentMethod[];
}

export function RefundsDataGrid({ refunds }: RefundsDataGridProps) {
  return (
    <DataGrid
      tableId="refunds"
      dataTestId="refunds-data-grid"
      columns={columns}
      data={refunds}
      containerClassName="print:max-w-[100vw]"
      cellClassName="align-top"
      includePagination={false}
    />
  );
}
