import React from 'react';

import { CalendarIcon } from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';

import { TextInput } from 'src/common/primitives/TextInput';

import { DatePicker } from '../date/DatePicker';
import { Button } from '../primitives/Button';
import { DefaultFormFieldLayout } from './DefaultFormFieldLayout';
import { FormFieldProps } from './FormField';

// Hide the default calendar icon in the date input
const dateInputStyles = `
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }
  
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type="date"] {
    -moz-appearance: textfield;
  }
`;

export const FormFieldDate = React.forwardRef(function _FormFieldDate<T>(
  { inputProps, onChange, label, error, inline, helperText }: FormFieldProps<T>,
  forwardedRef: React.Ref<HTMLInputElement>
) {
  const handleDateChange = (val: DateTime | undefined) => {
    if (val) {
      const dateString = val.toFormat('yyyy-MM-dd');
      const syntheticEvent = {
        target: { value: dateString, name: inputProps.name },
      } as React.ChangeEvent<HTMLInputElement>;

      inputProps.onChange?.(syntheticEvent);
      onChange?.(dateString);
    }
  };

  const parseDateValue = (value: any): DateTime | undefined => {
    if (!value) return undefined;
    if (typeof value === 'string') return DateTime.fromISO(value);
    if (value instanceof Date) return DateTime.fromJSDate(value);
    return undefined;
  };

  return (
    <DefaultFormFieldLayout
      label={label}
      error={error}
      inputProps={inputProps}
      inline={inline}
      helperText={helperText}
    >
      <style>{dateInputStyles}</style>
      <div className="flex items-center gap-2">
        <TextInput
          {...inputProps}
          onChange={(e) => {
            inputProps.onChange?.(e);
            onChange?.(e.target.value);
          }}
          type="date"
          ref={forwardedRef}
          data-testid="date-input"
          className={`block w-full rounded-md shadow-sm disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm ${
            error
              ? 'border-red-300 focus:border-red-300 focus:ring-red-500'
              : 'focus:border-indigo-500 focus:ring-indigo-500 border-gray-300'
          }`}
        />
        <DatePicker
          triggerElement={
            <Button
              variant="ghost-icon"
              dataTestId="calendar-btn"
              disabled={inputProps.disabled}
            >
              <CalendarIcon className="datepicker-icon h-6 w-6" />
            </Button>
          }
          onChange={handleDateChange}
          value={parseDateValue(inputProps.value)}
        />
      </div>
    </DefaultFormFieldLayout>
  );
});
