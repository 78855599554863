import { startCase } from 'lodash-es';

import { errorMessagePretty } from './errorMessagePretty';
import genericErrorMessages from './genericErrorMessages';
import { resolveErrorStatus } from './resolveErrorStatus';

export const genericErrorMessage =
  'An unexpected error occurred. Please try again or contact support.';

export const formatServerError = (error: any, parseHtmlErrors?: boolean) => {
  try {
    const status = resolveErrorStatus(error);

    if (status in genericErrorMessages && !parseHtmlErrors) {
      return genericErrorMessages[status];
    }

    if (status === 500 && parseHtmlErrors) {
      // Creating a temporary fragment to query the response
      const frag = document.createDocumentFragment();
      const children = document.createElement('div');
      children.innerHTML = error.data;
      frag.appendChild(children);

      const serverError = frag.querySelector('.exception_value');
      if (serverError) {
        return serverError.textContent ?? genericErrorMessages[status];
      }

      return genericErrorMessages[status];
    }

    if (error.status === 400) {
      if (error.data.errors && error.data.errors.length > 0) {
        let errorMessages = '';

        error.data.errors.forEach((err: any) => {
          const { attr, detail } = err;
          if (errorMessagePretty.has(detail)) {
            errorMessages += `${
              attr ? startCase(attr) + ':' : ''
            } ${errorMessagePretty.get(detail)} | `;
          } else {
            errorMessages += `${
              attr ? startCase(attr) + ':' : ''
            } ${detail} | `;
          }
        });

        if (errorMessages)
          return errorMessages.substring(0, errorMessages.length - 3).trim();
      } else {
        return JSON.stringify(error);
      }
    }
  } catch (error) {
    // since this method will be used inside try catch blocks,
    // this is the last line of defense to display a message
    // so skip the error and return the generic message
  }

  return genericErrorMessage;
};

export default formatServerError;
