import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IReportsState {
  filters: {
    range_start?: string;
    range_end?: string;
    payerIds: string[];
    driverIds: string[];
  };
}

export const initialReportsState: IReportsState = {
  filters: {
    payerIds: [],
    driverIds: [],
  },
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: initialReportsState,
  reducers: {
    setDateRange: (
      state,
      action: PayloadAction<{ range_start?: string; range_end?: string }>
    ) => {
      state.filters.range_start = action.payload.range_start;
      state.filters.range_end = action.payload.range_end;
    },
    setSelectedPayers: (state, action: PayloadAction<string[]>) => {
      state.filters.payerIds = action.payload;
    },
    setSelectedDrivers: (state, action: PayloadAction<string[]>) => {
      state.filters.driverIds = action.payload;
    },
    clearFilters: (state) => {
      state.filters = { ...initialReportsState.filters };
    },
  },
});
