import {
  CalendarIcon,
  EllipsisVerticalIcon,
  LockClosedIcon,
  MapPinIcon,
  PencilIcon,
  ShareIcon,
} from '@heroicons/react/24/solid';
import { MbscCalendarEvent } from '@mobiscroll/react';
import { truncate } from 'lodash-es';

import { useFeature } from 'src/app/FeatureToggle';
import { useAppSelector } from 'src/app/store';
import { ACTIVE_TRIP_STATUSES } from 'src/common/ACTIVE_TRIP_STATUSES';
import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { SimpleTooltip } from 'src/common/primitives/Tooltip';
import { reassignableTripStatuses } from 'src/common/tripStatuses';

import {
  RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES,
  RunBambiRunCalendarEventType,
} from '../../common/RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES';
import { isExistingAssignmentBeingReassigned } from '../tripReassignmentUtils';
import { getTripCalendarTheme } from './getTripCalendarTheme';
import { TripDetailsPopover } from './TripDetailsPopover';
import { TripTitle } from './TripTitle';

type TripCalendarEventProps = {
  trip: DispatchTripRead | RunBambiRunTripRead | RunBambiRunReassignedTripRead;
  type?: RunBambiRunCalendarEventType;
};

export function TripCalendarEvent({ trip, type }: TripCalendarEventProps) {
  const isMultiloadFeatureResponse = useFeature('multi-loading');
  const theme = getTripCalendarTheme(trip, type);
  const selectedTripId = useAppSelector(
    (state) => state.dispatcher.selectedTripId
  );
  const previewEvents = useAppSelector(
    (state) => state.dispatcher.previewEvents
  );

  const opacityClass =
    type === RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES.EXISTING ||
    isExistingAssignmentBeingReassigned(trip, String(selectedTripId)) ||
    isWaitTimeTripBeingReassigned(trip, previewEvents)
      ? 'opacity-40'
      : 'opacity-100';

  const shouldShowAsMultiLoad =
    isMultiloadFeatureResponse.isEnabled &&
    'multiload_id' in trip &&
    trip.multiload_id &&
    !trip.id.includes('preview');
  return (
    <div className="h-full">
      <TripDetailsPopover trip={trip} type={type}>
        <div
          className={`flex h-full flex-row overflow-hidden p-1 text-xs ${opacityClass} 
          ${trip.status === 'en route' && 'border-t-4'}
          ${trip.status === 'at pickup' && 'border-l-4'}
          ${trip.status === 'transporting' && 'border-r-4'}
          ${trip.status === 'at dropoff' && 'border-b-4'}
          ${!ACTIVE_TRIP_STATUSES.includes(trip.status) && 'border-l-4'}
          ${reassignableTripStatuses.includes(trip.status) && 'cursor-grab'}
          `}
          style={{
            backgroundColor: theme.bg,
            borderColor: theme.border,
            borderStyle: shouldShowAsMultiLoad ? 'double' : theme.borderStyle,
            minHeight: '2rem',
            backgroundImage:
              shouldShowAsMultiLoad && !trip.id.includes('preview')
                ? `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='${theme.border.replace(
                    '#',
                    '%23'
                  )}' fill-opacity='0.27'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
                : '',
            backgroundRepeat: 'repeat',
            backgroundPositionY: '12px',
            backgroundPositionX: '12px',
            backgroundSize: '72px',
          }}
        >
          <EventBadges trip={trip} />
          <div className="flex w-full flex-1 flex-col gap-0.5">
            <div className="flex w-full justify-between">
              <div
                style={{
                  color: theme.text,
                  backgroundColor: theme.bg,
                }}
              >
                <TripTitle trip={trip} />
              </div>
            </div>
            <div>
              <div
                className="flex justify-start gap-1 text-xs"
                style={{
                  color: theme.text,
                  backgroundColor: theme.bg,
                }}
                title={trip.pickup_address}
              >
                <div>
                  <MapPinIcon className="h-4 w-4" />
                </div>
                <div>
                  {truncate(trip.pickup_address, {
                    length: 30,
                    omission: '...',
                  })}
                </div>
              </div>
              <span
                style={{
                  color: theme.text,
                  backgroundColor: theme.bg,
                }}
                className="flex h-4 w-4 items-center justify-start"
              >
                <EllipsisVerticalIcon className="h-3 w-3" />
              </span>
              <div
                className="flex justify-start gap-1 text-xs"
                style={{
                  color: theme.text,
                  backgroundColor: theme.bg,
                }}
              >
                <div>
                  {trip.appointment_at ? (
                    <CalendarIcon className="min-w-4 h-4 w-4" />
                  ) : (
                    <MapPinIcon className="min-w-4 h-4 w-4" />
                  )}
                </div>
                <div>
                  {truncate(trip.dropoff_address, {
                    length: 30,
                    omission: '...',
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </TripDetailsPopover>
    </div>
  );
}
function isWaitTimeTripBeingReassigned(
  trip: DispatchTripRead | RunBambiRunTripRead | RunBambiRunReassignedTripRead,
  previewEvents: MbscCalendarEvent[] | undefined
): boolean {
  const previewEventIds = previewEvents?.map((event) => event.id) || [];
  return previewEventIds.some((id) => id === `${trip.id}-preview`);
}

function EventBadges({ trip, type }: TripCalendarEventProps) {
  const isMultiloadFeatureResponse = useFeature('multi-loading');
  const theme = getTripCalendarTheme(trip, type);

  return (
    <div className="absolute -right-0.5 -top-1 flex gap-0.5">
      {'missing_any_required_signature' in trip &&
        trip.missing_any_required_signature && (
          <div className="rounded-full border border-red-600 bg-white p-0.5">
            <SimpleTooltip
              content="Driver missed collecting signature and needs to be alerted"
              trigger={
                <PencilIcon
                  className="h-3 w-3 text-red-600"
                  data-testid={`event-missed-signature-icon-${trip.id}`}
                />
              }
            />
          </div>
        )}
      {isMultiloadFeatureResponse.isEnabled &&
        'multiload_id' in trip &&
        trip.multiload_id && (
          <div
            title="Multi-loaded trip"
            data-testid="multi-load-trip-icon"
            style={{
              backgroundColor: theme.bg,
              color: theme.text,
              borderColor: theme.border,
              borderWidth: '1px',
            }}
            className="rounded-full p-0.5"
          >
            <MultiLoadIcon color={theme.text} />
          </div>
        )}
      {trip.is_locked && (
        <div
          style={{
            backgroundColor: theme.bg,
            color: theme.text,
            borderColor: theme.border,
            borderWidth: '1px',
          }}
          className="rounded-full p-0.5"
        >
          <LockClosedIcon className="h-3 w-3" />
        </div>
      )}
      {trip.correlation_id && (
        <div
          data-testid="multi-leg-trip-icon"
          style={{
            backgroundColor: theme.bg,
            color: theme.text,
            borderColor: theme.border,
            borderWidth: '1px',
          }}
          className="rounded-full p-0.5"
        >
          <SimpleTooltip
            content="This is a multi-leg trip"
            trigger={<ShareIcon className="h-3 w-3" />}
          />
        </div>
      )}
    </div>
  );
}

function MultiLoadIcon({ color }: { color: string }) {
  return (
    <>
      {/* <?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --> */}
      <svg
        fill={color}
        viewBox="0 0 1920 1920"
        xmlns="http://www.w3.org/2000/svg"
        className="h-3 w-3"
      >
        <path
          d="M735.385 336.094c218.24 0 395.977 177.624 395.977 395.976v113.137c0 121.96-56.568 229.78-143.57 302.526 94.13 13.916 187.354 34.959 278.315 64.6 122.414 39.825 204.664 155.676 204.664 288.159v200.364l-26.814 16.63c-148.434 92.32-392.017 202.515-708.572 202.515-174.795 0-439.76-35.186-708.685-202.514L0 1700.856v-189.39c0-140.629 89.264-263.042 221.973-304.79 85.418-26.7 172.533-46.498 260.327-59.509-86.55-72.746-142.891-180.339-142.891-301.96V732.07c0-218.352 177.623-395.976 395.976-395.976ZM1183.405 0c218.24 0 395.976 177.624 395.976 395.977v113.136c0 121.96-56.568 229.893-143.57 302.526 94.13 13.916 187.241 35.072 278.316 64.6 122.413 40.051 204.663 155.79 204.663 288.272v200.364l-26.7 16.631c-77.612 48.31-181.81 101.03-308.183 140.742v-21.723c0-181.696-113.589-340.766-282.727-395.75a1720.133 1720.133 0 0 0-111.553-32.244c35.751-69.805 54.871-147.416 54.871-227.29V732.104c0-250.483-182.036-457.975-420.414-500.175C886.762 95.487 1023.656 0 1183.404 0Z"
          fillRule="evenodd"
        />
      </svg>
    </>
  );
}
