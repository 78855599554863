import { FeeLabelPDF } from './components/FeeLabelPDF';
import { PriceSummaryData } from './types';

export function AfterHoursFeeDetailsPDF({
  data,
}: {
  data?: PriceSummaryData | null;
}) {
  return (
    <>
      <FeeLabelPDF label="Start Time" value={data?.business_hours_start} />
      <FeeLabelPDF label="End Time" value={data?.business_hours_end} />
      <FeeLabelPDF label="Pickup Time" value={data?.pickup_time} />
    </>
  );
}
