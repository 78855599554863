import { useCallback, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { FormFieldSwitch } from 'src/common/FormField/FormFieldSwitch';
import { FilterPopover } from 'src/common/primitives/FilterPopover/FilterPopover';

import { setFilterIsActive } from '../../payers.slice';

interface IsActiveColumnFilterProps {
  open: boolean;
  onClose: () => void;
}

export function IsActiveColumnFilter({
  open,
  onClose,
}: IsActiveColumnFilterProps) {
  const dispatch = useAppDispatch();
  const isActive = useAppSelector((state) => state.payer.filterIsActive);

  const handleToggle = useCallback(
    (active: boolean) => {
      dispatch(setFilterIsActive(active));
    },
    [dispatch]
  );

  const [isActiveFilter, setIsActiveFilter] = useState<boolean>(isActive);

  return (
    <FilterPopover
      open={open}
      triggerElement={<div data-testid="is-active-column-filter-trigger" />}
      onApply={() => {
        handleToggle(isActiveFilter);
        onClose();
      }}
      onCancel={() => {
        // Reset state
        setIsActiveFilter(isActive);
        onClose();
      }}
    >
      <FormFieldSwitch
        inputProps={{
          checked: isActiveFilter,
          onChange: (e) => {
            const typedValue = e as unknown as boolean;
            setIsActiveFilter(typedValue);
          },
        }}
        label="Only show active payers"
      />
    </FilterPopover>
  );
}
