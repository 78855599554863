import { useUserRoles } from 'src/features/auth/useUserRoles';

import { useCredentialsExpiringSoonCountRetrieveQuery } from '../external/bambi-api/bambiApi';

export default function useCredentialsExpiringSoonCount() {
  const { isRideOrderingPortalUser } = useUserRoles();

  const { data: expiredMemberCredentialsCount } =
    useCredentialsExpiringSoonCountRetrieveQuery(undefined, {
      skip: isRideOrderingPortalUser,
    });

  return expiredMemberCredentialsCount;
}
