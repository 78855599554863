import { useMemo } from 'react';

import { PencilIcon } from '@heroicons/react/24/outline';
import { upperFirst } from 'lodash-es';
import { DateTime } from 'luxon';
import { Control, Controller, useForm } from 'react-hook-form';

import { FormCancelButton } from 'src/common/FormCancelButton';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { FormField, FormFieldSet } from 'src/common/FormField';
import { FormSubmitButton } from 'src/common/FormSubmitButton';
import { useModal } from 'src/common/modals/useModal';
import { MultiSelectPayerField } from 'src/common/MultiSelectPayerContextField';
import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';
import { useFormValues } from 'src/common/useFormValues';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import { rideOrderingPortalUserTypes } from '../../rideOrderingPortal/rideOrderingPortalUserTypes';
import { useCreateableUserTypes } from '../../rideOrderingPortal/useCreateableUserTypes';
import { DeleteAction } from './DeleteAction';
import { EditMemberEmailModal } from './EditMemberEmailModal';
import { FormValues } from './FormValues';
import { EmailAddressField } from './MemberFormFields/EmailAddressField';
import { FirstNameField } from './MemberFormFields/FirstNameField';
import { LastNameField } from './MemberFormFields/LastNameField';
import { PhoneNumberField } from './MemberFormFields/PhoneNumberField';

interface AddEditModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose: () => void;
  onCancel: () => void;
  onSubmit: () => void;
  onDelete: () => void;
  onRestore: () => void;
  errors: ReturnType<typeof useForm>['formState']['errors'];
  isReadyToSubmit: boolean;
  loading: boolean;
  control: Control<FormValues, any>;
  serverErrors: string | null;
}

export function AddEditModal({
  open,
  setOpen,
  onClose,
  onCancel,
  onSubmit,
  onDelete,
  onRestore,
  errors,
  loading,
  control,
  serverErrors,
}: AddEditModalProps) {
  const { id, role, deleted_at } = useFormValues<FormValues>();
  const createableUserTypes = useCreateableUserTypes(
    !id ? undefined : role?.value
  );
  const disabled = deleted_at !== null;
  const userTypeOptions = useMemo(() => {
    return createableUserTypes.map((userType) => {
      let label = upperFirst(userType);

      if (userType === 'associate') {
        label = 'Associate (drivers and attendants) ';
      }

      return {
        label,
        value: userType,
      };
    });
  }, [createableUserTypes]);

  const { isAdmin, isOwner, isBambi } = useUserRoles();

  const canEditEmail = isAdmin || isOwner || isBambi;

  const {
    open: isOpenEditMemberEmailModal,
    setOpen: setOpenEditMemberEmailModal,
  } = useModal();

  return (
    <Modal open={open} setOpen={setOpen} onClose={onClose}>
      <form onSubmit={onSubmit}>
        <div>
          <h3 className="text-[24px] font-medium leading-6 text-gray-900">
            {id ? 'Edit Member' : 'Add a New Member'}
          </h3>
          {id ? null : (
            <p className="mt-1 text-xs text-gray-500">
              Create a profile for the new team member.
            </p>
          )}
          <p className="mt-2 text-xs text-gray-500">*Required</p>
        </div>

        {serverErrors && <FormErrorMessage>{serverErrors}</FormErrorMessage>}
        {deleted_at ? (
          <FormErrorMessage>
            This member was deleted on{' '}
            {DateTime.fromISO(deleted_at).toLocaleString(DateTime.DATE_SHORT)}.
          </FormErrorMessage>
        ) : null}

        <div className="mt-4">
          <div>
            <div className="mt-1">
              <Controller
                name="role"
                control={control}
                rules={{
                  required: 'Role is required',
                }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <>
                      <FormFieldSet
                        Fields={
                          <FormField
                            label="Type of User *"
                            type="select"
                            inputProps={{
                              id: 'user-type',
                              placeholder: 'Select User Type',
                            }}
                            options={userTypeOptions}
                            colSpan={12}
                            onChange={onChange}
                            value={value}
                            error={errors.role?.message?.toString()}
                            disabled={disabled}
                          />
                        }
                      />
                    </>
                  );
                }}
              />
            </div>
          </div>
          <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <FirstNameField disabled={disabled} />
            </div>

            <div className="sm:col-span-3">
              <LastNameField disabled={disabled} />
            </div>
          </div>
          <div className="mt-4 sm:col-span-12">
            <div className="mt-1">
              <PhoneNumberField disabled={disabled} />
            </div>
          </div>
          <div className="mt-4 sm:col-span-12">
            <div className="flex flex-row items-end gap-1">
              <EmailAddressField disabled={!!id} />
              {id && canEditEmail ? (
                <Button
                  variant="icon"
                  className="mb-1 max-h-[36px]"
                  onClick={() => setOpenEditMemberEmailModal(true)}
                  ariaLabel="Edit email address"
                  dataTestId="edit-email-address-modal-trigger"
                >
                  <PencilIcon width={22} height={22} />
                </Button>
              ) : null}
            </div>
          </div>{' '}
          {role?.value &&
            (rideOrderingPortalUserTypes.includes(role.value) ||
              role.value === 'restricted support') && (
              <div className="mt-4 max-w-[30rem] sm:col-span-12">
                <MultiSelectPayerField
                  fieldPath="payer_ids"
                  rules={{
                    required: `Payers are required for ${role.label} role`,
                  }}
                />
              </div>
            )}
        </div>
        {id && !deleted_at ? <DeleteAction onDelete={onDelete} /> : null}
        <div className="mt-4 flex items-center justify-evenly gap-3">
          <FormCancelButton
            isSubmitting={loading}
            onCancel={onCancel}
            className="w-full"
          />
          {!deleted_at ? (
            <FormSubmitButton
              isSubmitting={loading}
              buttonName="Save"
              className="w-full"
            />
          ) : (
            <Button variant="primary" className="w-full" onClick={onRestore}>
              Restore Member
            </Button>
          )}
        </div>
      </form>
      <EditMemberEmailModal
        open={isOpenEditMemberEmailModal}
        setOpen={setOpenEditMemberEmailModal}
      />
    </Modal>
  );
}
