import { Control, Controller } from 'react-hook-form';

import { FormField } from 'src/common/FormField';
import { FormLabel } from 'src/common/FormField/FormLabel';
import { Select } from 'src/common/primitives/Select';
import { MultiSelect } from 'src/common/primitives/Select/MultiSelect/MultiSelect';

import { usePassengerMultiSelectData } from '../../usePassengerMultiSelectData';
import { usePayerSelectData } from '../../usePayerSelectData';
import { DetailsForm } from './useDetailsForm';

interface DetailsFormFieldsProps {
  includeDateRange?: boolean;
  // Single select for payers dropdown
  includeSinglePayer?: boolean;
  // Multi select for payers dropdown
  includePayers?: boolean;
  includePassengers?: boolean;
  includeBambiTripIds?: boolean;
  includeExternalTripIds?: boolean;
  control: Control<DetailsForm, any>;
}
export function DetailsFormFields({
  includeBambiTripIds = false,
  includeDateRange = false,
  includeExternalTripIds = false,
  includePassengers = false,
  includeSinglePayer = false,
  includePayers = false,
  control: hookFormControl,
}: DetailsFormFieldsProps) {
  const { passengerSearchTerm, setPassengerSearchTerm, passengerOptions } =
    usePassengerMultiSelectData();

  const { payerOptions, payerSearchTerm, setPayerSearchTerm } =
    usePayerSelectData();

  return (
    <div className="flex flex-col gap-2">
      {includeDateRange && (
        <div>
          <div>Select the time range</div>
          <div className="flex flex-col gap-2 py-2">
            <Controller
              control={hookFormControl}
              name="startDate"
              render={({ field }) => (
                <FormField
                  label="Start Date"
                  type="date"
                  inputProps={{ ...field }}
                />
              )}
            />
            <Controller
              control={hookFormControl}
              name="endDate"
              render={({ field }) => (
                <FormField
                  label="End Date"
                  type="date"
                  inputProps={{ ...field }}
                />
              )}
            />
          </div>
        </div>
      )}
      {includeSinglePayer && (
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Payer
          </label>
          <Controller
            control={hookFormControl}
            name="selectedPayers"
            render={({ field }) => (
              <Select
                selected={field.value?.length ? field.value[0] : undefined}
                placeholder="Select a payer"
                options={payerOptions}
                onChange={(selected) => {
                  if (selected) field.onChange([selected]);
                }}
              />
            )}
          />
        </div>
      )}
      {includePayers && (
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Payer
          </label>
          <Controller
            control={hookFormControl}
            name="selectedPayers"
            render={({ field }) => (
              <MultiSelect
                placeholder="Select payers or select none to download all"
                searchable
                query={payerSearchTerm}
                setQuery={setPayerSearchTerm}
                options={payerOptions}
                selected={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      )}
      {includePassengers && (
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Passengers
          </label>
          <Controller
            control={hookFormControl}
            name="selectedPassengers"
            render={({ field }) => (
              <MultiSelect
                placeholder="Select passengers or select none to download all"
                searchable
                query={passengerSearchTerm}
                setQuery={setPassengerSearchTerm}
                options={passengerOptions}
                selected={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      )}
      {includeExternalTripIds && (
        <div>
          <FormLabel htmlFor="external-trip-ids-filter">
            External Trip IDs
          </FormLabel>
          <Controller
            control={hookFormControl}
            name="externalTripIdOptions"
            render={({ field }) => (
              <MultiSelect
                id="external-trip-ids-filter"
                placeholder="Add comma-separated external trip IDs..."
                options={[]}
                selected={field.value}
                onChange={field.onChange}
                allowNew
              />
            )}
          />
        </div>
      )}
      {includeBambiTripIds && (
        <div>
          <FormLabel htmlFor="external-trip-ids-filter">
            Bambi Trip IDs
          </FormLabel>
          <Controller
            control={hookFormControl}
            name="bambiTripIdOptions"
            render={({ field }) => (
              <MultiSelect
                id="bambi-trip-ids-filter"
                placeholder="Add comma-separated bambi trip IDs..."
                options={[]}
                selected={field.value}
                onChange={field.onChange}
                allowNew
              />
            )}
          />
        </div>
      )}
    </div>
  );
}
