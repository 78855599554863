import { FeeLabelPDF } from './components/FeeLabelPDF';
import { PriceSummaryData } from './types';

export function NoShowFeeDetailsPDF({
  data,
}: {
  data?: PriceSummaryData | null;
}) {
  return (
    <>
      <FeeLabelPDF label="Fee Type" value={data?.no_show_fee_type} />
      <FeeLabelPDF label="Scheduled Pickup" value={data?.scheduled_pickup_at} />
      <FeeLabelPDF
        label="Cancelled At"
        value={`${data?.canceled_at} (${data?.minutes_before_pickup} minutes before pickup)`}
      />
    </>
  );
}
