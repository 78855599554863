import { useCallback, useMemo, useState } from 'react';

import { DocumentCheckIcon } from '@heroicons/react/24/outline';
import { parse } from 'csv-parse/browser/esm/sync';
import { SubmitHandler } from 'react-hook-form';

import { useAppDispatch } from 'src/app/store';
import { useLazyGetTripsExportAsCsvQuery } from 'src/common/external/bambi-api/emptyApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { Button } from 'src/common/primitives/Button';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { MASReport } from 'src/features/trip/reports/MASReport/MASReport';

import { usePayerSelectData } from '../../../usePayerSelectData';
import { DetailsFormFields } from '../DetailsFormFields';
import { DetailsForm, useDetailsForm } from '../useDetailsForm';
import { MASReportRecord } from './types';

interface PrintableMASDetailsExportProps {
  onClose: () => void;
}

export function PrintableMASDetailsExport({
  onClose,
}: PrintableMASDetailsExportProps) {
  const [data, setData] = useState<MASReportRecord[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const { handleSubmit, control } = useDetailsForm();
  const { payerData } = usePayerSelectData();
  const [downloadLink, setDownloadLink] = useState<string | null>(null);
  const payer = useMemo(() => {
    if (!payerData) {
      return null;
    }

    return payerData.results.find(
      (p) => p.payer_info_name?.toLowerCase() === 'mas'
    );
  }, [payerData]);

  const dispatch = useAppDispatch();

  const [trigger] = useLazyGetTripsExportAsCsvQuery();

  const handleFetchData = useCallback(
    async (fields: DetailsForm) => {
      if (!payer) {
        setError('Unable to find MAS payer');
        return;
      }

      // Trigger will cause a blob download, see emptyApi
      const response = await trigger({
        rangeStart: fields.startDate?.startOf('day').toISO() ?? '',
        rangeEnd: fields.endDate?.endOf('day').toISO() ?? '',
        outputSet: '',
        format: 'pdf',
        status: 'completed',
        payers: payer.id,
        passengers:
          fields.selectedPassengers
            ?.map((passenger) => passenger.value)
            .join(',') ?? '',
        externalTripIds:
          fields.externalTripIdOptions
            ?.map((option) => option.value)
            .join(',') ?? '',
        tripIds: '',
      }).unwrap();

      if (!response) {
        throw new Error('Response missing records');
      }

      const data = parse(response.records as string, {
        columns: true,
      });

      return data as MASReportRecord[];
    },
    [payer, trigger]
  );

  const handleDocumentReady = useCallback((pdfBlob: Blob | null) => {
    if (!pdfBlob) {
      setError('Something unexpected happened, unable to render PDF');
      return;
    }

    setDownloadLink(URL.createObjectURL(pdfBlob));
  }, []);

  const handleDownloadTrips = useCallback<SubmitHandler<DetailsForm>>(
    async (fields) => {
      if (!payer) {
        setError('Unable to find MAS payer');
        return;
      }

      try {
        setError(undefined);
        setLoading(true);

        const data = await handleFetchData(fields);
        if (!data || !data.length) {
          setError(`No records found for export`);
          setLoading(false);
          return;
        }

        setData(data);
      } catch (e) {
        const errMsg = `There was an error downloading the data. ${formatServerError(
          e
        )}`;
        setError(errMsg);
        setLoading(false);
      }
    },
    [handleFetchData, payer]
  );

  const handleOpenReport = useCallback(() => {
    if (!downloadLink) {
      dispatch(
        show({
          description:
            'Something unexpected happened, unable to find download link',
          type: 'error',
        })
      );
      return;
    }

    window.open(downloadLink, '_blank', 'noopener');
    onClose();
  }, [dispatch, downloadLink, onClose]);

  // Step 3 - After PDF is rendered into a blob, present the link to the user.
  // We do this instead of opening it because the length of time between export
  // and render can be longer than is allowable to avoid the popup blocker

  if (downloadLink) {
    return (
      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-2 text-lg">
          <span className="text-green-500">
            <DocumentCheckIcon width={32} height={32} />
          </span>
          Report ready
        </div>
        <Button variant="anchor" onClick={handleOpenReport}>
          Click here to open the report
        </Button>
      </div>
    );
  }

  // Step 2 - After data is fetched, render the report to render everything
  // else. When done, open the blob
  if (data.length) {
    return <MASReport trips={data} onDocumentReady={handleDocumentReady} />;
  }

  // Step 1 - Present filters to download data
  return (
    <div>
      <div className="flex flex-col gap-2">
        <h1 className="text-2xl">MAS Portal details download</h1>
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
        <DetailsFormFields control={control} includeDateRange />
        <div className="flex flex-row justify-between gap-4 pt-6">
          <div>
            <Button disabled={loading} onClick={onClose}>
              Cancel
            </Button>
          </div>
          <div className="flex flex-row gap-2">
            <Button
              dataTestId="download-pdf"
              disabled={loading}
              loading={loading}
              variant="primary"
              onClick={handleSubmit(handleDownloadTrips)}
            >
              {loading ? 'Exporting' : 'Print to PDF'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
