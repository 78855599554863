import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { truncate } from 'lodash-es';
import { DateTime } from 'luxon';

import store from 'src/app/store';
import { DriverColumnFilter } from 'src/common/datagridColumnFilters/DriverColumnFilter';
import { TripRead } from 'src/common/external/bambi-api/bambiApi';
import { formatAddress } from 'src/common/formatAddress';
import { formatPriceCell } from 'src/common/formatPriceCell';
import { HereMapsAddressLink } from 'src/common/HereMapsAddressLink';
import { Badge } from 'src/common/primitives/Badge';
import { PassengerPopover } from 'src/features/passenger/management/PassengerPopover';
import { PriceSummaryModalButton } from 'src/features/pricing/PriceSummaryModal/PriceSummaryModalButton';
import { TripGridRowActions } from 'src/features/trip/management/TripGridRowActions';
import { TripStatusBadge } from 'src/features/trip/management/TripStatusBadge';

import {
  setFilterVehicles,
  setSelectedDateRange,
  setSelectedDrivers,
  setSelectedPayers,
  setSelectedStatuses,
} from '../trip.slice';
import { DateColumnFilter } from './filters/DateColumnFilter';
import { PayersColumnFilter } from './filters/PayerColumnFilter';
import { PriceColumnFilter } from './filters/PriceColumnFilter';
import { StatusColumnFilter } from './filters/StatusColumnFilter';
import { TagColumnFilter } from './filters/TagColumnFilter';
import { VehicleColumnFilter } from './filters/VehicleColumnFilter';
import { TripDriver } from './TripDriver';
import { TripImportedBadge } from './TripImportedBadge';
import TripTagsDisplay from './TripTagsDisplay';

const columnHelper = createColumnHelper<TripRead>();

const rowSelectionColumnDef = columnHelper.display({
  id: 'selection',
  header: () => <div data-testid="row-selection-column" />,
  enableHiding: false,
  meta: {
    enableHeaderMenu: false,
  },
  cell: ({ row }) => {
    if (
      row.original.status === 'canceled' ||
      row.original.payer.has_kinetik_account
    ) {
      return null;
    }

    return (
      <input
        data-testid={`row-selection-control-${row.original.id}`}
        type="checkbox"
        checked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    );
  },
});

const actionColumnDef = (slim?: boolean) =>
  columnHelper.display({
    id: 'actions',
    header: '',
    enableHiding: false,
    cell: ({ row }) => {
      return (
        <TripGridRowActions
          trip={row.original}
          triggerClassName={slim ? 'px-0 py-1' : undefined}
        />
      );
    },
    meta: {
      columnClassName: slim ? 'px-0 py-0' : undefined,
    },
  });

export const passengerColumnDef = columnHelper.accessor('passenger', {
  enableSorting: true,
  id: 'name',
  header: 'Passenger',
  cell: (props) => (
    <PassengerPopover passenger={props.row.original.passenger} />
  ),
});

export const scheduledPickupAtColumnDef = columnHelper.accessor(
  'scheduled_pickup_at',
  {
    enableSorting: true,
    id: 'scheduled_pickup_at',
    header: 'Date of Trip',
    cell: (props) =>
      DateTime.fromISO(props.row.original.scheduled_pickup_at).toLocaleString({
        weekday: 'short',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      }),
    meta: {
      filterComponent(column, open, onClose) {
        return (
          <DateColumnFilter
            open={open}
            onClose={onClose}
            setSelectedAction={setSelectedDateRange}
          />
        );
      },
      filterCount() {
        const state = store.getState().trip;
        return state.selectedDateRange?.from || state.selectedDateRange?.to
          ? 1
          : 0;
      },
    },
  }
);

export const pickupTimeColumnDef = columnHelper.display({
  header: 'Pickup Time',
  cell: (props) =>
    DateTime.fromISO(props.row.original.scheduled_pickup_at).toFormat('t'),
});

export const pickupAddressColumnDef = columnHelper.accessor('pickup', {
  enableSorting: true,
  id: 'pickup_address',
  header: 'Pick Up Address',
  cell: ({ row }) => (
    <HereMapsAddressLink
      addresses={[
        {
          latitude: row.original.pickup.latitude,
          longitude: row.original.pickup.longitude,
        },
        {
          latitude: row.original.dropoff.latitude,
          longitude: row.original.dropoff.longitude,
        },
      ]}
    >
      {formatAddress(row.original.pickup.address)}
    </HereMapsAddressLink>
  ),
});

export const dropoffAddressColumnDef = columnHelper.accessor('dropoff', {
  enableSorting: true,
  id: 'dropoff_address',
  header: 'Drop Off Address',
  cell: ({ row }) => (
    <HereMapsAddressLink
      addresses={[
        {
          latitude: row.original.pickup.latitude,
          longitude: row.original.pickup.longitude,
        },
        {
          latitude: row.original.dropoff.latitude,
          longitude: row.original.dropoff.longitude,
        },
      ]}
    >
      {formatAddress(row.original.dropoff.address)}
    </HereMapsAddressLink>
  ),
});

const createdByColumnDef = columnHelper.accessor(
  'created_by',
  {
    id: 'created_by',
    header: 'Created By',
    cell: (props) => (
      <>{props.row.original.created_by}</>
    ),
  }
);

const estimatedDistanceMilesColumnDef = columnHelper.accessor(
  'estimated_distance_miles',
  {
    id: 'estimated_distance_miles',
    header: 'Estimated Miles',
    cell: (props) => (
      <>{props.row.original.estimated_distance_miles?.toFixed(2)}</>
    ),
  }
);

const inputPriceCentsColumnDef = columnHelper.accessor('input_price_cents', {
  id: 'input_price_cents',
  enableSorting: true,
  header: 'Price',
  cell: (props) => {
    const { input_price_cents } = props.row.original;
    const formattedPrice = formatPriceCell(input_price_cents);
    return (
      <div className="flex items-center gap-1">
        {formattedPrice}
        <PriceSummaryModalButton tripId={props.row.original.id} />
      </div>
    );
  },
  meta: {
    filterComponent(column, open, onClose) {
      return <PriceColumnFilter open={open} onClose={onClose} />;
    },
    filterCount() {
      const state = store.getState().trip;
      return state.filterPrice !== null ? 1 : 0;
    },
  },
});

const spaceTypeColumnDef = columnHelper.accessor('space_type', {
  id: 'space_type',
  header: 'Space Type',
  cell: (props) => <Badge label={props.row.original.space_type} color="blue" />,
});

export const payerColumnDef = columnHelper.accessor('payer', {
  enableSorting: true,
  id: 'payer_name',
  header: 'Payer',
  cell: (props) => (
    <>
      {props.row.original.payer
        ? truncate(props.row.original.payer.payer_name || '', {
            length: 30,
            omission: '...',
          })
        : ''}
    </>
  ),
  meta: {
    filterComponent(column, open, onClose) {
      return (
        <PayersColumnFilter
          open={open}
          onClose={onClose}
          selected={store.getState().trip.selectedPayers}
          setSelectedAction={setSelectedPayers}
        />
      );
    },
    filterCount() {
      const state = store.getState().trip;
      return state.selectedPayers.length || 0;
    },
  },
});

const driverColumnDef = columnHelper.accessor('driver_name', {
  id: 'driver_name',
  header: 'Driver',
  cell: (props) => <TripDriver {...props.row.original} />,
  enableColumnFilter: true,
  meta: {
    filterComponent(column, open, onClose) {
      return (
        <DriverColumnFilter
          open={open}
          onClose={onClose}
          selected={store.getState().trip.selectedDrivers}
          setSelectedAction={setSelectedDrivers}
        />
      );
    },
    filterCount() {
      return store.getState().trip.selectedDrivers.length;
    },
  },
});

const statusColumnDef = columnHelper.accessor('status', {
  enableSorting: true,
  id: 'status',
  header: 'Status',
  cell: (props) => <TripStatusBadge status={props.row.original.status} />,
  meta: {
    filterComponent(column, open, onClose) {
      return (
        <StatusColumnFilter
          open={open}
          onClose={onClose}
          selected={store.getState().trip.selectedStatuses}
          setSelectedAction={setSelectedStatuses}
        />
      );
    },
    filterCount() {
      const state = store.getState().trip;
      return state.selectedStatuses.length;
    },
  },
});

const confirmationNumberColumnDef = columnHelper.accessor('confirmation_code', {
  id: 'confirmation_code',
  header: 'Code',
  cell: (props) => {
    const { confirmation_code } = props.row.original;
    if (confirmation_code) {
      return confirmation_code;
    }
    return '';
  },
});

const tagsColumnDef = columnHelper.accessor('tags', {
  header: 'Tags',
  cell: (props) => <TripTagsDisplay tags={props.row.original.tags} />,
  meta: {
    filterComponent(column, open, onClose) {
      return <TagColumnFilter open={open} onClose={onClose} />;
    },
    filterCount() {
      const state = store.getState().trip;
      return state.selectedTags.length || 0;
    },
  },
});

const invoiceNumberColumnDef = columnHelper.accessor('invoice_numbers', {
  id: 'invoice_numbers',
  header: 'Invoice #',
  cell: ({ row }) => row.original.invoice_numbers,
});

const importedTripColumn = columnHelper.display({
  id: 'imported',
  header: 'Imported',
  cell: ({ row }) => <TripImportedBadge trip={row.original} />,
});

const vehicleNameColumn = columnHelper.accessor('vehicle_nickname', {
  id: 'vehicle_nickname',
  header: 'Vehicle',
  cell: ({ getValue }) => getValue(),
  meta: {
    filterComponent(column, open, onClose) {
      return (
        <VehicleColumnFilter
          open={open}
          onClose={onClose}
          selected={store.getState().trip.filterVehicles}
          setSelectedAction={setFilterVehicles}
        />
      );
    },
    filterCount() {
      return store.getState().trip.filterVehicles.length;
    },
  },
});

export const columns: ColumnDef<TripRead, any>[] = [
  rowSelectionColumnDef,
  actionColumnDef(true),
  passengerColumnDef,
  scheduledPickupAtColumnDef,
  pickupTimeColumnDef,
  pickupAddressColumnDef,
  dropoffAddressColumnDef,
  estimatedDistanceMilesColumnDef,
  inputPriceCentsColumnDef,
  spaceTypeColumnDef,
  payerColumnDef,
  statusColumnDef,
  tagsColumnDef,
  driverColumnDef,
  confirmationNumberColumnDef,
  invoiceNumberColumnDef,
  importedTripColumn,
  vehicleNameColumn,
];

export const rideOrderingPortalColumns: ColumnDef<TripRead, any>[] = [
  actionColumnDef(false),
  passengerColumnDef,
  scheduledPickupAtColumnDef,
  pickupTimeColumnDef,
  pickupAddressColumnDef,
  dropoffAddressColumnDef,
  estimatedDistanceMilesColumnDef,
  spaceTypeColumnDef,
  payerColumnDef,
  driverColumnDef,
  statusColumnDef,
  confirmationNumberColumnDef,
  createdByColumnDef,
  tagsColumnDef,
];

export const tripFinderModalColumns: ColumnDef<TripRead, any>[] = [
  passengerColumnDef,
  invoiceNumberColumnDef,
  scheduledPickupAtColumnDef,
  pickupTimeColumnDef,
  pickupAddressColumnDef,
  dropoffAddressColumnDef,
  estimatedDistanceMilesColumnDef,
  inputPriceCentsColumnDef,
  spaceTypeColumnDef,
  payerColumnDef,
  driverColumnDef,
  statusColumnDef,
  confirmationNumberColumnDef,
  tagsColumnDef,
  vehicleNameColumn,
];
