import { useCallback, useEffect } from 'react';

import { CloudArrowDownIcon } from '@heroicons/react/24/outline';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';

import { initializeFilters, setShowExportInvoicesModal } from './invoice.slice';
import InvoiceController from './InvoiceController';
import { InvoiceGlobalFilters } from './InvoiceGlobalFilters';
import { InvoiceDataGrid } from './management/InvoiceDataGrid';
import { useCreateEmptyInvoice } from './management/useCreateEmptyInvoice';
import { DownloadInvoicesModal } from './modals/DownloadInvoicesModal';
import { selectCheckedInvoiceRows } from './selectors';

export default function InvoiceScreen() {
  const handleCreateEmptyInvoice = useCreateEmptyInvoice();
  const dispatch = useAppDispatch();
  const isExportModalOpen = useAppSelector(
    (state) => state.invoice.showExportInvoicesModal
  );
  const setExportModalOpen = useCallback(
    (open: boolean) => {
      dispatch(setShowExportInvoicesModal(open));
    },
    [dispatch]
  );

  const selectedInvoiceRows = useAppSelector(selectCheckedInvoiceRows);

  useEffect(() => {
    dispatch(initializeFilters());
  }, [dispatch]);

  return (
    <div>
      <div className="flex flex-row justify-between">
        <InvoiceGlobalFilters />
        <div className="mb-4 flex gap-2">
          <Button
            className="relative"
            variant="secondary-outline"
            onClick={() => setExportModalOpen(true)}
          >
            <CloudArrowDownIcon className="mr-2 w-5" /> Download{' '}
            {selectedInvoiceRows.length ? (
              <span className="color-mint px-1">
                ({selectedInvoiceRows.length})
              </span>
            ) : null}
            Invoices
          </Button>
          <Button variant="primary" onClick={() => handleCreateEmptyInvoice()}>
            Create an Invoice
          </Button>
        </div>
      </div>
      <DownloadInvoicesModal
        open={isExportModalOpen}
        setOpen={setExportModalOpen}
        onClose={() => setExportModalOpen(false)}
      />
      <InvoiceDataGrid />
      <InvoiceController />
    </div>
  );
}
