import { Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { parseAddress, ParsedAddress } from 'vladdress';

import { removeUnitedStatesFromAddress } from '../util/removeUnitedStatesFromAddress';
import { commonStyles } from './common.styles';

interface PDFAddressBlockProps {
  address: string;
  style?: Style;
}

export function PDFAddressBlock({ address, style = {} }: PDFAddressBlockProps) {
  let addressParts: Partial<ParsedAddress> = {};

  if (!address) {
    return null;
  }

  try {
    addressParts = parseAddress(removeUnitedStatesFromAddress(address));
  } catch (e) {
    console.warn('Unable to parse address', e);
    addressParts.addressLine1 = `Unable to parse address: ${e}`;
  }

  return (
    <>
      <Text data-testid="address-line-1" style={[commonStyles.textSm, style]}>
        {addressParts.addressLine1}
      </Text>
      {addressParts.addressLine2 ? (
        <Text style={[commonStyles.textSm, style]}>
          {addressParts.addressLine2}
        </Text>
      ) : null}
      <Text style={[commonStyles.textSm, style]}>
        {addressParts.placeName}, {addressParts.stateAbbreviation}
      </Text>
      <Text style={[commonStyles.textSm, style]}>
        {addressParts.zipCodePlusFour ?? addressParts.zipCode}
      </Text>
    </>
  );
}
