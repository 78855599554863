import { ReactElement, useCallback } from 'react';

import { NewspaperIcon, TableCellsIcon } from '@heroicons/react/24/outline';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { Modal, ModalProps } from 'src/common/primitives/Modal';
import { SelectOption } from 'src/common/primitives/Select';

import {
  ExportOptions,
  setExportInvoicesReportType,
  setSelectedInvoiceRows,
  setSkipExportGatherDataStep,
} from '../invoice.slice';
import { selectCheckedInvoiceRows } from '../selectors';
import { useExportInvoices } from '../useExportInvoices';
import { DownloadInvoicesCondensedPDF } from './DownloadInvoices/DownloadInvoicesCondensedPDF';
import { DownloadInvoicesQuickbooksCSV } from './DownloadInvoices/DownloadInvoicesQuickbooksCSV';

interface DownloadInvoicesModalProps {
  open: ModalProps['open'];
  setOpen: ModalProps['setOpen'];
  onClose: ModalProps['onClose'];
}

export function DownloadInvoicesModal({
  open,
  setOpen,
  onClose,
}: DownloadInvoicesModalProps) {
  const dispatch = useAppDispatch();
  const reportType = useAppSelector(
    (state) => state.invoice.exportInvoicesReportType
  );
  const selectedInvoiceRows = useAppSelector(selectCheckedInvoiceRows);

  const { handleExport, isLoading: isFetchingInvoices } = useExportInvoices();

  const handleClose = useCallback(() => {
    dispatch(setExportInvoicesReportType(undefined));
    dispatch(setSkipExportGatherDataStep(false));
    onClose?.();
  }, [dispatch, onClose]);

  const handleSelectReportType = useCallback(
    async (option: ExportOptions) => {
      if (selectedInvoiceRows.length) {
        await handleExport(option, selectedInvoiceRows);
        dispatch(setSelectedInvoiceRows({}));
        // Close quickbooks report because it will immediately trigger a download
        if (option === 'quickbooks') {
          handleClose();
        }
        return;
      }

      dispatch(setExportInvoicesReportType(option));
    },
    [dispatch, handleClose, handleExport, selectedInvoiceRows]
  );

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      contentClassnames="w-[32rem]"
      onInteractOutside={handleClose}
    >
      <h1 className="text-2xl">Download Invoices</h1>
      {!reportType ? (
        <div>
          <h2>Select the report type</h2>
          <div className="mt-2 flex flex-col gap-2">
            {reportOptions.map((option) => (
              <div key={option.value}>
                <Button
                  variant="ghost"
                  className="flex w-full flex-row gap-2 border border-mint"
                  justifyContent="start"
                  loading={isFetchingInvoices}
                  onClick={() => handleSelectReportType(option.value)}
                >
                  {isFetchingInvoices ? (
                    <div className="py-2">&nbsp;</div>
                  ) : (
                    <>
                      {option.icon}
                      <h3 className="text-lg text-slate-600">{option.label}</h3>
                    </>
                  )}
                </Button>
              </div>
            ))}
            <div className="mt-2 flex flex-row justify-end">
              <Button
                variant="secondary-outline"
                onClick={handleClose}
                className="w-16"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {reportType === 'quickbooks' && (
            <DownloadInvoicesQuickbooksCSV onClose={handleClose} />
          )}
          {reportType === 'condensed' && (
            <DownloadInvoicesCondensedPDF onClose={handleClose} />
          )}
        </div>
      )}
    </Modal>
  );
}

export const reportOptions: (SelectOption<ExportOptions> & {
  icon: ReactElement;
})[] = [
  {
    icon: <TableCellsIcon className="w-6" />,
    label: 'Quickbooks Report (CSV)',
    value: 'quickbooks',
  },
  {
    icon: <NewspaperIcon className="w-6" />,
    label: 'Condensed Report (PDF)',
    value: 'condensed',
  },
];
