
import { datadogLogs } from '@datadog/browser-logs';

export function useDatadogLogger() {
  if(datadogLogs.getInternalContext()?.session_id) return;
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: 'bambi-web',
    env: process.env.NODE_ENV || 'development',
  });
}
