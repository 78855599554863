import { PropsWithChildren, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'src/app/store';
import { TripRead, TripWrite } from 'src/common/external/bambi-api/bambiApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { FormSubmitButton } from 'src/common/FormSubmitButton';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { useShouldShowRideOrderingPortalFeatures } from 'src/features/rideOrderingPortal/useShouldShowRideOrderingPortalFeatures';
import { TripStatusBadge } from 'src/features/trip/management/TripStatusBadge';

import { TripImportedBadge } from '../trip/management/TripImportedBadge';
import { addATrip } from './addATrip.slice';
import { AddATripErrorMessageForm } from './AddATripErrorMessageForm';
import { assignDefaultFormValues } from './assignDefaultFormValues';
import { TripPriceEstimateResult } from './TripPriceEstimateResult/TripPriceEstimateResult';
import { useAddATripOnSubmit } from './useAddATripOnSubmit';
import { useUpdateATripOnSubmit } from './useAddATripOnSubmit/useUpdateATripOnSubmit';
import { useIsEditingTrip } from './useIsEditingTrip';

export function AddATripFlowForm({ children }: PropsWithChildren) {
  const shouldShowRideOrderingPortalFeatures =
    useShouldShowRideOrderingPortalFeatures();
  const isEditingTrip = useIsEditingTrip();

  const selectedTrip = useAppSelector((state) => state.addATrip.selectedTrip);
  const isOpen = useAppSelector((state) => state.addATrip.isOpen);
  const isSubmitting = useAppSelector((state) => state.addATrip.isSubmitting);
  const errors = useAppSelector((state) => state.addATrip).errors;

  const methods = useForm({
    defaultValues: assignDefaultFormValues(
      selectedTrip as TripRead & TripWrite
    ),
    mode: 'all',
  });
  const dispatch = useDispatch();

  const onAddATripSubmit = useAddATripOnSubmit({
    getValues: methods.getValues,
  });

  const onUpdateATripSubmit = useUpdateATripOnSubmit({
    getValues: methods.getValues,
  });

  useEffect(() => {
    // TODO: Need to refine cancelling behavior or at least have save a draft before always resetting on close
    // Cancel should probably definitely reset
    // Clicking any element outside the dialog is a little trickier (ex: Clicking on a toast sometimes causes it to close, which is a bug)
    // By only reseting if the trip is successful this is deferred for a bit
    if (!isOpen) {
      dispatch(addATrip.actions.selectNoPassenger());
      dispatch(addATrip.actions.selectPayer(null));
      if (methods.formState.isSubmitSuccessful) methods.reset();
    }
  }, [isOpen, methods, dispatch]);

  useEffect(() => {
    if (errors.length > 0) {
      const submitErrors = document.querySelector('#add-a-trip-form-errors');
      if (submitErrors) {
        submitErrors.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [errors]);

  const onGoToError = (id: string) => {
    const error = document.querySelector(`#${id}`);
    if (error) {
      error.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [open, setOpen] = useState(false);
  const tripLegsPriceEstimationState = useAppSelector(
    (state) => state.addATrip.tripLegsPriceEstimationState
  );
  const isRevenueEstimateStale = tripLegsPriceEstimationState.some(
    (legState) => legState.isStale
  );

  const saveButtonLabel = isRevenueEstimateStale
    ? 'Save with stale price quotes'
    : 'Save';

  return (
    <FormProvider {...methods}>
      <form
        className="h-full"
        onSubmit={methods.handleSubmit(
          isEditingTrip ? onUpdateATripSubmit : onAddATripSubmit
        )}
        data-testid="trip-form"
        data-tripid={selectedTrip?.id}
      >
        <div className="flex h-full w-[100vw] flex-col justify-between divide-y divide-gray-200">
          <div className="flex flex-grow flex-col gap-y-4 overflow-y-scroll px-4 pb-4 pt-2 sm:px-6">
            <h1 className="flex items-center gap-2 text-xl font-bold">
              <div className="flex w-full flex-row justify-between">
                <div className="flex items-center gap-2">
                  <span>{isEditingTrip ? 'Edit' : 'Add'} Trip</span>
                  {isEditingTrip && (
                    <span data-test-id="trip-form-trip-status-badge">
                      <TripStatusBadge status={selectedTrip?.status} />
                    </span>
                  )}
                  {isEditingTrip && selectedTrip && (
                    <span data-test-id="trip-form-trip-imported-badge">
                      <TripImportedBadge trip={selectedTrip} />
                    </span>
                  )}
                </div>

                <span>
                  {isEditingTrip ? (
                    <span className="text-lg">
                      {selectedTrip?.confirmation_code}
                    </span>
                  ) : null}
                </span>
              </div>
            </h1>
            {errors.map((error) => {
              // Tried src/common/util/serverErrorFormatter.ts but wanted to generalize and make a more declarative abstraction
              // TODO: ids aren't being assigned properly to HeadlessUI components, so going to the error won't currently work all the time
              // However, it should be rare that any of these aren't caught by client side validation.
              // I'd like to do a few things:
              // 1. Fix the ids properly
              // 2. Clean up attr formatting to make this prettier or use the field label
              // 3. Standardize the type. { attr: string, code: string, detail: string } from the server works pretty well but I could see an optional type
              // although code seems to be more of a type/category ('required', 'null', etc)
              // 4. Smooth out the scrolling
              // 5. Use react hook form to highlight fields and something like https://www.carlrippon.com/react-hook-form-server-validation/
              // Don't want to blow up this pr though
              return (
                <>
                  <FormErrorMessage>
                    <div id="add-a-trip-form-errors">
                      {error.attr}: {error.genericMessage}
                      {error.code !== 'general' && (
                        <span
                          className="cursor-pointer underline"
                          onClick={() => onGoToError(error.attr.toLowerCase())}
                        >
                          Go to error
                        </span>
                      )}
                      <Button
                        onClick={() => setOpen(true)}
                        className="ml-2"
                        variant="secondary"
                      >
                        Show more
                      </Button>
                    </div>
                  </FormErrorMessage>
                  <AddATripErrorMessageForm
                    open={open}
                    setOpen={setOpen}
                    onClose={() => {
                      setOpen(false);
                    }}
                    // TODO: Dumb patch to keep this from blocking while I come up with a better solution
                    errorDetail={
                      error.detail
                        ? error.detail.toString()
                        : 'Unexpected error...'
                    }
                  />
                </>
              );
            })}
            {children}
          </div>
          <div className="flex flex-shrink-0 justify-between gap-x-1 bg-white px-4 py-4 sm:px-6">
            {!shouldShowRideOrderingPortalFeatures && (
              <TripPriceEstimateResult />
            )}
            <div className="flex w-full items-center justify-end gap-2">
              {isSubmitting && <LoadingIndicator />}
              <Button
                variant="ghost"
                onClick={() => dispatch(addATrip.actions.cancel())}
              >
                Cancel
              </Button>
              <FormSubmitButton
                isSubmitting={isSubmitting}
                buttonName={saveButtonLabel}
              />
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
