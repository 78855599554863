import { useState } from 'react';

import { DateTime } from 'luxon';
import { DayPicker } from 'react-day-picker';

import 'react-day-picker/dist/style.css';
import { Popover } from 'src/common/primitives/Popover';

import { Button } from '../primitives/Button';
import { BrowserJSDateToOrgLuxonDateTime } from './BrowserJSDateToOrgLuxonDateTime';
import { OrgLuxonDateTimeToBrowserJSDate } from './OrgLuxonDateTimeToBrowserJSDate';

const MIN_JS_DATE = new Date(-8640000000000000);
const MAX_JS_DATE = new Date(8640000000000000);

const customStyles = `
  .rdp-root {
    --rdp-accent-color: #0CACA6;
    --rdp-accent-background-color: rgba(12, 172, 166, 0.15);
  }
`;

interface DatePickerProps {
  triggerElement: React.ReactNode;
  value?: DateTime;
  onChange: (date: DateTime | undefined) => void;
  maxDate?: DateTime;
  minDate?: DateTime;
}

export function DatePicker({
  triggerElement,
  value,
  onChange,
  maxDate,
  minDate,
}: DatePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const timeZone = DateTime.local().zoneName;

  const convertToJSDate = (date?: DateTime) =>
    date ? OrgLuxonDateTimeToBrowserJSDate(date) : undefined;

  const valueAsJSDate = convertToJSDate(value);
  const minDateAsJSDate = convertToJSDate(minDate) || MIN_JS_DATE;
  const maxDateAsJSDate = convertToJSDate(maxDate) || MAX_JS_DATE;
  const endMonth = DateTime.now()
    .plus({ years: 5 })
    .set({ month: 12 })
    .toJSDate();

  const handleDateSelection = (day: Date) => {
    const luxonDateTime = BrowserJSDateToOrgLuxonDateTime(day);
    onChange(luxonDateTime);
    setIsOpen(false);
  };

  const handleQuickSelection = (daysToAdd: number) => () => {
    const selectedDate = DateTime.now().plus({ days: daysToAdd });
    onChange(selectedDate);
    setIsOpen(false);
  };

  return (
    <>
      <style>{customStyles}</style>
      <Popover
        trigger={triggerElement}
        open={isOpen}
        onOpenChange={setIsOpen}
        content={
          <>
            <DayPicker
              mode="single"
              timeZone={timeZone}
              selected={valueAsJSDate}
              captionLayout="dropdown"
              endMonth={endMonth}
              onDayClick={handleDateSelection}
              disabled={{ before: minDateAsJSDate, after: maxDateAsJSDate }}
              today={OrgLuxonDateTimeToBrowserJSDate(DateTime.local())}
            />
            <div className="flex justify-between px-3 pt-2">
              <Button
                dataTestId="date-picker-today-btn"
                onClick={handleQuickSelection(0)}
                variant="ghost"
                className="hover:text-mint"
              >
                Today
              </Button>
              <Button
                dataTestId="date-picker-tomorrow-btn"
                onClick={handleQuickSelection(1)}
                variant="ghost"
                className="hover:text-mint"
              >
                Tomorrow
              </Button>
            </div>
          </>
        }
      />
    </>
  );
}
