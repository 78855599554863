import { useMemo } from 'react';

import { Text, View } from '@react-pdf/renderer';
import { createColumnHelper } from '@tanstack/react-table';
import { keyBy } from 'lodash-es';

import { ServiceTypeEnum } from 'src/common/external/bambi-api/bambiApi';
import { commonStyles } from 'src/common/pdf/common.styles';
import { PDFAddressBlock } from 'src/common/pdf/PDFAddressBlock';
import { PDFTable } from 'src/common/pdf/PDFTable/PDFTable';
import { SelectOption } from 'src/common/primitives/Select';
import { serviceTypeOptionsMap } from 'src/features/add-trip/ServiceDetails/serviceTypeOptions';

import {
  currencyFormatter,
  formatInvoiceDate,
  formatInvoicePDFDate,
} from '../forms/cells/utils';
import { InvoiceColumnKey } from '../modals/DownloadInvoices/steps/components/InvoiceColumnSelect';
import { InvoiceExportCondensedReportRow } from '../types';

const createColumn = createColumnHelper<InvoiceExportCondensedReportRow>();
const columns = [
  createColumn.display({
    id: 'id',
    size: 80,
    header() {
      return <Text>ID</Text>;
    },
    cell: ({ row }) => {
      return <Text style={commonStyles.textSm}>{row.original.TripID}</Text>;
    },
  }),
  createColumn.display({
    id: 'date',
    size: 70,
    header() {
      return <Text>Date</Text>;
    },
    cell: ({ row }) => {
      return <Text style={commonStyles.textSm}>{row.original.TripDate}</Text>;
    },
  }),
  createColumn.display({
    id: 'completion_date',
    size: 85,
    header() {
      return <Text>Completed On</Text>;
    },
    cell: ({ row }) => {
      return (
        <Text style={commonStyles.textSm}>
          {formatInvoiceDate(row.original.TripCompletionTime)}
        </Text>
      );
    },
  }),
  createColumn.display({
    id: 'pickup_location',
    size: 120,
    header() {
      return (
        <>
          <Text>Pickup </Text>
          <Text>Location</Text>
        </>
      );
    },
    cell: ({ row }) => {
      return (
        <View style={[commonStyles.textSm, commonStyles.vstack, { gap: 2 }]}>
          <PDFAddressBlock address={row.original.Pickup} />
        </View>
      );
    },
  }),
  createColumn.display({
    id: 'dropoff_location',
    size: 120,
    header() {
      return <Text>Dropoff Location</Text>;
    },
    cell: ({ row }) => {
      return (
        <View style={[commonStyles.textSm, commonStyles.vstack, { gap: 2 }]}>
          <PDFAddressBlock address={row.original.Dropoff} />
        </View>
      );
    },
  }),
  createColumn.display({
    id: 'space_type',
    size: 80,
    header() {
      return (
        <>
          <Text>Space </Text>
          <Text>Type</Text>
        </>
      );
    },
    cell({ row }) {
      return <Text style={commonStyles.textSm}>{row.original.SpaceType}</Text>;
    },
  }),
  createColumn.display({
    id: 'service_type',
    size: 80,
    header() {
      return <Text>Service Type</Text>;
    },
    cell({ row }) {
      return (
        <Text style={commonStyles.textSm}>
          {serviceTypeOptionsMap[row.original.ServiceType as ServiceTypeEnum]}
        </Text>
      );
    },
  }),
  createColumn.display({
    id: 'status',
    size: 60,
    header() {
      return <Text>Status</Text>;
    },
    cell({ row }) {
      return <Text style={commonStyles.textSm}>{row.original.Status}</Text>;
    },
  }),
  createColumn.display({
    id: 'payer_name',
    size: 50,
    header() {
      return <Text>Payer</Text>;
    },
    cell({ row }) {
      return (
        <Text style={commonStyles.textSm}>{row.original.ItemPayerName}</Text>
      );
    },
  }),
  createColumn.display({
    id: 'passenger_first_name',
    size: 80,
    header() {
      return <Text>First</Text>;
    },
    cell: ({ row }) => {
      return (
        <Text style={commonStyles.textSm}>
          {row.original.PassengerFirstName}
        </Text>
      );
    },
  }),
  createColumn.display({
    id: 'passenger_last_name',
    size: 80,
    header() {
      return <Text>Last</Text>;
    },
    cell: ({ row }) => {
      return (
        <Text style={commonStyles.textSm}>
          {row.original.PassengerLastName}
        </Text>
      );
    },
  }),
  createColumn.display({
    id: 'passenger_dob',
    size: 65,
    header() {
      return <Text>DOB</Text>;
    },
    cell: ({ row }) => {
      return (
        <Text style={commonStyles.textSm}>
          {row.original.PassengerDOB ? (
            formatInvoicePDFDate(row.original.PassengerDOB)
          ) : (
            <Text style={{ textAlign: 'center' }}>&mdash;</Text>
          )}
        </Text>
      );
    },
  }),
  createColumn.display({
    id: 'payer_member_id',
    size: 60,
    header() {
      return <Text>Payer Member ID</Text>;
    },
    cell({ row }) {
      return (
        <Text style={commonStyles.textSm}>
          {row.original.ExternalPassengerID}
        </Text>
      );
    },
  }),

  createColumn.display({
    id: 'miles',
    size: 40,
    header() {
      return <Text style={commonStyles.textRight}>Miles</Text>;
    },
    cell: ({ row }) => {
      return (
        <Text style={[commonStyles.textSm, commonStyles.textRight]}>
          {parseFloat(row.original.Miles).toFixed(1)}
        </Text>
      );
    },
  }),
  createColumn.display({
    id: 'price',
    size: 75,
    header() {
      return <Text style={commonStyles.textRight}>Price</Text>;
    },
    cell: ({ row }) => {
      const amount = row.original.ItemAmount
        ? parseInt(row.original.ItemAmount) / 100
        : 0;
      return (
        <Text style={[commonStyles.textSm, commonStyles.textRight]}>
          {currencyFormatter(amount)}
        </Text>
      );
    },
  }),
];

interface InvoicePDFTripTableProps {
  rows: InvoiceExportCondensedReportRow[];
  selectedInvoiceColumns: SelectOption<InvoiceColumnKey>[];
}

export function InvoicePDFTripTable({
  rows,
  selectedInvoiceColumns,
}: InvoicePDFTripTableProps) {
  const tripLineItems = useMemo(() => {
    return rows.filter((item) => item.LineItemType === 'trip');
  }, [rows]);

  const selectedInvoiceColumnMap = keyBy(selectedInvoiceColumns, 'value');

  const columnsToRender = columns.filter((c) => {
    // Assuming that if it doesnt have an id, we don't allow customization
    if (!c.id) {
      return true;
    }

    // Using unknown here to avoid an incorrect "always truthy" resolution.
    const isSelected: unknown = selectedInvoiceColumnMap[c.id];

    return !!isSelected;
  });

  return (
    <View>
      <PDFTable columns={columnsToRender} data={tripLineItems} />
      <View style={commonStyles.separatorSm} />
    </View>
  );
}
