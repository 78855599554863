import {
  MembershipRead,
  useMembersListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { useAuth } from 'src/features/auth/useAuth';

export function useNonDriverMembers(search?: string) {
  const auth = useAuth();
  const queryState = useMembersListQuery({
    'Organization-ID': auth.currentOrganizationId || '',
    isDriver: false,
    isBambiStaff: false,
    search,
  });

  const members: MembershipRead[] = queryState.data?.results || [];

  return {
    members,
    queryState,
  };
}
