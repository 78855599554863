import { DateTime } from 'luxon';

interface RefundDateCellProps {
  date: string;
}
export function RefundDateCell({ date }: RefundDateCellProps) {
  return (
    <div>{DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT)}</div>
  );
}
