import { useCallback, useEffect, useMemo, useState } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { DocumentCheckIcon } from '@heroicons/react/24/outline';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { groupBy } from 'lodash-es';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { useAppSelector } from 'src/app/store';
import {
  useBillingBillingSettingsRetrieveQuery,
  useOrganizationSettingsRetrieveQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { FormField } from 'src/common/FormField';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { SimpleContextFormField } from 'src/common/FormField/SimpleContextFormField';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { useAuth } from 'src/features/auth/useAuth';

import { InvoicePDFDocument } from '../../../pdf/InvoicePDFDocument';
import { InvoiceExportCondensedReportItem } from '../../../types';
import {
  columnOptions,
  columnOptionsMap,
  InvoiceColumnSelect,
  InvoiceSelectedOptions,
} from './components/InvoiceColumnSelect';

type PDFGenerationOptions = {
  fileName: string;
  invoiceNote: string;
  includePriceSummary: boolean;
  selectedInvoiceColumns: InvoiceSelectedOptions;
};

interface CondensedPDFStep2Props {
  onClose?: () => void;
  next: () => void;
  prev: () => void;
}

export function CondensedPDFStep2({
  onClose,
  next,
  prev,
}: CondensedPDFStep2Props) {
  const { currentOrganizationName } = useAuth();

  const report = useAppSelector((state) => state.invoice.exportedCondensedData);
  const { data: billingSettings, isLoading: isLoadingBillingSettings } =
    useBillingBillingSettingsRetrieveQuery({});
  const { data: orgSettings, isLoading: isLoadingOrgSettings } =
    useOrganizationSettingsRetrieveQuery({});
  const [showPdfLink, setShowPdfLink] = useState(false);

  const invoices = groupBy(report?.records ?? [], (r) => r.InvoiceNo);
  const payers = groupBy(report?.records ?? [], (r) => r.ItemPayerID);
  const tripLineItems =
    report?.records.filter((r) => r.LineItemType === 'trip') ?? [];
  const trips = groupBy(tripLineItems, (r) => r.TripID);

  const form = useForm<PDFGenerationOptions>({
    mode: 'onChange',
    defaultValues: {
      fileName: report?.fileName,
      includePriceSummary: true,
      selectedInvoiceColumns: columnOptions,
    },
  });

  const handleRenderPdf = useCallback<
    SubmitHandler<PDFGenerationOptions>
  >(() => {
    setShowPdfLink(true);
  }, [setShowPdfLink]);

  useEffect(() => {
    if (isLoadingBillingSettings) {
      return;
    }

    form.setValue('invoiceNote', billingSettings?.invoice_note ?? '');
  }, [billingSettings, form, isLoadingBillingSettings]);

  const invoiceItems = useMemo<InvoiceExportCondensedReportItem[]>(() => {
    const items: InvoiceExportCondensedReportItem[] = [];

    Object.keys(invoices).forEach((invoiceNo) => {
      const recordsByInvoice = invoices[invoiceNo];
      const firstItem = recordsByInvoice[0];
      const item: InvoiceExportCondensedReportItem = {
        InvoiceNo: invoiceNo,
        ItemPayerID: firstItem.ItemPayerID,
        ItemPayerName: firstItem.ItemPayerName,
        items: recordsByInvoice,
        InvoiceDueAmount: firstItem.InvoiceDueAmount,
        PaymentsTotalAmount: firstItem.PaymentsTotalAmount,
        ItemPayerBillingAddress: firstItem.ItemPayerBillingAddress,
        ItemPayerBillingContactName: firstItem.ItemPayerBillingContactName,
        ItemPayerBillingContactPhone: firstItem.ItemPayerBillingContactPhone,
        ItemPayerBillingContactEmail: firstItem.ItemPayerBillingContactEmail,
        ItemPayerInvoiceDueDays: firstItem.ItemPayerInvoiceDueDays,
        InvoiceNotes: firstItem.InvoiceNotes,
        ModifiersTotalAmount: firstItem.ModifiersTotalAmount,
        TripsTotalAmount: firstItem.TripsTotalAmount,
        InvoiceDate: firstItem.InvoiceDate,
      };

      items.push(item);
    });

    return items;
  }, [invoices]);

  const values = form.getValues();
  const resolvedSelectedInvoiceColumns = useMemo(() => {
    return values.selectedInvoiceColumns.map((option) => {
      if (typeof option !== 'string') {
        return option;
      }
      const resolvedOption = columnOptionsMap[option];
      return resolvedOption;
    });
  }, [values]);

  // Somehow skipped step 1, or was unable to assign report data
  if (!report?.records.length) {
    return (
      <div className="mt-2">
        <h2>Something went wrong, unable to find any records.</h2>
        <span>Go back to the previous step and adjust your filters.</span>
      </div>
    );
  }

  if (isLoadingBillingSettings || isLoadingOrgSettings) {
    return (
      <div className="mt-2 flex flex-row justify-center">
        <LoadingIndicator />
      </div>
    );
  }

  // Last step, we have data and generation settings go ahead and render the
  // document link
  if (showPdfLink) {
    return (
      <div className="mt-2 flex flex-row justify-center">
        <PDFDownloadLink
          fileName={values.fileName}
          document={
            <InvoicePDFDocument
              organizationName={currentOrganizationName}
              organizationBillingAddress={
                orgSettings?.billing_address ??
                orgSettings?.headquarters_location?.address ??
                ''
              }
              organizationBillingPhone={billingSettings?.phone_number ?? ''}
              organizationBillingEmail={billingSettings?.email ?? ''}
              organizationLogo={orgSettings?.logo ?? ''}
              organizationTaxId={orgSettings?.tax_id ?? ''}
              invoiceItems={invoiceItems}
              invoiceNote={values.invoiceNote}
              includePriceSummary={values.includePriceSummary}
              selectedInvoiceColumns={resolvedSelectedInvoiceColumns}
            />
          }
        >
          {({ loading, error, url }) => {
            if (loading || !url) {
              return (
                <div className="flex flex-col items-center gap-2">
                  <img
                    className="aspect-square h-12 w-12"
                    src={process.env.PUBLIC_URL + '/icons/document.gif'}
                    alt="Rendering PDF"
                    data-attribution="Paper animated icons created by Freepik - Flaticon"
                  />
                  Rendering PDF
                </div>
              );
            }

            if (error) {
              return (
                <span className="text-red-500">
                  Something unexpected happened while rendering: {error.message}
                </span>
              );
            }

            return (
              <div className="mt-2 flex flex-row">
                <span className="text-green-500">
                  <DocumentCheckIcon width={32} height={32} />
                </span>

                <span
                  className="text-md border-none px-3 py-2 text-blue-600 underline"
                  onClick={() => {
                    // Delaying slightly because we need the link to open before
                    // closing the modal so we don't kill the reference to the blob
                    // that was just created
                    setTimeout(() => onClose?.(), 500);
                    datadogLogs.logger.warn('invoice-export:v1');
                  }}
                >
                  Click here to open the report
                </span>
              </div>
            );
          }}
        </PDFDownloadLink>
      </div>
    );
  }

  // Step 1 - Generation options
  return (
    <div className="mt-2">
      <FormProvider {...form}>
        <div>
          <div className="flex flex-col gap-2">
            <div className="my-4 flex flex-row justify-evenly gap-2">
              <div className="text-2xl" data-testid="invoice-count-stat-block">
                <strong>{Object.keys(invoices).length}</strong> Invoices
              </div>
              <div className="text-2xl" data-testid="payer-count-stat-block">
                <strong>{Object.keys(payers).length}</strong> Payers
              </div>
              <div className="text-2xl" data-testid="trip-count-stat-block">
                <strong>{Object.keys(trips).length}</strong> Trips
              </div>
            </div>
            <p>
              Customize invoice settings before generating the PDF. Any options
              selected here will override the default payer settings for each
              invoice.
            </p>
            <strong>Generation options:</strong>
            <FormFieldController name="includePriceSummary">
              {({ field, fieldState }) => {
                return (
                  <FormField
                    label="Include Price Summary"
                    error={fieldState.error?.message?.toString()}
                    type="switch"
                    inputProps={{
                      id: 'includePriceSummary',
                      ...field,
                      checked: field.value,
                    }}
                  />
                );
              }}
            </FormFieldController>
            <InvoiceColumnSelect
              fieldPath="selectedInvoiceColumns"
              label="Invoice columns"
            />
            <SimpleContextFormField fieldPath="fileName" label="Filename" />
            <SimpleContextFormField
              fieldPath="invoiceNote"
              label="Note to payer"
            />
          </div>
          <div className="grid grid-cols-2 gap-4 pt-6">
            <Button onClick={onClose}>Cancel</Button>
            <Button
              dataTestId="create-pdf"
              variant="primary"
              onClick={form.handleSubmit(handleRenderPdf)}
            >
              Create PDF
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
}
