import { Controller, useFormContext } from 'react-hook-form';

import { FormLabel } from 'src/common/FormField/FormLabel';
import { Button } from 'src/common/primitives/Button';

import { SettingsRowHeader } from './components/SettingsRowHeader';

export function CustomizationSettings() {
  const { control } = useFormContext();

  return (
    <div className="grid grid-cols-1 gap-x-4 gap-y-4 border-b border-gray-900/10 py-5 md:grid-cols-4">
      <SettingsRowHeader
        title="Bambi Customization Settings"
        description="Organization defaults for visual customizations within the web app."
      />

      <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-4 md:col-span-3">
        <div>
          <Controller
            control={control}
            name="header_background_color"
            render={({ field }) => {
              const colorOptions = ['#FFFFFF', '#d2effa', '#76e1c7', '#e8dffc'];

              return (
                <div className="flex flex-col gap-1">
                  <FormLabel htmlFor="header-background-color-options">
                    Header background color
                  </FormLabel>
                  <div
                    className="flex flex-row gap-2"
                    id="header-background-color-options"
                  >
                    {colorOptions.map((color) => {
                      const colorSelected = color === field.value;
                      return (
                        <Button
                          dataTestId={`color-${color}`}
                          key={color}
                          onClick={() => field.onChange(color)}
                          buttonProps={{
                            style: {
                              ...(colorSelected
                                ? {
                                    borderColor: 'black',
                                  }
                                : undefined),
                            },
                          }}
                        >
                          <div className="flex flex-col gap-2">
                            <div
                              style={{
                                backgroundColor: color,
                                border: '1px solid #000',
                                borderRadius: '100%',
                                width: '50px',
                                height: '50px',
                              }}
                            />
                          </div>
                        </Button>
                      );
                    })}
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}
