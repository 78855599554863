import { useCallback, useMemo } from 'react';

import {
  CloudArrowDownIcon,
  CurrencyDollarIcon,
  PencilIcon,
  PrinterIcon,
} from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';

import { InvoiceRead } from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { Popover } from 'src/common/primitives/Popover';

import {
  editInvoice,
  ExportOptions,
  setInvoiceModalOpen,
  setPayModalOpen,
} from '../../invoice.slice';
import { reportOptions } from '../../modals/DownloadInvoicesModal';
import { PrintableInvoiceTrigger } from '../../print/PrintableInvoice';
import { useExportInvoices } from '../../useExportInvoices';
import { useInvoiceTotal } from '../../useInvoiceTotal';
import { DeleteInvoiceTrigger } from './DeleteInvoiceTrigger';

export function ManageCell({ invoice }: { invoice: InvoiceRead }) {
  const dispatch = useDispatch();
  const { invoiceTotalDue } = useInvoiceTotal(invoice);

  const handleEditInvoice = useCallback(() => {
    dispatch(editInvoice(invoice));
    dispatch(setInvoiceModalOpen(true));
  }, [dispatch, invoice]);

  const handleShowPay = useCallback(() => {
    dispatch(editInvoice(invoice));
    dispatch(setPayModalOpen(true));
  }, [dispatch, invoice]);
  const { handleExport, isLoading: isFetchingInvoice } = useExportInvoices();

  const canDeleteInvoice = useMemo(() => {
    const hasPayments = !!(invoice.payments?.length ?? 0);

    return !hasPayments;
  }, [invoice.payments]);

  return (
    <div className="flex flex-row items-center justify-end">
      <Button
        dataTestId={`edit-invoice-${invoice.id}`}
        variant="icon"
        ariaLabel={`Edit Invoice: ${invoice.number}`}
        onClick={() => handleEditInvoice()}
      >
        <div
          className="flex flex-col items-center text-xs"
          style={{ fontSize: '0.625rem' }}
        >
          <PencilIcon className="mt-1 h-[20px] w-[24px]" />
          Edit
        </div>
      </Button>
      <Button
        dataTestId={`pay-invoice-${invoice.id}`}
        variant="icon"
        onClick={() => handleShowPay()}
        disabled={invoiceTotalDue === 0}
        ariaLabel={`Pay Invoice: ${invoice.number}`}
      >
        <div
          className="flex flex-col items-center text-xs"
          style={{ fontSize: '0.625rem' }}
        >
          <CurrencyDollarIcon className="w-[24px]" />
          Pay
        </div>
      </Button>
      <ExportInvoiceButton
        onSelectReport={(reportType) => {
          handleExport(reportType, [invoice.id]);
        }}
        invoiceId={invoice.id}
        invoiceNumber={invoice.number}
        isLoading={isFetchingInvoice}
      />
      <PrintableInvoiceTriggerWithFormContext invoice={invoice} />
      <DeleteInvoiceTrigger
        invoiceId={invoice.id}
        invoiceNumber={invoice.number}
        disabled={!canDeleteInvoice}
        label="Delete"
      />
    </div>
  );
}

function PrintableInvoiceTriggerWithFormContext({
  invoice,
}: {
  invoice: InvoiceRead;
}) {
  return (
    <PrintableInvoiceTrigger
      invoice={invoice}
      variant="icon"
      dataTestId={`print-invoice-${invoice.id}`}
      ariaLabel={`Print Invoice: ${invoice.number}`}
    >
      <div
        className="flex flex-col items-center text-xs"
        style={{ fontSize: '0.625rem' }}
      >
        <PrinterIcon className="w-[24px]" />
        Print
      </div>
    </PrintableInvoiceTrigger>
  );
}

interface ExportInvoiceButtonProps {
  invoiceId: string;
  invoiceNumber: string;
  isLoading: boolean;
  onSelectReport: (type: ExportOptions) => void;
}

function ExportInvoiceButton({
  invoiceId,
  invoiceNumber,
  isLoading,
  onSelectReport,
}: ExportInvoiceButtonProps) {
  return (
    <Popover
      content={
        <div className="flex flex-col gap-1">
          {reportOptions.map((option) => (
            <div key={option.value}>
              <Button
                variant="ghost"
                className="flex w-full flex-row gap-2 border border-mint"
                justifyContent="start"
                onClick={() => onSelectReport(option.value as ExportOptions)}
              >
                {option.icon}
                <h3 className="text-sm text-slate-600">{option.label}</h3>
              </Button>
            </div>
          ))}
        </div>
      }
      trigger={
        <Button
          dataTestId={`export-invoice-${invoiceId}`}
          variant="icon"
          ariaLabel={`Export Invoice: ${invoiceNumber}`}
          loading={isLoading}
        >
          <div
            className="flex flex-col items-center text-xs"
            style={{ fontSize: '0.625rem' }}
          >
            <CloudArrowDownIcon className="w-[24px]" />
            <span>Export</span>
          </div>
        </Button>
      }
    />
  );
}
