import { PriceSummaryData } from './types';

export function WaitTimeDetails({ data }: { data?: PriceSummaryData | null }) {
  return (
    <>
      <td className="px-2 py-1 text-center text-xs">
        <div className="underline">Period</div>
        <div>{data?.period || '--'}</div>
      </td>
      <td className="px-2 py-1 text-center text-xs">
        <div className="underline">Free Minutes</div>
        <div>{data?.free_minutes || '--'}</div>
      </td>
      <td className="px-2 py-1 text-center text-xs">
        <div className="underline">Minutes</div>
        <div>{data?.minutes || '--'}</div>
      </td>
    </>
  );
}
