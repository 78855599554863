import { ReactElement, useEffect } from 'react';

import { SessionHandler } from '@sendbird/chat';
import { SendBirdProvider } from '@sendbird/uikit-react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'src/app/store';
import { useLazyTokensChatIssueRetrieveQuery } from 'src/common/external/bambi-api/bambiApi';
import { useAuth } from 'src/features/auth/useAuth';

import { useUserRoles } from '../auth/useUserRoles';
import { setChatError, setSendbirdAccessToken } from './chat.slice';
import SendbirdConnectionManager from './SendbirdConnectionManager';

export default function ChatProvider({ children }: { children: ReactElement }) {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [getChatToken] = useLazyTokensChatIssueRetrieveQuery();
  const sendBirdAccessToken = useAppSelector(
    (state) => state.chat.sendbirdAccessToken
  );

  const isChatEnabled =
    process.env.REACT_APP_SENDBIRD_CHAT_ENABLED === 'true' &&
    auth.sendbirdAppId;

  const { isAssociate, isRideOrderingPortalUser } = useUserRoles();

  useEffect(() => {
    if (isChatEnabled && !isAssociate && !isRideOrderingPortalUser) {
      dispatch(setChatError(false));
      getChatToken({
        'Organization-ID': auth.currentOrganizationId,
      }).then(({ data, error }) => {
        if (error) {
          dispatch(setChatError(true));
        } else if (data) {
          dispatch(setSendbirdAccessToken(data.token));
        }
      });
    }
  }, [
    auth.currentOrganizationId,
    dispatch,
    getChatToken,
    isAssociate,
    isRideOrderingPortalUser,
    isChatEnabled,
  ]);

  if (!auth.currentMembership?.id || !isChatEnabled || !sendBirdAccessToken) {
    return children;
  }

  const configureSession = () => {
    const sessionHandler = new SessionHandler();
    sessionHandler.onSessionTokenRequired = (resolve, reject) => {
      getChatToken({
        'Organization-ID': auth.currentOrganizationId,
      }).then(({ data, error }) => {
        if (error) {
          reject(error as Error);
        } else if (data) {
          dispatch(setSendbirdAccessToken(data.token));
          resolve(data.token);
        }
      });
    };
    return sessionHandler;
  };

  return (
    <SendBirdProvider
      appId={auth.sendbirdAppId?.toUpperCase() || ''}
      userId={auth.currentMembership.id}
      configureSession={configureSession}
      accessToken={sendBirdAccessToken}
    >
      <SendbirdConnectionManager>{children}</SendbirdConnectionManager>
    </SendBirdProvider>
  );
}
