import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { hide } from 'src/common/primitives/Toast/toast.slice';

import { Toast } from './Toast';

export function ToastRenderer() {
  const toasts = useAppSelector((state) => state.toast.toasts);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const toastDuration = toasts[0]?.duration || 3000;
    const timeout = setTimeout(() => {
      if (toasts.length > 0) {
        dispatch(hide(toasts[0]));
      }
    }, toastDuration);
    return () => clearTimeout(timeout);
  }, [dispatch, toasts]);

  return (
    <>
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          title={toast.title}
          description={toast.description}
          type={toast.type}
          descriptionType={toast.descriptionType}
          descriptionData={toast.descriptionData}
          onClose={() => {
            dispatch(hide(toast));
          }}
        />
      ))}
    </>
  );
}
