import * as ToastPrimitive from '@radix-ui/react-toast';

import { ToastRenderer } from './ToastRenderer';
import { ToastViewport } from './ToastViewport';

export function ToastConfigProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    // setting duration to 0 to prevent the toast from closing automatically,
    // we handle the duration in the ToastRenderer
    <ToastPrimitive.Provider duration={0}>
      {children}
      <ToastRenderer />
      <ToastViewport />
    </ToastPrimitive.Provider>
  );
}
