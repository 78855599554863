import { ReactNode, useState } from 'react';

import * as RadixPopover from '@radix-ui/react-popover';
import { DateTime } from 'luxon';
import { DateRange, DayPicker } from 'react-day-picker';

import { Popover, PopoverProps } from 'src/common/primitives/Popover';

interface DateRangePickerProps {
  triggerElement: ReactNode;
  open?: boolean;
  side?: PopoverProps['side'];
  onDateRangeChange: (dateRange: DateRange) => void;
  onClose?: () => void;
}
export default function DateRangePicker({
  triggerElement,
  open,
  side,
  onDateRangeChange,
  onClose,
}: DateRangePickerProps) {
  // timeZone is set in useAuth line 99
  const timeZone = DateTime.local().zoneName;

  const [dateRange, setDateRange] = useState<DateRange>();

  const handleSelectRange = (range: DateRange | undefined) => {
    setDateRange(range);
  };

  const handleApply = () => {
    if (dateRange) onDateRangeChange(dateRange);
  };

  const onOpenChange = (open: boolean) => {
    if (!open) {
      setDateRange({ to: undefined, from: undefined });
      onClose?.();
    }
  };

  return (
    <>
      <style>{`
      .rdp-root {
        --rdp-accent-color: #0CACA6;
        --rdp-accent-background-color: rgba(12, 172, 166, 0.15);
      }
    `}</style>
      <Popover
        open={open}
        onOpenChange={onOpenChange}
        trigger={triggerElement}
        side={side}
        content={
          <>
            <DayPicker
              mode="range"
              timeZone={timeZone}
              selected={dateRange}
              onSelect={handleSelectRange}
              captionLayout="dropdown"
              startMonth={new Date(2020, 0)}
              endMonth={new Date(2030, 0)}
            />
            <div className="grid grid-cols-2 border-t border-gray-200 py-2">
              <RadixPopover.Close className="flex">
                <div className="focus:ring-indigo-500 mr-2 inline-flex flex-grow items-center justify-center rounded border border-gray-100 bg-gray-100 px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-300 focus:outline-mint disabled:cursor-not-allowed disabled:opacity-50">
                  Cancel
                </div>
              </RadixPopover.Close>
              <RadixPopover.Close onClick={handleApply} className="flex">
                <div className="focus:ring-indigo-500 inline-flex flex-grow items-center justify-center rounded border border-mint bg-mint px-3 py-2 text-sm font-medium text-white hover:bg-mint focus:outline-mint disabled:cursor-not-allowed disabled:opacity-50">
                  Apply
                </div>
              </RadixPopover.Close>
            </div>
          </>
        }
      ></Popover>
    </>
  );
}
