import { TripCreateRead } from 'src/common/external/bambi-api/bambiApi';

import { TripSummaryEventPersonName } from './TripSummaryEventPersonName';
import { TripSummaryOccuredAt } from './TripSummaryOccuredAt';
import { TripSummaryTitle } from './TripSummaryTitle';

export function TripSummaryCreateItemHeader({
  tripEvent,
  isSelected,
}: {
  tripEvent: TripCreateRead;
  isSelected: boolean;
}) {
  return (
    <div
      className={`flex w-full flex-col gap-1 p-2 pr-12 text-left ${
        isSelected ? 'bg-mint-light' : 'bg-gray-50'
      }`}
    >
      <div className="flex w-full items-center justify-between gap-1">
        <TripSummaryTitle title="Created" />
        {tripEvent.parsed_trip?.created_at && (
          <TripSummaryOccuredAt occuredAt={tripEvent.parsed_trip.created_at} />
        )}
      </div>
      <div className="flex w-full flex-wrap items-center justify-between">
        <div>&nbsp;</div>
        {tripEvent.parsed_trip?.person_name && (
          <TripSummaryEventPersonName
            name={tripEvent.parsed_trip.person_name}
          />
        )}
      </div>
    </div>
  );
}
