import { useCallback, useState } from 'react';

import { useAppDispatch } from 'src/app/store';
import { useLazyGetInvoicesExportListBySelectedInvoiceCsvQuery } from 'src/common/external/bambi-api/emptyApi';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import {
  ExportOptions,
  setExportedCondensedReportData,
  setExportInvoicesReportType,
  setShowExportInvoicesModal,
  setSkipExportGatherDataStep,
} from './invoice.slice';

// Unified handler to export invoices by invoice id
export function useExportInvoices() {
  const [isLoading, setLoading] = useState(false);
  const [fetchInvoiceExportList] =
    useLazyGetInvoicesExportListBySelectedInvoiceCsvQuery();
  const dispatch = useAppDispatch();

  const handleExportInvoiceAsQuickbooksCSV = useCallback(
    async (invoices: string[]) => {
      try {
        await fetchInvoiceExportList({
          selectedInvoices: invoices,
          format: 'csv',
        }).unwrap();
      } catch (error) {
        dispatch(
          show({
            type: 'error',
            title: 'There was an error downloading the data.',
            description: formatServerError(error),
          })
        );
      }
    },
    [dispatch, fetchInvoiceExportList]
  );

  const handleExportInvoiceAsCondensedPDF = useCallback(
    async (invoices: string[]) => {
      try {
        const data = await fetchInvoiceExportList({
          selectedInvoices: invoices,
          format: 'pdf',
          outputType: 'condensed',
        }).unwrap();

        if (!data) {
          throw new Error('Missing data');
        }

        dispatch(setExportedCondensedReportData(data));
        dispatch(setExportInvoicesReportType('condensed'));
        dispatch(setSkipExportGatherDataStep(true));
        dispatch(setShowExportInvoicesModal(true));
      } catch (error) {
        dispatch(
          show({
            type: 'error',
            title: 'There was an error downloading the data.',
            description: formatServerError(error),
          })
        );
      }
    },
    [dispatch, fetchInvoiceExportList]
  );

  const handleExport = useCallback(
    async (type: ExportOptions, invoices: string[]) => {
      setLoading(true);
      if (type === 'quickbooks') {
        await handleExportInvoiceAsQuickbooksCSV(invoices);
      } else {
        await handleExportInvoiceAsCondensedPDF(invoices);
      }
      setLoading(false);
    },
    [handleExportInvoiceAsCondensedPDF, handleExportInvoiceAsQuickbooksCSV]
  );

  return {
    handleExport,
    isLoading,
  };
}
