import {
  PriceSummary,
  TripPriceSummaryRead,
} from 'src/common/external/bambi-api/bambiApi';

import { LabelDetailsCellMap } from './LabelDetailsCellRenderer';
import { FeeSummaryItem, PriceSummaryData, PriceSummaryLabel } from './types';

export const usePriceSummaryTableData = (
  summary?: TripPriceSummaryRead['price_summary'] | PriceSummary
) => {
  const getLabelDetails = (
    label: PriceSummaryLabel,
    data: PriceSummaryData
  ) => {
    return <LabelDetailsCellMap label={label} data={data} />;
  };

  const feeSummaries: FeeSummaryItem[] = [
    {
      label: 'Base Fee',
      data: summary?.base_fee_summary,
      subTotal: summary?.base_fee_summary?.sub_total,
    },
    {
      label: 'Minimum Trip Price',
      data: {
        minimum_price_cents: summary?.minimum_price_cents,
        sub_total: summary?.minimum_price_cents || 0,
      },
      subTotal:
        typeof summary?.minimum_price_cents === 'number'
          ? summary.minimum_price_cents
          : undefined,
    },
    {
      label: 'Loaded Mileage Fee',
      data: summary?.loaded_mileage_fee_summary,
      subTotal: summary?.loaded_mileage_fee_summary?.sub_total,
    },
    {
      label: 'Long Distance Mileage Rate',
      data: summary?.long_distance_loaded_mileage_fee_summary,
      subTotal: summary?.long_distance_loaded_mileage_fee_summary?.sub_total,
    },
    {
      label: 'Unloaded Pickup Mileage Fee',
      data: summary?.unloaded_mileage_fee_summary,
      subTotal: summary?.unloaded_mileage_fee_summary?.sub_total,
    },
    {
      label: 'Unloaded Return Mileage Fee',
      data: summary?.unloaded_return_mileage_fee_summary,
      subTotal: summary?.unloaded_return_mileage_fee_summary?.sub_total,
    },
    {
      label: 'Wait Time Fee',
      data: summary?.wait_time_fee_summary,
      subTotal: summary?.wait_time_fee_summary?.sub_total,
    },
    {
      label: 'No Show Fee',
      data: summary?.no_show_fee_summary,
      subTotal: summary?.no_show_fee_summary?.sub_total,
    },
    {
      label: 'Late Cancellation Fee',
      data: summary?.late_cancellation_fee_summary,
      subTotal: summary?.late_cancellation_fee_summary?.sub_total,
    },
    {
      label: 'Pickup Stair Fee',
      data: summary?.pickup_stair_fee_summary,
      subTotal: summary?.pickup_stair_fee_summary?.sub_total,
    },
    {
      label: 'Dropoff Stair Fee',
      data: summary?.dropoff_stair_fee_summary,
      subTotal: summary?.dropoff_stair_fee_summary?.sub_total,
    },
    {
      label: 'Additional Attendants Fee',
      data: summary?.additional_attendants_fee_summary,
      subTotal: summary?.additional_attendants_fee_summary?.sub_total,
    },
    {
      label: 'Additional Passengers Fee',
      data: summary?.additional_passengers_fee_summary,
      subTotal: summary?.additional_passengers_fee_summary?.sub_total,
    },
    {
      label: 'Bariatric Fee',
      data: summary?.bariatric_fee_summary,
      subTotal: summary?.bariatric_fee_summary?.sub_total,
    },
    {
      label: 'Wheelchair Rental Fee',
      data: summary?.wheelchair_rental_fee_summary,
      subTotal: summary?.wheelchair_rental_fee_summary?.sub_total,
    },
    {
      label: 'Oxygen Fee',
      data: summary?.oxygen_fee_summary,
      subTotal: summary?.oxygen_fee_summary?.sub_total,
    },
    {
      label: 'After Hours Fee',
      data: summary?.after_hours_fee_summary,
      subTotal: summary?.after_hours_fee_summary?.sub_total,
    },
    {
      label: 'Weekend Fee',
      data: summary?.weekend_fee_summary,
      subTotal: summary?.weekend_fee_summary?.sub_total,
    },
    {
      label: 'Holiday Fee',
      // TODO: Types don't match
      // @ts-ignore
      data: summary?.holiday_fee_summary,
      // @ts-ignore
      subTotal: summary?.holiday_fee_summary?.sub_total,
    },
    {
      label: 'Infectious Disease Fee',
      data: summary?.infectious_disease_fee_summary,
      subTotal: summary?.infectious_disease_fee_summary?.sub_total,
    },
    {
      label: 'Same Day Booking Fee',
      data: summary?.same_day_booking_fee_summary,
      subTotal: summary?.same_day_booking_fee_summary?.sub_total,
    },
  ];

  return { feeSummaries, getLabelDetails };
};
