import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  PayerOrganizationRead,
  PayerOrganizationSettingsRead,
} from 'src/common/external/bambi-api/bambiApi';

export type PayerAndPayerSettings = PayerOrganizationRead &
  PayerOrganizationSettingsRead;

export interface PayerState {
  payers: PayerOrganizationRead[];
  selectedPayer: PayerAndPayerSettings | undefined;
  payerSearchTerm: string;
  openPayersMenuId: string;
  showPayersModal: boolean;

  filterIsActive: boolean;
}

export const initialState: PayerState = {
  payers: [],
  selectedPayer: undefined,
  payerSearchTerm: '',
  openPayersMenuId: '',
  showPayersModal: false,
  filterIsActive: true,
};

export const payerSlice = createSlice({
  name: 'payer',
  initialState,
  reducers: {
    setSelectedPayer: (state, action) => {
      state.selectedPayer = action.payload;
    },
    setPayerSearchTerm: (state, action) => {
      state.payerSearchTerm = action.payload;
    },
    setOpenPayersMenuId: (state, action) => {
      state.openPayersMenuId = action.payload;
    },
    setShowPayersModal: (state, action) => {
      state.showPayersModal = action.payload;
    },
    setFilterIsActive(state, action: PayloadAction<boolean>) {
      state.filterIsActive = action.payload;
    },
  },
});

export const {
  setSelectedPayer,
  setPayerSearchTerm,
  setOpenPayersMenuId,
  setShowPayersModal,
  setFilterIsActive,
} = payerSlice.actions;
