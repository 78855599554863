import { useState } from 'react';

import { CheckIcon, CopyIcon } from '@radix-ui/react-icons';
import { DateTime } from 'luxon';

import { TripsBulkCreateRead } from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';

export default function TripCreateSuccessToastDescription({
  tripResponse,
}: {
  tripResponse: TripsBulkCreateRead;
}) {
  const passenger = `${tripResponse.trips[0].passenger.first_name} ${tripResponse.trips[0].passenger.last_name}`;
  const [copyClicked, setCopyClicked] = useState(false);

  const handleCopyClick = async () => {
    const tripDetails = tripResponse.trips
      .map((trip) => {
        const pickup = DateTime.fromISO(
          trip.scheduled_pickup_at
        ).toLocaleString(DateTime.DATETIME_SHORT);
        return `${pickup}\n${trip.confirmation_code}`;
      })
      .join('\n\n');

    const content = `${passenger}\n\n${tripDetails}`;
    await navigator.clipboard.writeText(content);
    setCopyClicked(true);

    setTimeout(() => {
      setCopyClicked(false);
    }, 3000);
  };

  return (
    <div className="flex flex-col">
      <div className="my-1 text-sm" data-testid="trip-success-toast-passenger">
        {passenger}
      </div>
      {tripResponse.trips.map((trip) => (
        <div key={trip.id} className="mt-1 text-xs text-gray-500">
          <div data-testid={`${trip.id}-trip-success-toast-pickup`}>
            {DateTime.fromISO(trip.scheduled_pickup_at).toLocaleString(
              DateTime.DATETIME_SHORT
            )}
          </div>
          <div
            className="flex items-center gap-2"
            data-testid={`${trip.id}-trip-success-toast-confirmation`}
          >
            {trip.confirmation_code}
          </div>
          <hr className="my-2" />
        </div>
      ))}

      <Button
        onClick={handleCopyClick}
        className="mt-2"
        variant={copyClicked ? 'primary' : 'secondary'}
        dataTestId="trip-success-toast-copy"
      >
        {copyClicked ? (
          <CheckIcon className="mr-1 h-5 w-5" />
        ) : (
          <CopyIcon className="mr-1 h-4 w-4" />
        )}
        {copyClicked ? 'Details Copied' : 'Copy Details'}
      </Button>
    </div>
  );
}
