import { Text, View } from '@react-pdf/renderer';
import { createColumnHelper } from '@tanstack/react-table';

import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import { commonStyles } from 'src/common/pdf/common.styles';
import { PDFTable } from 'src/common/pdf/PDFTable/PDFTable';
import { LabelDetailsCellMap } from 'src/features/pricing/PriceSummaryModal/PriceSummaryTable/LabelDetailsCellRenderer';
import { FeeSummaryItem } from 'src/features/pricing/PriceSummaryModal/PriceSummaryTable/types';

const createColumn = createColumnHelper<FeeSummaryItem>();
const columns = [
  createColumn.display({
    id: 'fee_type',
    header() {
      return <Text>Fee Type</Text>;
    },
    cell: ({ row }) => {
      return <Text style={commonStyles.textSm}>{row.original.label}</Text>;
    },
  }),
  createColumn.display({
    id: 'fee_details',
    header() {
      return <Text>Fee Details</Text>;
    },
    size: 300,
    cell: ({ row }) => {
      return (
        <View style={commonStyles.hstack}>
          <LabelDetailsCellMap
            label={row.original.label}
            data={row.original.data}
            type="pdf"
          />
        </View>
      );
    },
  }),
  createColumn.display({
    id: 'rate',
    header() {
      return <Text>Rate</Text>;
    },
    cell: ({ row }) => {
      return (
        <Text style={commonStyles.textSm}>
          {row.original.data?.rate
            ? defaultCurrencyFormat(row.original.data.rate, true)
            : '-'}
        </Text>
      );
    },
  }),
  createColumn.display({
    id: 'subtotal',
    header() {
      return <Text>Amount</Text>;
    },
    cell: ({ row }) => {
      return (
        <Text style={commonStyles.textSm}>
          {row.original.subTotal
            ? defaultCurrencyFormat(row.original.subTotal, true)
            : '-'}
        </Text>
      );
    },
  }),
];

interface InvoicePDFPriceSummaryTableProps {
  rows: FeeSummaryItem[];
}

export function InvoicePDFPriceSummaryTable({
  rows,
}: InvoicePDFPriceSummaryTableProps) {
  const visibleRows = rows.filter((r) => !!r.data && r.subTotal);
  return (
    <View style={{ paddingVertical: 4 }}>
      <Text style={[commonStyles.textMd, { color: '#71717a', marginTop: 2 }]}>
        Fee Table
      </Text>
      <PDFTable columns={columns} data={visibleRows} />
      <View style={commonStyles.separatorSm} />
    </View>
  );
}
