import { Text, View } from '@react-pdf/renderer';

import { commonStyles } from '../../../../common/pdf/common.styles';

interface InvoicePDFOrganizationHeaderProps {
  organizationName: string;
  invoiceNote: string;
}

export function InvoicePDFOrganizationHeader({
  organizationName,
  invoiceNote,
}: InvoicePDFOrganizationHeaderProps) {
  return (
    <View style={[commonStyles.vstack, { marginVertical: 16 }]}>
      <Text style={[commonStyles.textXl, commonStyles.strong]}>
        {organizationName}
      </Text>
      <Text style={[commonStyles.textMd]}>{invoiceNote}</Text>
    </View>
  );
}
