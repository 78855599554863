import { useEffect } from 'react';

import { useAppSelector } from 'src/app/store';
import { DataGrid } from 'src/common/DataGrid';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { DriverProfileForm } from '../DriverProfileForm/DriverProfileForm';
import { RestoreDriverProfileModal } from '../DriverProfileForm/modals/RestoreDriverProfileModal';
import { columns, columnsWithDeletedAt } from './columns';
import { DriverProfileEmptyState } from './DriverProfileEmptyState';
import { useCurrentOrganizationDriverProfiles } from './useCurrentOrganizationDriverProfiles';

export function DriverProfileRoute() {
  const { drivers, isLoading, isFetching, refetch } =
    useCurrentOrganizationDriverProfiles();

  const driverProfileSearchTerm = useAppSelector(
    (state) => state.team.driverProfileSearchTerm
  );
  const includeDeleted = useAppSelector(
    (state) => state.team.showDeletedDrivers
  );

  useEffect(() => {
    refetch();
  }, [driverProfileSearchTerm, includeDeleted, refetch]);

  return (
    <>
      <div>
        {isLoading || isFetching ? (
          <div className="my-72 flex justify-center">
            <LoadingIndicator />
          </div>
        ) : (
          <DataGrid
            tableId="driver-profile"
            columns={includeDeleted ? columnsWithDeletedAt : columns}
            data={drivers}
            totalCount={drivers.length}
            emptyState={<DriverProfileEmptyState />}
            getRowId={(row) => row.id}
          />
        )}
      </div>
      <DriverProfileForm />
      <RestoreDriverProfileModal />
    </>
  );
}
