import { PlusIcon } from '@heroicons/react/24/outline';

import { useAuth } from 'src/features/auth/useAuth';

import { Logo } from '../Logo';

export function NavLogo() {
  const { currentOrganizationSettings } = useAuth();

  return (
    <a href="/" className="flex flex-row items-center gap-2">
      <Logo />
      {currentOrganizationSettings?.logo ? (
        <span className="flex flex-row items-center gap-1">
          <PlusIcon className="w-4" />
          <img
            src={currentOrganizationSettings.logo}
            alt="Organization Logo"
            style={{ width: 100, height: 50 }}
          />
        </span>
      ) : null}
    </a>
  );
}
