import { Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import { commonStyles } from 'src/common/pdf/common.styles';

import { currencyFormatter } from '../forms/cells/utils';

interface InvoicePDFFooterProps {
  invoiceNotes: string;
  subTotalAmount: number;
  adjustmentAmount: number;
  paymentAmount: number;
  totalAmount: number;
}

export function InvoicePDFFooter({
  invoiceNotes,
  subTotalAmount,
  adjustmentAmount,
  paymentAmount,
  totalAmount,
}: InvoicePDFFooterProps) {
  const subTotalStyle: Style = {
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingVertical: 4,
    ...commonStyles.textSmedium,
  };

  return (
    <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
      <View style={{ paddingTop: 4, width: '65%' }}>
        <Text
          style={[
            commonStyles.textMd,
            commonStyles.upper,
            commonStyles.strong,
            commonStyles.letterSpacingSm,
          ]}
        >
          Notes:
        </Text>
        <Text>{invoiceNotes}</Text>
      </View>
      <View
        style={[
          {
            width: '30%',
          },
        ]}
      >
        <View style={subTotalStyle}>
          <Text style={commonStyles.strong}>Subtotal</Text>
          <Text>{currencyFormatter(subTotalAmount)}</Text>
        </View>
        {adjustmentAmount ? (
          <View style={subTotalStyle}>
            <Text style={commonStyles.strong}>Adjustments</Text>
            <Text>{currencyFormatter(adjustmentAmount)}</Text>
          </View>
        ) : null}
        {paymentAmount ? (
          <View style={subTotalStyle}>
            <Text style={commonStyles.strong}>Payments</Text>
            <Text>{currencyFormatter(paymentAmount)}</Text>
          </View>
        ) : null}
        <View
          style={[
            subTotalStyle,
            {
              backgroundColor: '#0caca64a',
            },
          ]}
        >
          <Text style={commonStyles.strong}>Total Due</Text>
          <Text>{currencyFormatter(totalAmount)}</Text>
        </View>
      </View>
    </View>
  );
}
