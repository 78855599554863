import { createColumnHelper } from '@tanstack/react-table';

import { CreditCardBrand } from 'src/common/primitives/icon/CreditCardBrand/CreditCardBrand';
import { CreditCardPaymentMethod } from 'src/common/primitives/PaymentMethod/CreditCardPaymentMethod';

import { RefundAmountCell } from './cells/refund/RefundAmountCell';
import { RefundDateCell } from './cells/refund/RefundDateCell';
import { InvoiceRefundWithPaymentMethod } from './cells/useHydratePaymentRefunds';

const columnHelper = createColumnHelper<InvoiceRefundWithPaymentMethod>();

export const columns = [
  columnHelper.accessor('created_at', {
    header: 'Date',
    cell({ getValue }) {
      return <RefundDateCell date={getValue()} />;
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.accessor('amount_cents', {
    header: 'Amount',
    cell({ getValue }) {
      return <RefundAmountCell value={getValue()} />;
    },

    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.display({
    id: 'paymentMethod',
    header: 'Payment Method',
    cell({ row }) {
      const paymentMethod = row.original.paymentMethod;

      if (!paymentMethod) {
        return <div>&mdash;</div>;
      }

      return (
        <CreditCardPaymentMethod
          id={paymentMethod.id}
          brand={paymentMethod.card.brand as CreditCardBrand}
          last4={paymentMethod.card.last4}
          expMonth={paymentMethod.card.exp_month}
          expYear={paymentMethod.card.exp_year}
          holderName={paymentMethod.card.holder_name}
          billingDetails={paymentMethod.billing_details}
        />
      );
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    meta: {
      enableHeaderMenu: false,
    },
  }),
];
