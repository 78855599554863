import { View } from '@react-pdf/renderer';
import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';

import { commonStyles } from 'src/common/pdf/common.styles';

interface PDFTableProps<T> {
  columns: ColumnDef<T, any>[];
  data: Array<T>;
  rowHeight?: number;
  renderExpandedRow?: (row: Row<T>) => JSX.Element;
  expandedState?: ExpandedState;
}
export function PDFTable<T>({
  columns,
  data,
  rowHeight,
  renderExpandedRow,
  expandedState,
}: PDFTableProps<T>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: () => true,
    state: {
      expanded: expandedState,
    },
  });

  return (
    <View style={[commonStyles.fullWidth]}>
      <View fixed>
        <View style={commonStyles.separatorSm} />
        {table.getHeaderGroups().map((headerGroup) => (
          <View
            key={headerGroup.id}
            style={{
              justifyContent: 'space-evenly',
              flexDirection: 'row',
              paddingVertical: 4,
            }}
          >
            {headerGroup.headers.map((header) => {
              const headerContent = header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  );

              // TODO detect default rendering and wrap with <Text>
              return (
                <View
                  key={header.id}
                  style={[
                    commonStyles.strong,
                    commonStyles.letterSpacingSm,
                    commonStyles.upper,
                    commonStyles.textSm,
                    {
                      width: header.getSize(),
                    },
                  ]}
                >
                  {headerContent}
                </View>
              );
            })}
          </View>
        ))}
        <View style={commonStyles.separatorSm} />
      </View>
      <View>
        {table.getRowModel().rows.map((row) => (
          <View wrap={false} key={row.id}>
            <View
              style={
                rowHeight
                  ? {
                      height: rowHeight,
                    }
                  : undefined
              }
            >
              <View
                style={{
                  justifyContent: 'space-evenly',
                  flexDirection: 'row',
                  paddingVertical: 4,
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <View
                    key={cell.id}
                    style={[
                      {
                        width: cell.column.getSize(),
                      },
                    ]}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </View>
                ))}
              </View>
            </View>
            {row.getIsExpanded() && renderExpandedRow ? (
              <View>{renderExpandedRow(row)}</View>
            ) : null}
          </View>
        ))}
      </View>
    </View>
  );
}
