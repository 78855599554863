import { createColumnHelper } from '@tanstack/react-table';
import { DateTime } from 'luxon';

import {
  InvoicePaymentRead,
  TilledPaymentMethodResponse,
} from 'src/common/external/bambi-api/bambiApi';
import { CreditCardBrand } from 'src/common/primitives/icon/CreditCardBrand/CreditCardBrand';
import { CreditCardPaymentMethod } from 'src/common/primitives/PaymentMethod/CreditCardPaymentMethod';

import { PaymentAmountCell } from './cells/PaymentAmountCell';
import { RefundPaymentCell } from './cells/RefundPaymentCell';

export type InvoicePaymentWithPaymentMethod = InvoicePaymentRead & {
  paymentMethod?: TilledPaymentMethodResponse;
};

const columnHelper = createColumnHelper<InvoicePaymentWithPaymentMethod>();

export const columns = [
  columnHelper.accessor('created_at', {
    header: 'Date',
    cell({ getValue }) {
      return DateTime.fromISO(getValue()).toLocaleString(
        DateTime.DATETIME_SHORT
      );
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.accessor('amount_cents', {
    header: 'Amount',
    cell({ getValue, row }) {
      return (
        <PaymentAmountCell
          amountCents={getValue()}
          chargeStatus={row.original.charge_status}
          refunds={row.original.refunds}
          paymentFailureMessage={row.original.failure_message}
          isManual={row.original.is_manual}
          payment={row.original}
        />
      );
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.display({
    id: 'paymentMethod',
    header: 'Payment Method',
    cell({ row }) {
      const paymentMethod = row.original.paymentMethod;

      if (!paymentMethod || row.original.is_manual) {
        return <div>&mdash;</div>;
      }

      return (
        <CreditCardPaymentMethod
          id={paymentMethod.id}
          brand={paymentMethod.card.brand as CreditCardBrand}
          last4={paymentMethod.card.last4}
          expMonth={paymentMethod.card.exp_month}
          expYear={paymentMethod.card.exp_year}
          holderName={paymentMethod.card.holder_name}
          billingDetails={paymentMethod.billing_details}
        />
      );
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.display({
    id: 'actions',
    header() {
      return <>&nbsp;</>;
    },
    cell({ row }) {
      const paymentFailed = row.original.charge_status === 'failed';
      if (paymentFailed) {
        return null;
      }
      return <RefundPaymentCell payment={row.original} />;
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
];
