import { View } from '@react-pdf/renderer';

import { FeeLabelPDF } from './components/FeeLabelPDF';
import { PriceSummaryData } from './types';

export function MileageDetailsPDF({
  data,
}: {
  data?: PriceSummaryData | null;
}) {
  return (
    <>
      <View />
      <FeeLabelPDF label="Free Miles" value={data?.free_miles} />
      <FeeLabelPDF label="Miles" value={data?.miles?.toFixed(2)} />
    </>
  );
}
