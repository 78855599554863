import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { MembershipRead } from 'src/common/external/bambi-api/bambiApi';

export const memberAdapter = createEntityAdapter<MembershipRead>();

const { addOne, setAll, updateOne } = memberAdapter;

export const initialState = {
  ...memberAdapter.getInitialState(),
  selectedMember: undefined as MembershipRead | undefined | null,
  showMemberForm: false,
};

export const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    addMember: addOne,
    setMembers: setAll,
    updateMember: updateOne,
    setSelectedMember: (
      state,
      action: PayloadAction<MembershipRead | undefined | null>
    ) => {
      state.selectedMember = action.payload;
    },
    setShowMemberForm: (state, action: PayloadAction<boolean>) => {
      state.showMemberForm = action.payload;
    },
  },
});

export const {
  addMember,
  setMembers,
  updateMember,
  setSelectedMember,
  setShowMemberForm,
} = memberSlice.actions;
