import { useEffect, useMemo } from 'react';

import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import { InvoicePaymentRead } from 'src/common/external/bambi-api/bambiApi';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { Well } from 'src/common/primitives/Well/Well';

import { usePrintableInvoiceContext } from '../print/PrintableInvoiceContext';
import { useHydratePaymentRefunds } from './cells/useHydratePaymentRefunds';
import { PaymentsDataGrid } from './PaymentsDataGrid';
import { RefundsDataGrid } from './RefundsDataGrid';
import {
  PayerInfoPair,
  useHydratePaymentMethods,
} from './useHydratePaymentMethods';
import { useHydratePayments } from './useHydratePayments';

interface PaymentsSectionProps {
  payments: InvoicePaymentRead[];
  invoiceTotalDue: number;
}

export function PaymentsSection({
  payments,
  invoiceTotalDue,
}: PaymentsSectionProps) {
  const payerInfoPairs: PayerInfoPair[] = useMemo(() => {
    return payments.map((p) => ({
      payerId: p.id_of_payer,
      payerType: p.tilled_type_of_payer as PayerInfoPair['payerType'],
    }));
  }, [payments]);

  const { data: paymentMethods, isLoading } =
    useHydratePaymentMethods(payerInfoPairs);
  const { setHasPaymentMethods } = usePrintableInvoiceContext();

  useEffect(() => {
    if (!payments.length) {
      setHasPaymentMethods(true);
      return;
    }
    setHasPaymentMethods(!isLoading);
  }, [isLoading, payments.length, setHasPaymentMethods]);

  const refundIds = useMemo(() => {
    return payments.flatMap((payment) => payment.refunds);
  }, [payments]);

  const hydratedPayments = useHydratePayments(payments, paymentMethods);

  const { data: refundData, isLoading: isLoadingRefunds } =
    useHydratePaymentRefunds(refundIds, hydratedPayments);

  return (
    <>
      <Well>
        <strong>Payments</strong>
        <PaymentsDataGrid payments={hydratedPayments} />

        {isLoadingRefunds ? (
          <LoadingIndicator />
        ) : refundIds.length ? (
          <>
            <strong>Refunds</strong>
            <RefundsDataGrid refunds={refundData} />
          </>
        ) : null}
      </Well>
      <div className="mt-4 flex flex-row items-center justify-end gap-8">
        <div>Total Due:</div>
        <div className="text-xl">{defaultCurrencyFormat(invoiceTotalDue)}</div>
      </div>
    </>
  );
}
