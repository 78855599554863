import { Image, Text, View } from '@react-pdf/renderer';
import { formatPhoneNumber } from 'react-phone-number-input';

import { PDFAddressBlock } from 'src/common/pdf/PDFAddressBlock';

import { commonStyles } from '../../../../common/pdf/common.styles';
import { currencyFormatter } from '../forms/cells/utils';
import { styles } from './styles';

interface InvoicePDFHeaderProps {
  logo?: string;
  organizationName: string;
  billingAddress: string;
  billingEmail: string;
  billingPhone: string;
  invoiceNumber: string;
  issueDate: string;
  amountDue: number;
}

export function InvoicePDFHeader({
  amountDue,
  billingAddress,
  billingEmail,
  billingPhone,
  invoiceNumber,
  issueDate,
  organizationName,
  logo,
}: InvoicePDFHeaderProps) {
  return (
    <View fixed style={{ marginTop: 10 }}>
      <View style={[commonStyles.between, styles.headerContainer]}>
        <View style={commonStyles.hstack}>
          {logo ? <Image src={logo} style={styles.organizationLogo} /> : null}
          <View style={[commonStyles.vstack, { gap: 4 }]}>
            <Text style={[commonStyles.strong, commonStyles.textSm]}>
              {organizationName}
            </Text>
            <View>
              {billingPhone ? (
                <Text style={commonStyles.textSm}>
                  {formatPhoneNumber(billingPhone)}
                </Text>
              ) : null}
              {billingEmail ? (
                <Text style={commonStyles.textSm}>{billingEmail}</Text>
              ) : null}
            </View>
            <View>
              <PDFAddressBlock address={billingAddress} />
            </View>
          </View>
        </View>
        <View style={{ gap: 2 }}>
          <Text style={[commonStyles.strong, commonStyles.textSm]}>
            Invoice
          </Text>
          <Text style={commonStyles.textSm}>{invoiceNumber}</Text>
          <Text style={[commonStyles.strong, commonStyles.textSm]}>
            Issue date
          </Text>
          <Text style={commonStyles.textSm}>{issueDate}</Text>
          <Text style={[commonStyles.strong, commonStyles.textSm]}>
            Amount due
          </Text>
          <Text style={commonStyles.textSm}>
            {currencyFormatter(amountDue)}
          </Text>
        </View>
      </View>
      <View style={commonStyles.separatorMd} />
    </View>
  );
}
