import { DateTime } from 'luxon';

import { InvoiceTripsRead } from 'src/common/external/bambi-api/bambiApi';

export function formatInvoiceDate(date: string): string {
  return `${DateTime.fromISO(date).toLocaleString(
    DateTime.DATE_MED
  )} at ${DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE)}`;
}

export function formatInvoicePDFDate(date: string): string {
  return `${DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)}`;
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format;

export function shouldDisplayTripSummaryPrice(
  summary: InvoiceTripsRead['price_summary'] | null,
  trip: InvoiceTripsRead
) {
  const dynamicTripPrice = summary?.total ?? 0;
  const tripPrice = trip.input_price_cents;
  const arePricesSame = dynamicTripPrice === tripPrice;

  // If prices are the same we can display the price summary
  return arePricesSame;
}
