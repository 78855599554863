import { useEffect, useMemo } from 'react';

import { Settings } from 'luxon';

import { RootState, useAppDispatch, useAppSelector } from 'src/app/store';
import {
  useOrganizationSettingsRetrieveQuery,
  useProfileRetrieveQuery,
  UserProfileRead,
} from 'src/common/external/bambi-api/bambiApi';

import { authSlice } from './auth.slice';
import { IAuthContext } from './AuthContext';
import { authService } from './authService';

function pluckOrganizationById(profile: UserProfileRead, id: string) {
  return profile.memberships.find((membership) => {
    return membership.organization.id === id;
  })?.organization;
}

export function useAuth(): IAuthContext {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.auth.profile);

  const currentOrganizationId = useAppSelector(
    (state: RootState) => state.auth.currentOrganizationId
  );

  const currentOrganization = useAppSelector((state: RootState) => {
    if (!state.auth.profile) return;

    if (state.auth.currentOrganizationId) {
      return pluckOrganizationById(
        state.auth.profile,
        state.auth.currentOrganizationId
      );
    }

    if (state.auth.profile.memberships.length) {
      return state.auth.profile.memberships[0].organization;
    }

    throw new Error('AuthProvider: Profile has no memberships!');
  });

  const currentMembership = useAppSelector((state: RootState) => {
    if (!state.auth.profile) return;

    if (state.auth.currentOrganizationId) {
      return state.auth.profile.memberships.find((m) => {
        return m.organization.id === state.auth.currentOrganizationId;
      });
    }
  });

  const sendbirdAppId = useAppSelector((state: RootState) => {
    if (!state.auth.profile) return;

    if (state.auth.currentOrganizationId) {
      const organization = pluckOrganizationById(
        state.auth.profile,
        state.auth.currentOrganizationId
      );

      return organization?.chat_application_id;
    }
  });

  const { data: profile, isLoading, error } = useProfileRetrieveQuery();
  const {
    data: organizationSettings,
    isLoading: isLoadingOrganizationSettings,
  } = useOrganizationSettingsRetrieveQuery(
    {},
    {
      skip: !currentOrganization?.id,
    }
  );

  useEffect(() => {
    if (profile) {
      dispatch(authSlice.actions.signIn(profile));
    }
  }, [profile, dispatch]);

  if (error) {
    authService.signOut();
  }

  Settings.defaultZone =
    organizationSettings?.headquarters_location?.time_zone_name ??
    Settings.defaultZone;

  const isBambiUser = useMemo(() => {
    return currentMembership?.role === 'bambi';
  }, [currentMembership]);

  const headquartersLocation = organizationSettings?.headquarters_location;

  return {
    user,
    signOut: authService.signOut,
    loading: isLoading || isLoadingOrganizationSettings,
    currentOrganizationSettings: organizationSettings,
    currentMembership: currentMembership,
    currentOrganizationName: currentOrganization?.name ?? '',
    currentOrganizationId: currentOrganizationId || '',
    currentOrganizationHeadQuarters: headquartersLocation,
    currentOrganizationLocation: headquartersLocation
      ? {
          latLong: {
            lat: headquartersLocation.latitude,
            long: headquartersLocation.longitude,
          },
        }
      : undefined,
    sendbirdAppId,
    isBambiUser,
  };
}
