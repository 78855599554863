import { View } from '@react-pdf/renderer';

import { FeeLabelPDF } from './components/FeeLabelPDF';
import { PriceSummaryData } from './types';

export function HolidayFeeDetailsPDF({
  data,
}: {
  data?: PriceSummaryData | null;
}) {
  return (
    <>
      <View />
      <FeeLabelPDF label="Holiday" value={data?.holiday} />
      <FeeLabelPDF label="Date" value={data?.date} />
    </>
  );
}
