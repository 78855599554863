import { useCallback, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import { DeleteAction } from 'src/common/DeleteAction';
import { DeleteModal } from 'src/common/DeleteModal';
import {
  DriverRead,
  useDriversCreateMutation,
  useDriversDestroyMutation,
  useDriversPartialUpdateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { useConfirmClose } from 'src/common/modals/RequiresConfirmationModal/RequiresConfirmationModal';
import { Modal } from 'src/common/primitives/Modal';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { WizardHeader } from 'src/common/wizard/WizardHeader';
import { useAuth } from 'src/features/auth/useAuth';

import { convertDriverProfileToDriver } from '../driver-profile/convertDriverProfileToDriver';
import { DriverProfile } from '../driver-profile/DriverProfile';
import { setShowDriverProfileModal } from '../team.slice';
import { assignDefaultFormValues } from './assignDefaultFormValues';
import { DriverProfileFormStep1 } from './DriverProfileFormStep1/DriverProfileFormStep1';
import { DriverProfileFormStep2 } from './DriverProfileFormStep2/DriverProfileFormStep2';
import { generateSuccessToast } from './generateAddSuccessToast';
import { generateDeleteSuccessToast } from './generateDeleteSuccessToast';
import { generateEditSuccessToast } from './generateEditSuccessToast';

function getStepTitle(step: number, driverProfile: DriverProfile | null) {
  switch (step) {
    case 2:
      return 'Add Skills';
    default:
      return driverProfile ? 'Edit Driver Profile' : 'Add Driver Profile';
  }
}

export function DriverProfileForm() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const open = useSelector(
    (state: RootState) => state.team.showDriverProfileModal
  );

  const driverProfile = useSelector(
    (state: RootState) => state.team.selectedDriverProfile
  );
  const nextToastId = useSelector((state: RootState) => state.toast.nextId);

  const [step, setStep] = useState(1);
  const [serverErrors, setServerErrors] = useState<string | null>(null);

  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const defaultFormValues = assignDefaultFormValues(driverProfile);
  const methods = useForm({
    defaultValues: defaultFormValues,
  });

  const [createDriver] = useDriversCreateMutation();
  const [updateDriver] = useDriversPartialUpdateMutation();
  const [deleteDriver] = useDriversDestroyMutation();

  const onSubmitStepTwo = async () => {
    setIsSubmitting(true);

    const patchedDriver: DriverRead = convertDriverProfileToDriver(
      methods.getValues(),
      auth.currentOrganizationId
    );

    setServerErrors(null);

    try {
      const driver = await (driverProfile?.id
        ? updateDriver({
            'Organization-ID': auth.currentOrganizationId || '',
            id: patchedDriver.id,
            patchedDriver,
          }).unwrap()
        : createDriver({
            'Organization-ID': auth.currentOrganizationId || '',
            driver: patchedDriver,
          }).unwrap());

      const showAction = driverProfile
        ? generateEditSuccessToast(nextToastId, driver.first_name || 'unknown')
        : generateSuccessToast(nextToastId);

      dispatch(show(showAction));
      dispatch(setShowDriverProfileModal(false));
      setStep(1);
    } catch (e) {
      const formattedErr = formatServerError(e);
      setServerErrors(formattedErr);
    } finally {
      setIsSubmitting(false);
    }
  };
  const title = getStepTitle(step, driverProfile);

  useEffect(() => {
    if (open) {
      methods.reset(defaultFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const setModalOpen = useCallback(
    (open: boolean) => {
      dispatch(setShowDriverProfileModal(open));
    },
    [dispatch]
  );

  const onModalClose = useCallback(() => {
    setStep(1);
    setServerErrors(null);
    methods.reset();
  }, [methods]);

  const shouldConfirmClose = useCallback(
    () => methods.formState.isDirty,
    [methods.formState.isDirty]
  );

  const [setOpen, onClose] = useConfirmClose({
    setOpen: setModalOpen,
    shouldConfirmClose,
    onClose: onModalClose,
  });

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        onClose={onClose}
        onInteractOutside={(e) => {
          onClose();
        }}
      >
        <div data-testid="driver-profile-form">
          <WizardHeader
            title={title}
            wizardState={{ step, totalSteps: 2 }}
            setStep={setStep}
          />
          <FormProvider {...methods}>
            <form>
              {step === 1 ? (
                <DriverProfileFormStep1
                  onNextClick={() => setStep(2)}
                  onCancel={() => setOpen(false)}
                >
                  {driverProfile ? (
                    <DeleteAction
                      onDelete={() => {
                        setIsConfirmingDelete(true);
                        dispatch(setShowDriverProfileModal(false));
                      }}
                      label="Delete this driver profile"
                    />
                  ) : null}
                </DriverProfileFormStep1>
              ) : null}
              {step === 2 ? (
                <DriverProfileFormStep2
                  onSubmit={onSubmitStepTwo}
                  onCancel={() => setOpen(false)}
                  serverErrors={serverErrors}
                  isSubmitting={isSubmitting}
                />
              ) : null}
            </form>
          </FormProvider>
        </div>
      </Modal>
      <DeleteModal
        open={isConfirmingDelete}
        setOpen={setIsConfirmingDelete}
        onCancel={() => {
          dispatch(setShowDriverProfileModal(false));
          setIsConfirmingDelete(false);
        }}
        onConfirm={async () => {
          try {
            await deleteDriver({
              'Organization-ID': auth.currentOrganizationId || '',
              id: driverProfile?.id || '',
            }).unwrap();
            dispatch(show(generateDeleteSuccessToast(nextToastId)));
            setIsConfirmingDelete(false);
          } catch (error) {
            console.error(error);
          }
        }}
        title="Delete this driver profile?"
        description="Are you sure you want to delete this driver profile? Future assignments cannot be restored."
      />
    </>
  );
}
