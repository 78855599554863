import { useCallback, useEffect, useMemo, useState } from 'react';

import { throttle } from 'lodash-es';
import { DateTime } from 'luxon';
import { Controller, useForm } from 'react-hook-form';

import { useAppDispatch } from 'src/app/store';
import { CurrencyInput } from 'src/common/CurrencyInput';
import {
  InvoicePayment,
  InvoicePaymentRead,
  InvoiceRead,
  usePayersListQuery,
  useTilledPaymentMethodCreateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { ManagePaymentMethods } from 'src/common/forms/PaymentMethodCaptureForm/ManagePaymentMethods';
import { usePaymentMethodCaptureContext } from 'src/common/forms/PaymentMethodCaptureForm/PaymentMethodCaptureContext';
import {
  BillingDetails,
  validateBillingAddress,
} from 'src/common/forms/PaymentMethodCaptureForm/PaymentMethodCaptureForm';
import { useCanCapturePaymentMethod } from 'src/common/forms/PaymentMethodCaptureForm/useCanCapturePaymentMethod';
import { useTilled } from 'src/common/forms/PaymentMethodCaptureForm/useTilled';
import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';
import { Select, SelectOption } from 'src/common/primitives/Select';
import { show } from 'src/common/primitives/Toast/toast.slice';
import { Well } from 'src/common/primitives/Well/Well';

import { formatBillingServerError } from '../../helpers/formatBillingServerError';
import { isSelfPay } from '../isSelfPay';
import { useHydratedInvoice } from '../useHydratedInvoice';

interface PaymentModalProps {
  open: boolean;
  invoice: InvoiceRead | null;
  loading?: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: (data: InvoicePayment) => void;
}
export function PaymentModal({
  open,
  invoice,
  setOpen,
  loading,
  onConfirm,
}: PaymentModalProps) {
  const { onlyInvoicingEnabled } = useCanCapturePaymentMethod();
  const { isLoading, data } = useHydratedInvoice(invoice);
  const { isLoading: isLoadingPayers, data: payers } = usePayersListQuery({});
  const dispatch = useAppDispatch();
  const payerOptions: SelectOption[] = useMemo(() => {
    return (
      payers?.results.reduce((acc, item) => {
        return [
          ...acc,
          {
            label: item.display_name,
            value: item.id,
          },
        ];
      }, [] as SelectOption[]) ?? []
    );
  }, [payers]);

  const selfPayer = useMemo(() => {
    return payerOptions.find((payer) => isSelfPay(payer.label));
  }, [payerOptions]);

  const {
    getValues,
    setValue,
    reset,
    resetField,
    control,
    setError,
    handleSubmit,
    formState,
  } = useForm<InvoicePaymentRead & { payer?: SelectOption }>({
    defaultValues: {
      amount_cents: invoice?.total_amount_due_cents,
      date: DateTime.now().toISODate(),
      external_payment_method_id: '',
      invoice: invoice?.id,
      tilled_type_of_payer: '',
      status: 'requires_capture',
      payer: selfPayer,
      is_manual: false,
    },
  });

  const passengerId = useMemo(() => {
    if (isLoading) {
      return '';
    }

    return data?.line_items.find(
      (lineItem) => lineItem.line_item_type === 'trip'
    )?.trip.passenger.id;
  }, [isLoading, data]);

  // Reset after invoice data is resolved
  useEffect(() => {
    if (isLoading || isLoadingPayers) {
      return;
    }

    reset({
      date: DateTime.now().toISODate(),
      external_payment_method_id: '',
      by_passenger: passengerId,
      by_payer: null,
      amount_cents: invoice?.total_amount_due_cents ?? 0,
      invoice: invoice?.id,
      payer: selfPayer,
    });
  }, [reset, isLoading, isLoadingPayers, invoice, passengerId, selfPayer]);

  // Transform selected payer into payer_id and type
  const handleSetPayer = useCallback(
    (newPayer?: SelectOption) => {
      let payerId: string;

      if (!newPayer) {
        setValue('payer', newPayer);
        return;
      }

      let key: keyof InvoicePayment = 'by_payer';
      if (newPayer === selfPayer) {
        payerId = passengerId ?? '';
        key = 'by_passenger';
        setValue('by_payer', null);
      } else {
        payerId = newPayer.value;
        key = 'by_payer';
        setValue('by_passenger', null);
      }

      setValue('payer', newPayer);
      setValue(key, payerId);
      resetField('external_payment_method_id');
    },
    [passengerId, selfPayer, setValue, resetField]
  );

  // Store billing details to save
  const [billingDetails, setBillingDetails] = useState<BillingDetails>();

  const { savePaymentMethod, validateForm: validatePaymentForm } =
    useTilled('card');
  const [savePaymentMethodCreate] = useTilledPaymentMethodCreateMutation();
  const { areFieldsEmpty } = usePaymentMethodCaptureContext();

  // Calculate the current payerId/type
  const payerId = getValues().by_passenger || getValues().by_payer;
  const payerType =
    getValues().payer?.value === selfPayer?.value
      ? 'passenger'
      : 'payer_organization';

  const handleSubmitPayment = useCallback(
    async (formData: InvoicePayment) => {
      const billingAddress = {
        city: billingDetails?.city,
        country: billingDetails?.country,
        zip: billingDetails?.zip,
        state: billingDetails?.state,
        street: billingDetails?.street,
        street2: billingDetails?.street2,
      };

      const validBillingAddress = validateBillingAddress(billingAddress);

      const validPaymentForm = await validatePaymentForm();
      const billingName = billingDetails?.billingName ?? '';

      if (!areFieldsEmpty() && !!billingDetails) {
        // Creating a new payment?
        // Attempt to save payment method if valid
        if (validPaymentForm && billingName) {
          try {
            // Persist payment method in tilled
            const savedPaymentMethod = await savePaymentMethod({
              type: 'card',
              billing_details: {
                name: billingName,
                address: validBillingAddress
                  ? {
                      ...validBillingAddress,
                      zip: validBillingAddress.zip,
                    }
                  : undefined,
              },
            });

            // Shouldn't happen in practice, either the payer or passenger will have an id
            if (!payerId) {
              dispatch(
                show({
                  title: 'Unable to save payment method',
                  description: 'Missing payer id',
                  type: 'error',
                })
              );
              return;
            }

            // Attach payment method to payer
            await savePaymentMethodCreate({
              tilledPaymentMethodInput: {
                payer_id: payerId,
                payer_type: payerType,
                payment_method_id: savedPaymentMethod.id,
              },
            }).unwrap();

            dispatch(
              show({
                title: 'Saved payment method',
                type: 'success',
              })
            );

            onConfirm({
              ...formData,
              external_payment_method_id: savedPaymentMethod.id,
            });
          } catch (e) {
            dispatch(
              show({
                title: 'Failed to save payment method',
                description: formatBillingServerError(e),
                type: 'error',
              })
            );
          }
        } else {
          dispatch(
            show({
              type: 'error',
              title: 'Unable to save payment method',
              description: 'Payment details are invalid, or missing',
            })
          );
        }
      } else if (getValues().amount_cents <= 0) {
        setError('amount_cents', {
          message: 'Amount should be greater than zero',
        });
      }
      // Validate a payment method was selected, or error
      else if (!getValues().external_payment_method_id) {
        setError('external_payment_method_id', {
          message: 'Select a payment method',
        });
      } else {
        // Create payment using existing payment method
        return onConfirm(formData);
      }
    },
    [
      billingDetails,
      validatePaymentForm,
      areFieldsEmpty,
      getValues,
      savePaymentMethod,
      payerId,
      savePaymentMethodCreate,
      payerType,
      dispatch,
      onConfirm,
      setError,
    ]
  );

  const throttledHandleSubmitPayment = useMemo(
    () => throttle(handleSubmitPayment, 100),
    [handleSubmitPayment]
  );

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      contentClassnames="w-full max-w-[45vw]"
    >
      <h3 className="text-[24px] font-medium leading-6 text-gray-900">
        Pay Invoice
      </h3>
      <div className="mt-4 flex flex-row items-center justify-start gap-8">
        <Controller
          control={control}
          name="payer"
          render={({ field }) => {
            return (
              <DefaultFormFieldLayout
                label="Payer *"
                inputProps={{ id: `payer` }}
              >
                <Select
                  options={payerOptions}
                  onChange={handleSetPayer}
                  value={field.value?.value}
                />
              </DefaultFormFieldLayout>
            );
          }}
        />
      </div>

      <Well>
        <div className="flex flex-row justify-between">
          <div className="text-xl">Amount</div>
          <div className="text-xl">
            <Controller
              control={control}
              name="amount_cents"
              render={({ field, fieldState }) => {
                return (
                  <div className="flex flex-col gap-2">
                    <CurrencyInput
                      value={field.value}
                      onValueChange={(e) => field.onChange(e.floatValue)}
                    />
                    {fieldState.error?.message && (
                      <strong className="text-sm text-error-700">
                        {fieldState.error.message}
                      </strong>
                    )}
                  </div>
                );
              }}
            />
          </div>
        </div>
      </Well>
      {onlyInvoicingEnabled ? null : (
        <Well>
          <div className="flex flex-row">
            <div className="text-xl">Credit Card</div>
          </div>
          <Controller
            name="external_payment_method_id"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <strong className="text-error-700">
                  {fieldState.error?.message}
                </strong>
                <div className="sm:col-span-5">
                  <ManagePaymentMethods
                    payerId={payerId ?? ''}
                    payerType={payerType}
                    billingName={billingDetails?.billingName ?? ''}
                    selectedPaymentMethodId={field.value}
                    onChangeBillingDetails={setBillingDetails}
                    onSelectPaymentMethod={field.onChange}
                  />
                </div>
              </>
            )}
          />
        </Well>
      )}

      <div className="mt-4 flex flex-row justify-between">
        <Button
          disabled={!formState.isValid}
          dataTestId="cancelBtn"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <div className="flex flex-row gap-2">
          <Button
            disabled={!formState.isValid}
            type="submit"
            variant={onlyInvoicingEnabled ? 'primary' : 'primary-outline'}
            onClick={(e) => {
              setValue('is_manual', true);
              setValue('external_payment_method_id', '-1');
              handleSubmit(throttledHandleSubmitPayment)(e);
            }}
            dataTestId="manual-payment-button"
            loading={loading}
          >
            Mark as Paid
          </Button>
          {onlyInvoicingEnabled ? null : (
            <Button
              disabled={!formState.isValid}
              type="submit"
              variant="primary"
              onClick={handleSubmit(throttledHandleSubmitPayment)}
              dataTestId="confirmBtn"
              loading={loading}
            >
              Confirm
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
