import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/app/store';

export const selectCheckedInvoiceRows = createSelector(
  (state: RootState) => state.invoice.selectedInvoiceRows,
  (invoiceRows) => {
    const ids = Object.keys(invoiceRows);
    return ids.filter((id) => !!invoiceRows[id]);
  }
);
