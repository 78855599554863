import { ContextFormFieldCheckbox } from 'src/common/FormField/v2/context/components';
import SecondsToMinutesField from 'src/common/SecondsToMinutesField';

import { SettingsRowHeader } from './components/SettingsRowHeader';
export default function BufferTimeSettings() {
  return (
    <>
      <div className="grid grid-cols-1 gap-x-4 gap-y-4 border-b border-gray-900/10 py-5 md:grid-cols-4">
        <SettingsRowHeader
          title="'Run Bambi Run' Settings"
          description="Organization defaults for 'Run Bambi Run'."
        />

        <div className="col-span-3 flex flex-col">
          <div className="grid grid-cols-1 gap-x-4 py-2 md:grid-cols-4">
            <div>
              <SecondsToMinutesField
                fieldPath={`pickup_appointment_early_seconds`}
                label="Appointment Pickup Early"
                tooltipHelper={{
                  text:
                    'If there is an Appointment Time listed on a trip, ' +
                    'and its Appointment Time is 9:00am, then putting "30" in this field ' +
                    'allows Run Bambi Run to assign the trip such that the actual ' +
                    'appointment time will occur between 8:30am and 9:00am.',
                }}
                inlineHelperText="This applies to trips that DO have any Appointment Time listed"
              />
            </div>

            <div>
              <SecondsToMinutesField
                fieldPath={`pickup_appointment_late_seconds`}
                label="Appointment Pickup Late"
                tooltipHelper={{
                  text:
                    'If there is an Appointment Time listed on a trip, ' +
                    'and its Appointment Time is 9:00am, then putting "30" in this field ' +
                    'allows Run Bambi Run to assign the trip such that the actual ' +
                    'appointment time will occur between 9:00am and 9:30am.',
                }}
                inlineHelperText="This applies to trips that DO have any Appointment Time listed"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-4 py-2 md:grid-cols-4">
            <div>
              <SecondsToMinutesField
                fieldPath={`pickup_default_early_seconds`}
                label="Pickup Early"
                tooltipHelper={{
                  text:
                    'If there is no Appointment Time listed on a trip, ' +
                    'and its Pickup Time is 9:00am, then putting "30" in this field ' +
                    'allows Run Bambi Run to assign the trip such that the actual ' +
                    'pickup time will occur between 8:30am and 9:00am.',
                }}
                inlineHelperText="This applies to trips that DO NOT have any Appointment Time listed"
              />
            </div>

            <div>
              <SecondsToMinutesField
                fieldPath={`pickup_default_late_seconds`}
                label="Pickup Late"
                tooltipHelper={{
                  text:
                    'If there is no Appointment Time listed on a trip, ' +
                    'and its Pickup Time is 9:00am, then putting "30" in this field ' +
                    'allows Run Bambi Run to assign the trip such that the actual ' +
                    'pickup time will occur between 9:00am and 9:30am.',
                }}
                inlineHelperText="This applies to trips that DO NOT have any Appointment Time listed"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-4 py-2 md:grid-cols-4">
            <div></div>
            <div>
              <SecondsToMinutesField
                fieldPath={`pickup_will_call_late_seconds`}
                label="Will Call Pickup Late"
                inlineHelperText="This applies to will-call (purple) trips"
                tooltipHelper={{
                  text:
                    'If the trip is a will-call trip (purple), ' +
                    'and its Pickup Time is 9:00am, then putting "30" in this field ' +
                    'allows Run Bambi Run to assign the trip such that the actual ' +
                    'pickup time will occur between 9:00am and 9:30am.',
                }}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-4 py-2 md:grid-cols-4">
            <ContextFormFieldCheckbox
              label="Maintain same driver for passenger"
              fieldPath="assign_same_driver_for_passenger"
              helperText={{
                text: 'Whether to assign the same driver for a passenger during run bambi run if they have multiple trips',
                presentation: {
                  type: 'tooltip',
                },
              }}
            />
          </div>
          <div className="grid grid-cols-1 gap-x-4 py-2 md:grid-cols-4"></div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-x-4 gap-y-4 border-b border-gray-900/10 py-5 md:grid-cols-4">
        <SettingsRowHeader
          title="Appointment Early Arrival Buffer"
          description="Organization early buffer time for appointments in minutes."
        />
        <div className="col-span-3 flex flex-col">
          <div className="grid grid-cols-1 gap-x-4 py-2 md:grid-cols-4">
            <div>
              <SecondsToMinutesField
                fieldPath={`appointment_early_arrival_window_seconds`}
                label="Appointment Early Arrival Buffer"
                tooltipHelper={{
                  text: `Bambi automatically generates a Pickup Time if you input an Appointment Time.
                  For example, if you put an appointment time of 9:00am, Bambi may generate a Pickup Time of 8:30am,
                  calculating that it'll take 30 minutes from the pickup location to the appointment.
                  If you input '10 minutes' in this field, then Bambi would instead generate a Pickup Time of 8:20am,
                  creating a 10-minute buffer so that the passenger more likely arrives before the Appointment Time.`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
