import {
  ClockIcon,
  ReceiptRefundIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { InfoCircledIcon } from '@radix-ui/react-icons';

import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import { InvoicePaymentRead } from 'src/common/external/bambi-api/bambiApi';
import { SimpleTooltip } from 'src/common/primitives/Tooltip';

import { useHydratePaymentRefunds } from './useHydratePaymentRefunds';
import { useRefundTooltipText } from './useRefundTooltipText';

interface PaymentAmountCellProps {
  amountCents: number;
  refunds?: string[];
  chargeStatus: InvoicePaymentRead['charge_status'];
  paymentFailureMessage?: string;
  isManual?: boolean;
  payment: InvoicePaymentRead;
}

export function PaymentAmountCell({
  amountCents,
  refunds,
  chargeStatus,
  paymentFailureMessage,
  isManual,
  payment,
}: PaymentAmountCellProps) {
  const hasRefund = !!refunds?.length;
  const paymentPending = chargeStatus === 'pending';
  const paymentFailed = chargeStatus === 'failed';
  const failureMessage = paymentFailureMessage
    ? paymentFailureMessage
    : 'This payment was not successful';

  const { data: refundData } = useHydratePaymentRefunds(refunds);

  const refundToolTipText = useRefundTooltipText(payment, refundData);

  return (
    <div className="flex flex-row items-center gap-2">
      {defaultCurrencyFormat(amountCents)}
      {hasRefund ? (
        <SimpleTooltip
          trigger={
            <ReceiptRefundIcon className="w-4" data-testid="payment-refunded" />
          }
          content={refundToolTipText}
        />
      ) : null}
      {paymentFailed ? (
        <SimpleTooltip
          trigger={
            <XCircleIcon
              className="w-4 stroke-error-700"
              data-testid="payment-failed"
            />
          }
          content={failureMessage}
        />
      ) : null}
      {!hasRefund && paymentPending ? (
        <SimpleTooltip
          trigger={
            <ClockIcon className="w-4" data-testid="payment-processing" />
          }
          content="This payment is processing"
        />
      ) : null}
      {isManual ? (
        <SimpleTooltip
          trigger={
            <InfoCircledIcon className="w-4" data-testid="payment-manual" />
          }
          content="This was manually marked as paid"
        />
      ) : null}
    </div>
  );
}
