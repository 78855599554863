import { createColumnHelper } from '@tanstack/react-table';
import { upperFirst } from 'lodash-es';
import { formatPhoneNumber } from 'react-phone-number-input';

import {
  PayerOrganizationRead,
  PayerTypeEnum,
} from 'src/common/external/bambi-api/bambiApi';
import { Badge } from 'src/common/primitives/Badge';

import { IsActiveColumnFilter } from './filters/IsActiveColumnFilter';
import { PayerSettingsPopover } from './PayerSettingsPopover';
import { RowActions } from './RowActions';

const columnHelper = createColumnHelper<PayerOrganizationRead>();

export const manageColumn = columnHelper.display({
  id: 'actions',
  header: 'Manage',
  enableHiding: false,
  cell: (props) => <RowActions payer={props.row.original} />,
});

export const columns = [
  columnHelper.accessor('display_name', {
    enableSorting: true,
    id: 'display_name',
    header: 'Name',
    cell: (props) => <PayerSettingsPopover payer={props.row.original} />,
  }),
  columnHelper.accessor('phone_number', {
    enableSorting: true,
    id: 'phone_number',
    header: 'Phone',
    cell: ({ getValue }) => {
      const value = getValue();
      return value ? (
        <a href={`tel:${value}`}>{formatPhoneNumber(value)}</a>
      ) : (
        ''
      );
    },
  }),
  columnHelper.accessor('billing_phone_number', {
    enableSorting: true,
    id: 'billing_phone_number',
    header: 'Billing Phone',
    cell: ({ getValue }) => {
      const value = getValue();
      return value ? (
        <a href={`tel:${value}`}>{formatPhoneNumber(value)}</a>
      ) : (
        ''
      );
    },
  }),
  columnHelper.accessor('email', {
    enableSorting: true,
    id: 'email',
    header: 'Email',
    cell: ({ getValue }) => {
      const value = getValue();
      return value ? <a href={`mailto:${value}`}>{value}</a> : '';
    },
  }),
  columnHelper.accessor('billing_email', {
    enableSorting: true,
    id: 'billing_email',
    header: 'Billing Email',
    cell: ({ getValue }) => {
      const value = getValue();
      return value ? <a href={`mailto:${value}`}>{value}</a> : '';
    },
  }),
  columnHelper.accessor('website', {
    enableSorting: true,
    id: 'website',
    header: 'Website',
    cell: ({ getValue }) => {
      const value = getValue();
      return value ? (
        <a target="_blank" href={value} rel="noreferrer">
          {value}
        </a>
      ) : (
        ''
      );
    },
  }),
  columnHelper.accessor('payer_type', {
    header: 'Type',
    cell: (props) => payerBadge(props.row.original.payer_type),
  }),
  columnHelper.accessor('is_active', {
    header: 'Active',
    cell: ({ getValue }) => (
      <Badge
        label={getValue() ? 'Active' : 'Inactive'}
        color={getValue() ? 'green' : 'red'}
      />
    ),
    meta: {
      filterComponent(column, open, onClose) {
        return <IsActiveColumnFilter open={open} onClose={onClose} />;
      },
    },
  }),
];

function payerBadge(type: PayerTypeEnum) {
  const colorByType = {
    insurance: 'blue',
    broker: 'orange',
    private: 'primary',
  };

  const color = colorByType[type];

  return <Badge label={upperFirst(type)} color={color} />;
}
