import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import Switch from 'src/common/primitives/Switch';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useDebouncedSearch } from 'src/common/useDebouncedSearch';

import { useUserRoles } from '../auth/useUserRoles';
import {
  setDriverProfileSearchTerm,
  setSelectedDriverProfile,
  setShowDriverProfileModal,
  setShowDeletedDrivers,
} from './team.slice';

export function DriverProfileRouteActions() {
  const dispatch = useAppDispatch();

  const { isFacilityUser, isRestrictedSupport } = useUserRoles();
  const { showDeletedDrivers, driverProfileSearchTerm } = useAppSelector(
    (state) => state.team
  );

  const { inputValue, handleInputChange } = useDebouncedSearch(
    setDriverProfileSearchTerm,
    driverProfileSearchTerm
  );

  function handleAddDriverProfile() {
    dispatch(setSelectedDriverProfile(null));
    dispatch(setShowDriverProfileModal(true));
  }

  return (
    <>
      <div className="flex text-sm" style={{ height: '80%' }}>
        {!isFacilityUser && !isRestrictedSupport && (
          <label className="flex items-center gap-2">
            <Switch
              label="Show deleted drivers"
              inputProps={{
                value: showDeletedDrivers,
                onChange: (nextValue: boolean) => {
                  dispatch(setShowDeletedDrivers(nextValue));
                },
              }}
            />
            Include deleted
          </label>
        )}
      </div>
      <SearchTypeahead
        onChange={handleInputChange}
        placeholder="Search drivers"
        value={inputValue}
      />
      {!isRestrictedSupport && (
        <Button onClick={() => handleAddDriverProfile()} variant="primary">
          Add a Driver Profile
        </Button>
      )}
    </>
  );
}
