import { useCallback } from 'react';

import { FingerPrintIcon } from '@heroicons/react/24/outline';
import { Route, Routes } from 'react-router';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import { addATrip } from '../add-trip/addATrip.slice';
import { useAuth } from '../auth/useAuth';
import { PassengerMergeModal } from './management/merge/PassengerMergeModal';
import { PassengerSearchForm } from './management/PassengerSearchForm';
import { setPassengerMergeModalOpen } from './passenger.slice';
import { useIsPassengerMergeEnabledForOrganization } from './useIsPassengerMergeEnabledForOrganization';

export function PassengerRouteActions() {
  return (
    <Routes>
      {/* TODO: element is placeholder here to silence warning. Eventually there will be an add passenger button */}
      <Route index element={<PassengerIndexRouteActions />} />
    </Routes>
  );
}

export function PassengerIndexRouteActions() {
  const passengerMergeModalOpen = useAppSelector(
    (state) => state.passenger.passengerMergeModalOpen
  );
  const dispatch = useAppDispatch();

  const isPassengerMergeEnabledForOrganization =
    useIsPassengerMergeEnabledForOrganization();

  const { isBambiUser } = useAuth();
  const { isAdmin, isDispatcher, isOwner } = useUserRoles();

  const canMerge =
    (isPassengerMergeEnabledForOrganization && (isAdmin || isDispatcher || isOwner)) ||
    isBambiUser;

  const handleCreateNewPassenger = useCallback(() => {
    dispatch(addATrip.actions.selectNoPassenger());
    dispatch(addATrip.actions.selectPassenger({}));
    dispatch(addATrip.actions.setIsEditingPassenger(true));
  }, [dispatch]);

  return (
    <div className="flex flex-row gap-2">
      {canMerge ? (
        <Button
          variant="primary-outline"
          onClick={() => dispatch(setPassengerMergeModalOpen(true))}
        >
          <FingerPrintIcon className="mr-1 w-5" />
          Merge Passengers
        </Button>
      ) : null}
      <PassengerSearchForm />
      <Button variant="primary" onClick={handleCreateNewPassenger}>
        Create a Passenger
      </Button>
      <PassengerMergeModal
        open={passengerMergeModalOpen}
        setOpen={(open) => dispatch(setPassengerMergeModalOpen(open))}
      />
    </div>
  );
}
