import { Text, View } from '@react-pdf/renderer';

import { commonStyles } from 'src/common/pdf/common.styles';

export function FeeLabelPDF({
  label,
  value,
}: {
  label: string;
  value?: string | number | null;
}) {
  return (
    <View style={[commonStyles.textSm]}>
      <Text style={{ textDecoration: 'underline' }}>{label}</Text>
      <Text>{value || '--'}</Text>
    </View>
  );
}
