import { useAppDispatch } from 'src/app/store';
import { DataGrid } from 'src/common/DataGrid';

import { setHighlightPaymentId } from '../invoice.slice';
import { columns, InvoicePaymentWithPaymentMethod } from './paymentColumns';

interface PaymentsDataGridProps {
  payments?: InvoicePaymentWithPaymentMethod[];
}

export function PaymentsDataGrid({ payments = [] }: PaymentsDataGridProps) {
  const dispatch = useAppDispatch();

  return (
    <DataGrid
      tableId="payments"
      columns={columns}
      data={payments}
      containerClassName="print:max-w-[100vw]"
      cellClassName="align-top"
      includePagination={false}
      onOverRow={(d) => dispatch(setHighlightPaymentId(d.original.id))}
      onOutRow={() => dispatch(setHighlightPaymentId(undefined))}
    />
  );
}
