import { useCallback, useEffect, useRef, useState } from 'react';

import { parse } from 'csv-parse/browser/esm/sync';
import { DateTime } from 'luxon';
import { SubmitHandler } from 'react-hook-form';
import { useReactToPrint } from 'react-to-print';

import { useLazyGetTripsExportAsCsvQuery } from 'src/common/external/bambi-api/emptyApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { Select, SelectOption } from 'src/common/primitives/Select';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { DetailsFormFields } from '../DetailsFormFields';
import { DetailsForm, useDetailsForm } from '../useDetailsForm';
import {
  CondensedReportRecord,
  PrintableCondensedReport,
} from './PrintableCondensedReport';

const reportTypeOptions: SelectOption[] = [
  {
    label: 'All details report',
    value: 'all-details-report',
  },
  {
    label: 'Condensed report',
    value: 'condensed-report',
  },
];

interface AllDetailsExportProps {
  onClose: () => void;
}

export function AllDetailsExport({ onClose }: AllDetailsExportProps) {
  const [selectedReport, setSelectedReport] = useState<string | undefined>(
    reportTypeOptions[0].value
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const { handleSubmit, control } = useDetailsForm();
  const [trigger] = useLazyGetTripsExportAsCsvQuery();

  const [csvData, setCsvData] = useState<Array<CondensedReportRecord>>([]);
  const [printAsPdf, setPrintAsPdf] = useState(false);
  const [printTitle, setPrintTitle] = useState<string>(
    'condensed-report-export'
  );

  const handleFetchData = useCallback(
    async (fields: DetailsForm, format: 'csv' | 'pdf') => {
      const isMiniDownload = selectedReport === 'condensed-report';
      const {
        startDate,
        endDate,
        selectedPayers,
        selectedPassengers,
        externalTripIdOptions,
      } = fields;

      const params = {
        rangeStart: startDate
          ? DateTime.fromISO(startDate).startOf('day').toISO()
          : '',
        rangeEnd: endDate ? DateTime.fromISO(endDate).endOf('day').toISO() : '',
        outputSet: isMiniDownload ? 'mini' : '',
        format,
        // Only fetch completed trips for the pdf export
        status: isMiniDownload && format === 'pdf' ? 'completed' : '',
        payers: selectedPayers?.map((payer) => payer.value).join(',') ?? '',
        passengers:
          selectedPassengers?.map((passenger) => passenger.value).join(',') ??
          '',
        externalTripIds:
          externalTripIdOptions?.map((option) => option.value).join(',') ?? '',
        tripIds: '',
      };

      // Trigger will cause a blob download, see emptyApi
      return await trigger(params).unwrap();
    },
    [selectedReport, trigger]
  );

  const handleRenderAsPdf = useCallback((data: string, fileName: string) => {
    const records: Array<CondensedReportRecord> = parse(data, {
      columns: true,
    });

    setCsvData(records);
    setLoading(false);
    setPrintAsPdf(true);
    setPrintTitle(fileName);
  }, []);

  const handleDownloadAsPdf = useCallback<SubmitHandler<DetailsForm>>(
    async (fields) => {
      try {
        setError(undefined);
        setLoading(true);
        const result = await handleFetchData(fields, 'pdf');

        handleRenderAsPdf(
          (result?.records ?? '') as string,
          result?.fileName ?? 'condensed-report-export'
        );
      } catch (e) {
        const errMsg = `There was an error downloading the data. ${formatServerError(
          e
        )}`;
        setError(errMsg);
        setLoading(false);
      }
    },
    [handleFetchData, handleRenderAsPdf]
  );

  const handleDownloadTrips = useCallback<SubmitHandler<DetailsForm>>(
    async (fields) => {
      try {
        setError(undefined);
        setLoading(true);

        // Trigger will cause a blob download, see emptyApi
        await handleFetchData(fields, 'csv');
        onClose();
      } catch (e) {
        const errMsg = `There was an error downloading the data. ${formatServerError(
          e
        )}`;
        setError(errMsg);
        setLoading(false);
      }
    },
    [handleFetchData, onClose]
  );

  const printRef = useRef<HTMLDivElement>(null);
  const triggerPrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: printTitle,
    onAfterPrint: onClose,
  });

  useEffect(() => {
    if (printAsPdf) {
      triggerPrint();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printAsPdf]);

  if (printAsPdf) {
    return (
      <div>
        <PrintableCondensedReport data={csvData} ref={printRef} />
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div>
      <h1 className="text-2xl">All details download</h1>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      <div className="mb-2">
        <div>Select the report type</div>
        <Select
          options={reportTypeOptions}
          onChange={(val) => {
            setSelectedReport(val?.value);
          }}
          value={selectedReport}
        />
      </div>
      <DetailsFormFields
        control={control}
        includeDateRange
        includePayers
        includePassengers
        includeExternalTripIds
      />
      {selectedReport !== 'condensed-report' && (
        <span className="text-sm text-gray-500">
          The condensed report has been moved to a report type.
        </span>
      )}
      <div className="flex flex-row justify-between gap-4 pt-6">
        <div>
          <Button disabled={loading} onClick={onClose}>
            Cancel
          </Button>
        </div>
        <div className="flex flex-row gap-2">
          {selectedReport === 'condensed-report' && (
            <Button
              dataTestId="download-pdf"
              disabled={loading}
              loading={loading}
              variant="secondary"
              onClick={handleSubmit(handleDownloadAsPdf)}
            >
              {loading ? 'Exporting' : 'Print to PDF'}
            </Button>
          )}
          <Button
            dataTestId="download-csv"
            disabled={loading}
            loading={loading}
            variant="primary"
            onClick={handleSubmit(handleDownloadTrips)}
          >
            {loading ? 'Exporting' : 'Export as CSV'}
          </Button>
        </div>
      </div>
    </div>
  );
}
