import { StyleSheet } from '@react-pdf/renderer';

// Create styles
export const styles = StyleSheet.create({
  // Header
  headerContainer: {
    flexDirection: 'row',
  },
  organizationLogo: {
    width: 100,
    height: 66,
  },
  // Table
  // Footer
});
