import { PriceSummaryData } from './types';

export function StairFeeDetails({ data }: { data?: PriceSummaryData | null }) {
  return (
    <>
      <td className="px-2 py-1 text-center text-xs">
        <div className="underline">Fee Type</div>
        <div>{data?.fee_type || '--'}</div>
      </td>
      <td className="px-2 py-1 text-center text-xs">
        <div className="underline">Minimum Steps</div>
        <div>{data?.minimum_steps ?? '--'}</div>
      </td>
      <td className="px-2 py-1 text-center text-xs">
        <div className="underline">Steps</div>
        <div>{data?.steps || '--'}</div>
      </td>
    </>
  );
}
