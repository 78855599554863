import { useMemo } from 'react';

import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DateControls } from 'src/common/date/DateControls';
import { SelectedDateDisplay } from 'src/common/date/SelectedDateDisplay';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { SimpleTooltip } from 'src/common/primitives/Tooltip';

import { useDispatchRepository } from '../common/useDispatchRepository';
import { dispatcherSlice } from '../dispatcher.slice';
import DispatchCalendarAssignmentFilter from './DispatchCalendarAssignmentFilter';
import { DispatchCalendarTimeColumn } from './DispatchCalendarTimeColumn/DispatchCalendarTimeColumn';
import { EstimatedRevenueBadge } from './EstimatedRevenueBadge';
import TripStatusAggregation from './TripStatusAggregation';

export function DispatchCalendarHeader() {
  const dispatch = useAppDispatch();
  const selectedDate = useAppSelector((state) => state.dispatcher.selectedDate);

  const { isFetching: dispatchFetching, data: dispatchData } =
    useDispatchRepository();

  const isMenuActionInProgress = useAppSelector(
    (state) => state.dispatcher.isMenuActionInProgress
  );

  const totalRevenue = useMemo(
    () =>
      dispatchData?.assignments.reduce(
        (acc, assignment) => acc + assignment.estimated_revenue,
        0
      ) ?? 0,
    [dispatchData?.assignments]
  );

  const navigate = useNavigate();

  return (
    <div className="w-full pb-3 pt-3 pr-3">
      <div className="grid w-full items-center sm:grid-cols-12">
        <div className="flex items-center sm:col-span-4">
          <DispatchCalendarTimeColumn />
          <SelectedDateDisplay date={DateTime.fromISO(selectedDate)} />
          {(dispatchFetching || isMenuActionInProgress) && (
            <div className="ml-4">
              <LoadingIndicator />
            </div>
          )}
        </div>
        <div className="flex justify-center sm:col-span-3">
          <DateControls
            current={DateTime.fromISO(selectedDate)}
            onPrev={() => {
              dispatch(dispatcherSlice.actions.setSelectedDateToPreviousDay());

              const previousDayISO = DateTime.fromISO(selectedDate)
                .minus({ day: 1 })
                .toISODate();
              navigate(`/dispatch/${previousDayISO}`);
            }}
            onNext={() => {
              dispatch(dispatcherSlice.actions.setSelectedDateToNextDay());
              const nextDayISO = DateTime.fromISO(selectedDate)
                .plus({ day: 1 })
                .toISODate();
              navigate(`/dispatch/${nextDayISO}`);
            }}
            onToday={() => {
              dispatch(dispatcherSlice.actions.setSelectedDateToToday());
              const todayISO = DateTime.local().toISODate();
              navigate(`/dispatch/${todayISO}`);
            }}
            onDatePicked={(date) => {
              dispatch(
                dispatcherSlice.actions.setSelectedDate(date?.toISODate())
              );
              navigate(`/dispatch/${date?.toISODate()}`);
            }}
            prevTooltip="Previous Day"
            nextTooltip="Next Day"
          />
          <div className="mx-2"></div>
        </div>
        <div className="flex items-center justify-end gap-x-1 sm:col-span-5">
          { dispatchData?.show_pricing ? (
            <div className="mr-2 flex items-center">
              <SimpleTooltip
                trigger={
                  <div>
                    <EstimatedRevenueBadge revenue={totalRevenue} />
                  </div>
                }
                content="Total estimated revenue for the day's assignments"
              />
            </div>
          ) : null }
          <div className="mr-2">
            <DispatchCalendarAssignmentFilter />
          </div>

          <TripStatusAggregation />
        </div>
      </div>
    </div>
  );
}
