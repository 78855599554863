import React, { InputHTMLAttributes } from 'react';

import { searchForLocationSuggestions } from 'src/common/external/aws/searchForLocationSuggestions';
import { Combobox } from 'src/common/primitives/ComboBox';

import { useAuth } from '../../features/auth/useAuth';
import { LatLong } from '../external/aws/LatLong';
import { SelectOption } from '../primitives/Select';
import { isSuggestionStreetAddress } from './isSuggestionStreetAddress';

export type LocationTypeaheadProps = {
  onChange?: (value: SelectOption) => void;
  value?: any;
  placeholder?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  error?: string;
  disabled?: boolean;
  additionalResultsQueryFn?: (
    query: string,
    options: SelectOption[]
  ) => Promise<SelectOption[]>;
  renderOption?: (
    option: SelectOption,
    selected: boolean,
    active: boolean
  ) => React.ReactNode;
};

export const LocationTypeahead = React.forwardRef(function _LocationTypeahead(
  {
    onChange,
    value,
    placeholder = 'Start typing and select an address',
    inputProps,
    error,
    disabled,
    additionalResultsQueryFn = (query, options) => Promise.resolve(options),
    renderOption,
  }: LocationTypeaheadProps,
  forwardedRef: React.Ref<HTMLInputElement>
) {
  const auth = useAuth();
  const bias: LatLong | undefined = auth.currentOrganizationLocation?.latLong;
  const filterCountries = ['USA', 'PRI', 'BMU'];

  return (
    <Combobox
      disabled={disabled}
      dataTestId={inputProps?.id}
      keepValue
      onChange={onChange}
      options={value ? [{ value: value.value, label: value.label }] : []}
      value={value}
      placeholder={placeholder}
      ref={forwardedRef}
      queryFn={async (query) => {
        let mappedSuggestions: SelectOption[] = [];
        if (query) {
          const suggestions = await Promise.resolve(
            searchForLocationSuggestions(query, bias, filterCountries)
          );

          mappedSuggestions = suggestions
            .filter((suggestion) => {
              return (
                isSuggestionStreetAddress(suggestion.text) &&
                // We don't want to show any suggestions that don't have a placeId
                // A placeId is required by our system
                typeof suggestion.placeId === 'string'
              );
            })
            .map((suggestion) => {
              return {
                // TODO: Find a better way to narrow this type closer to the comment above
                value: suggestion.placeId as string,
                label: suggestion.text,
              };
            });
        }

        return additionalResultsQueryFn(query, mappedSuggestions);
      }}
      renderOption={renderOption}
      error={error}
    />
  );
});
