import { useCallback, useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import {
  DriverWrite,
  useDriversUndeleteCreateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { Modal } from 'src/common/primitives/Modal';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { setShowRestoreDriverProfileModal } from '../../team.slice';
import { assignDefaultFormValues } from '../assignDefaultFormValues';
import { DriverProfileFormStep1 } from '../DriverProfileFormStep1/DriverProfileFormStep1';

export function RestoreDriverProfileModal() {
  const dispatch = useAppDispatch();
  const driverProfile = useAppSelector(
    (state) => state.team.selectedDriverProfile
  );

  const open = useAppSelector(
    (state) => state.team.showRestoreDriverProfileModal
  );
  const handleClose = useCallback(() => {
    dispatch(setShowRestoreDriverProfileModal(false));
  }, [dispatch]);

  const defaultFormValues = assignDefaultFormValues(driverProfile);
  const form = useForm({
    defaultValues: defaultFormValues,
  });

  const [triggerRestore] = useDriversUndeleteCreateMutation({});
  const handleRestore = useCallback(async () => {
    if (!driverProfile) {
      dispatch(
        show({
          type: 'error',
          title: 'Unable to restore driver profile',
          description: 'Something unexpected happened, driver profile missing',
        })
      );
      return;
    }

    try {
      await triggerRestore({
        id: driverProfile.id,
        driver: driverProfile as unknown as DriverWrite,
      }).unwrap();
      dispatch(
        show({
          type: 'success',
          title: 'Profile restored',
        })
      );
      handleClose();
    } catch (e) {
      dispatch(
        show({
          type: 'error',
          title: 'Unable to restore driver profile',
          description: formatServerError(e),
        })
      );
    }
  }, [dispatch, driverProfile, handleClose, triggerRestore]);

  useEffect(() => {
    if (open) {
      form.reset(defaultFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      open={open}
      setOpen={(open) => {
        if (!open) {
          handleClose();
        }
      }}
    >
      <div data-testid="driver-profile-retore-form">
        <h2 className="text-xl font-medium leading-6 text-gray-900">
          Restore driver profile
        </h2>
        <FormProvider {...form}>
          <DriverProfileFormStep1
            nextButtonLabel="Restore Profile"
            validate={false}
            onNextClick={handleRestore}
            onCancel={handleClose}
          />
        </FormProvider>
      </div>
    </Modal>
  );
}
