import { useCallback, useEffect, useState } from 'react';

import { get } from 'lodash-es';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { useMembersUpdateEmailCreateMutation } from 'src/common/external/bambi-api/bambiApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { SimpleContextFormField } from 'src/common/FormField/SimpleContextFormField';
import { Button } from 'src/common/primitives/Button';
import { Modal, ModalProps } from 'src/common/primitives/Modal';
import { ModalTitle } from 'src/common/primitives/Modal/ModalTitle';
import { show } from 'src/common/primitives/Toast/toast.slice';
import { genericErrorMessage } from 'src/common/util/serverErrorFormatter';

import { setSelectedMember } from '../member.slice';

type EmailForm = {
  currentEmail: string;
  newEmail: string;
};

type EmailResponseError = {
  status: number;
  data: {
    email: string[] | { message: string; existing_membership_id?: string };
  };
};

interface EditMemberEmailModalProps {
  open: ModalProps['open'];
  setOpen: ModalProps['setOpen'];
}

export function EditMemberEmailModal({
  open,
  setOpen,
}: EditMemberEmailModalProps) {
  const member = useAppSelector((state) => state.member.selectedMember);
  const dispatch = useAppDispatch();
  const [updateError, setUpdateError] = useState<EmailResponseError | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);

  const form = useForm<EmailForm>({
    defaultValues: {
      currentEmail: member?.email,
      newEmail: member?.email,
    },
  });

  useEffect(() => {
    if (!member) {
      return;
    }

    form.setValue('currentEmail', member.email);
    form.setValue('newEmail', member.email);
  }, [form, member]);

  const [updateEmail] = useMembersUpdateEmailCreateMutation();

  const handleSubmit = useCallback<SubmitHandler<EmailForm>>(
    async (fields) => {
      if (!member) {
        return;
      }

      setUpdateError(null);
      setLoading(true);

      try {
        const updatedMember = await updateEmail({
          id: member.id,
          updateEmail: {
            email: fields.newEmail,
          },
        }).unwrap();

        dispatch(setSelectedMember(updatedMember));

        dispatch(
          show({
            type: 'success',
            title: 'Successfully updated email',
          })
        );
        setOpen(false);
      } catch (e) {
        setUpdateError(e as EmailResponseError);
        dispatch(
          show({
            type: 'error',
            title: 'Unable to update email',
            description:
              'An error occured while trying to update the email address',
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [dispatch, member, setOpen, updateEmail]
  );

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      contentClassnames="max-w-lg flex flex-col gap-3"
    >
      <div>
        <ModalTitle>Update Member Email</ModalTitle>
        <p className="mt-2 text-xs text-gray-500">*Required</p>
      </div>
      {updateError ? (
        <div>
          <FormErrorMessage>{formatEmailError(updateError)}</FormErrorMessage>
        </div>
      ) : null}
      <p className="text-sm">
        This will not automatically log out your team member, but they will be
        required to change their password the next time they log in. They will
        be notified at their new email.
      </p>
      <FormProvider {...form}>
        <div className="flex flex-col gap-2">
          <SimpleContextFormField
            fieldPath="currentEmail"
            type="text"
            label="Current email address"
            disabled
          />
          <SimpleContextFormField
            fieldPath="newEmail"
            type="email"
            label="New email address"
            placeholder="user@domain.com"
            rules={{
              required: 'This field is required',
            }}
          />
        </div>
      </FormProvider>
      <div className="flex flex-row justify-items-stretch gap-3">
        <Button
          variant="secondary"
          className="w-full"
          loading={loading}
          disabled={loading}
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="w-full"
          loading={loading}
          disabled={loading}
          onClick={form.handleSubmit(handleSubmit)}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
}

function formatEmailError(error: any) {
  const hasEmailFieldError = get(error, 'data.email', false);

  if (hasEmailFieldError) {
    const typedError: EmailResponseError = error as EmailResponseError;

    if ('message' in typedError.data.email) {
      let e = `${typedError.data.email.message}`;
      if (typedError.data.email.existing_membership_id) {
        e += ` Existing membership id: ${typedError.data.email.existing_membership_id}`;
      }
      return e;
    }

    if ('length' in typedError.data.email) {
      return (typedError.data.email as string[]).join(',');
    }
  }

  const apiErrors = get(error, "data.errors", []);
  if (Array.isArray(apiErrors) && apiErrors.length > 0) {
    return apiErrors.map((err) => err.detail).join(" ");
  }

  return genericErrorMessage;
}
