import { useState } from 'react';

import { pick } from 'lodash-es';
import { FormProvider, useForm } from 'react-hook-form';

import { useAppDispatch } from 'src/app/store';
import {
  PricingSchemeRead,
  PricingSchemeWrite,
  usePricingPricingSchemesCreateMutation,
  usePricingPricingSchemesPartialUpdateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { CurrencyFormContextField } from 'src/common/FormField/CurrencyFormContextField';
import { ContextFormFieldNumber } from 'src/common/FormField/v2/context/components';
import { FormSubmitButton } from 'src/common/FormSubmitButton';
import { Button } from 'src/common/primitives/Button';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { AfterHoursPricingRow } from './AfterHoursPricingRow';
import { LateCancellationAmountField } from './late-cancellation/LateCancellationAmountField';
import { LateCancellationFeeTypeField } from './late-cancellation/LateCancellationFeeTypeField';
import { MileageRoundingSelectField } from './MileageRoundingSelectField';
import { NoShowAmountField } from './no-show/NoShowAmountField';
import { NoShowFeeTypeField } from './no-show/NoShowFeeTypeField';
import { ObservedHolidaysField } from './ObservedHolidaysField';
import { PricingWizardMode } from './PricingWizard';
import {
  PricingSchemeWriteKeysForStepOne,
  SchemeMetaFormValues,
} from './PricingWizardSchemeMetaStep';
import { StairFeeTypeField } from './StairFeeTypeField';

export type SchemeConfigFormValues = Omit<
  PricingSchemeWrite,
  PricingSchemeWriteKeysForStepOne
> & {
  // We're abusing late_cancellation_fee_cents on the backend, but need to use
  // a different field name for react hook form to be happy and keep validations
  // separate for when the fee is flat vs percentage. This can fall off
  // once the backend is updated to use a separate field for percentage.
  late_cancellation_fee_percentage?: number;
  // Likewise, with no show fee
  no_show_fee_percentage?: number;
};

export type PricingWizardBaseConfigStepProps = {
  onCancel: () => void;
  onSubmit: (values: SchemeConfigFormValues) => void;
  workInProgressScheme: SchemeMetaFormValues;
  originalPricingSchemeValues?: PricingSchemeRead;
  mode: PricingWizardMode;
};

export const defaultValues: SchemeConfigFormValues = {
  holiday_fee_cents: 0,
  observed_holidays: [],
  stair_fee_type: '',
  stair_fee_cents: 0,
  minimum_steps: 0,
  base_fee_cents: 0,
  minimum_price_cents: 0,
  loaded_mileage_rate_cents: 0,
  free_loaded_miles: 0,
  long_distance_mileage_rate_cents: 0,
  long_distance_mileage_start: 0,
  unloaded_mileage_rate_cents: 0,
  free_unloaded_miles: 0,
  unloaded_return_mileage_rate_cents: 0,
  free_unloaded_return_miles: 0,
  wait_time_rate_cents: 0,
  wait_time_period_minutes: 0,
  wait_time_free_minutes: 0,
  late_cancellation_fee_cents: 0,
  late_cancellation_period_minutes: 0,
  late_cancellation_fee_type: '',
  late_cancellation_fee_percentage: 0,
  no_show_fee_type: '',
  no_show_fee_cents: 0,
  no_show_fee_percentage: 0,
  additional_attendant_rate_cents: 0,
  additional_passenger_rate_cents: 0,
  bariatric_passenger_fee_cents: 0,
  wheelchair_rental_fee_cents: 0,
  after_hours_fee_cents: 0,
  // These are important to set to undefined so we pick all the fields on edit
  // See pick(originalPricingSchemeValues, Object.keys(defaultValues))
  business_hours_start_time: undefined,
  business_hours_end_time: undefined,
  oxygen_fee_cents: 0,
  weekend_fee_cents: 0,
  infectious_disease_fee_cents: 0,
  same_day_booking_fee_cents: 0,
  mileage_rounding: 'none',
};

function FieldRowDivider() {
  return <div className="col-span-3 hidden border sm:block" />;
}

export function PricingWizardSchemeConfigurationStep({
  onCancel,
  onSubmit,
  workInProgressScheme,
  originalPricingSchemeValues,
  mode,
}: PricingWizardBaseConfigStepProps) {
  const formDefaultValues: SchemeConfigFormValues = originalPricingSchemeValues
    ? pick(originalPricingSchemeValues, Object.keys(defaultValues))
    : defaultValues;
  // Again, some extra handling because the backend only uses late_cancellation_fee_cents
  if (formDefaultValues.late_cancellation_fee_type === 'Percentage') {
    formDefaultValues.late_cancellation_fee_percentage =
      formDefaultValues.late_cancellation_fee_cents ||
      defaultValues.late_cancellation_fee_percentage;
    formDefaultValues.late_cancellation_fee_cents =
      defaultValues.late_cancellation_fee_cents;
  }

  // And likewise with no show fee
  if (formDefaultValues.no_show_fee_type === 'Percentage') {
    formDefaultValues.no_show_fee_percentage =
      formDefaultValues.no_show_fee_cents ||
      defaultValues.no_show_fee_percentage;
    formDefaultValues.no_show_fee_cents = defaultValues.no_show_fee_cents;
  }
  const methods = useForm<SchemeConfigFormValues>({
    defaultValues: formDefaultValues,
  });
  const [createScheme] = usePricingPricingSchemesCreateMutation();
  const [updateScheme] = usePricingPricingSchemesPartialUpdateMutation();
  const [formError, setFormError] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  return (
    <div
      data-testid="pricing-wizard-scheme-config"
      className="mt-6 sm:max-w-7xl"
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(async (values) => {
            // Because the backend uses cents for both flat and percentage fees,
            // we need to remove it from values and handle it separately
            // This will be able to be removed once the backend is updated
            const lateCancellationPercentage =
              values.late_cancellation_fee_percentage;
            delete values.late_cancellation_fee_percentage;
            // Likewise, with no show fee
            const noShowPercentage = values.no_show_fee_percentage;
            delete values.no_show_fee_percentage;
            if (mode === 'create' || mode === 'clone') {
              try {
                const newSchemeResponse = await createScheme({
                  pricingScheme: {
                    ...workInProgressScheme,
                    ...values,
                    long_distance_mileage_rate_cents:
                      // In this case, don't send anything if the value is 0 or empty/undefined/null
                      values.long_distance_mileage_rate_cents || undefined,
                    long_distance_mileage_start:
                      values.long_distance_mileage_start || undefined,
                    after_hours_fee_cents: !(
                      values.business_hours_start_time &&
                      values.business_hours_end_time
                    )
                      ? undefined
                      : values.after_hours_fee_cents,
                    late_cancellation_fee_cents:
                      values.late_cancellation_fee_type === 'Percentage'
                        ? lateCancellationPercentage
                        : values.late_cancellation_fee_cents,
                    no_show_fee_cents:
                      values.no_show_fee_type === 'Percentage'
                        ? noShowPercentage
                        : values.no_show_fee_cents,
                  },
                });
                if ('error' in newSchemeResponse) {
                  return setFormError(
                    formatServerError(newSchemeResponse.error)
                  );
                }
                onSubmit(newSchemeResponse.data);
                dispatch(
                  show({
                    title: `Successfully created pricing`,
                    type: 'success',
                  })
                );
              } catch (e) {
                setFormError(formatServerError(e));
              }
            } else if (originalPricingSchemeValues?.id) {
              try {
                const updatedSchemeResponse = await updateScheme({
                  id: originalPricingSchemeValues.id,
                  patchedPricingScheme: {
                    ...workInProgressScheme,
                    ...values,
                    // In this case, we send 0 if the user wants to remove the value
                    long_distance_mileage_rate_cents:
                      values.long_distance_mileage_rate_cents || 0,
                    long_distance_mileage_start:
                      values.long_distance_mileage_start || 0,
                    after_hours_fee_cents: !(
                      values.business_hours_start_time &&
                      values.business_hours_end_time
                    )
                      ? 0
                      : // If this value is null, the backend will 500
                        values.after_hours_fee_cents || 0,
                    business_hours_start_time:
                      values.business_hours_start_time || null,
                    business_hours_end_time:
                      values.business_hours_end_time || null,
                    late_cancellation_fee_cents:
                      values.late_cancellation_fee_type === 'Percentage'
                        ? lateCancellationPercentage
                        : values.late_cancellation_fee_cents,
                    no_show_fee_cents:
                      values.no_show_fee_type === 'Percentage'
                        ? noShowPercentage
                        : values.no_show_fee_cents,
                  },
                });
                if ('error' in updatedSchemeResponse) {
                  return setFormError(
                    formatServerError(updatedSchemeResponse.error)
                  );
                }
                onSubmit(values);
                dispatch(
                  show({
                    title: `Successfully updated pricing`,
                    type: 'success',
                  })
                );
              } catch (e) {
                setFormError(formatServerError(e));
              }
            }
          })}
          data-testid="pricing-wizard-scheme-config-form"
        >
          <div className="grid gap-2 md:grid-cols-3 md:gap-x-16 md:gap-y-3">
            {formError && (
              <div className="col-span-3">
                <FormErrorMessage>{formError}</FormErrorMessage>
              </div>
            )}
            <div>
              <CurrencyFormContextField
                fieldPath="base_fee_cents"
                label="Base Fee"
                inline
                helperText="Enter the base fee that will be charged per trip."
              />
            </div>
            <div>
              <CurrencyFormContextField
                fieldPath="minimum_price_cents"
                label="Minimum Trip Price"
                inline
                helperText="Enter minimum price that will be charged per trip."
              />
            </div>
            <div>
              <MileageRoundingSelectField fieldPath="mileage_rounding" />
            </div>
            <ConfigurationGridSpacer />

            <FieldRowDivider />

            <div>
              <CurrencyFormContextField
                fieldPath="loaded_mileage_rate_cents"
                label="Loaded Mileage Rate"
                inline
                helperText="Enter the rate per mile for loaded miles."
              />
            </div>
            <div>
              <ContextFormFieldNumber
                fieldPath="free_loaded_miles"
                label="Free Miles"
                inline
                helperText="Enter the number of loaded miles that will be free of charge."
              />
            </div>
            <ConfigurationGridSpacer />

            <FieldRowDivider />

            <div>
              <CurrencyFormContextField
                fieldPath="long_distance_mileage_rate_cents"
                label="Long Distance Mileage Rate"
                inline
                helperText="Enter the rate per mile for long distance miles."
              />
            </div>
            <div>
              <ContextFormFieldNumber
                fieldPath="long_distance_mileage_start"
                label="Long Distance Start"
                inline
                helperText="Specify the minimum number of miles required for the long distance rate to apply."
              />
            </div>
            <ConfigurationGridSpacer />

            <FieldRowDivider />

            <div>
              <CurrencyFormContextField
                fieldPath="unloaded_mileage_rate_cents"
                label="Unloaded Pickup Mileage Rate"
                inline
                helperText="Enter the rate per mile for unloaded pickup miles (distance from HQ to pickup)."
              />
            </div>
            <div>
              <ContextFormFieldNumber
                fieldPath="free_unloaded_miles"
                label="Free Miles"
                inline
                helperText="Enter the number of unloaded pickup miles that will be free of charge."
              />
            </div>
            <ConfigurationGridSpacer />

            <FieldRowDivider />

            <div>
              <CurrencyFormContextField
                fieldPath="unloaded_return_mileage_rate_cents"
                label="Unloaded Return Mileage Rate"
                inline
                helperText="Enter the rate per mile for unloaded return miles (distance from dropoff to HQ)."
              />
            </div>
            <div>
              <ContextFormFieldNumber
                fieldPath="free_unloaded_return_miles"
                label="Free Miles"
                inline
                helperText="Enter the number of unloaded return miles that will be free of charge."
              />
            </div>
            <ConfigurationGridSpacer />

            <FieldRowDivider />

            <div>
              <CurrencyFormContextField
                fieldPath="wait_time_rate_cents"
                label="Wait Time Rate"
                inline
                helperText="Enter the rate per unit of waiting time."
              />
            </div>
            <div>
              <ContextFormFieldNumber
                fieldPath="wait_time_period_minutes"
                label="Time Period"
                inline
                helperText="Specify the period in minutes used to calculate the wait time rate. Ex: $20 per 15 minutes."
              />
            </div>
            <div>
              <ContextFormFieldNumber
                fieldPath="wait_time_free_minutes"
                label="Free Minutes"
                inline
                helperText="Enter the number of wait time minutes that will be free of charge."
              />
            </div>

            <FieldRowDivider />

            <div>
              <LateCancellationAmountField />
            </div>
            <div>
              <ContextFormFieldNumber
                fieldPath="late_cancellation_period_minutes"
                label="Time Window"
                inline
                helperText="Specify the number of minutes before the scheduled pickup time within which cancellations are considered late."
              />
            </div>
            <div>
              <LateCancellationFeeTypeField fieldPath="late_cancellation_fee_type" />
            </div>

            <FieldRowDivider />

            <div>
              <NoShowAmountField />
            </div>
            <div>
              <NoShowFeeTypeField fieldPath="no_show_fee_type" />
            </div>
            <ConfigurationGridSpacer />

            <FieldRowDivider />

            <AfterHoursPricingRow />

            <FieldRowDivider />

            <div>
              <CurrencyFormContextField
                fieldPath="holiday_fee_cents"
                label="Holiday Fee"
                inline
                helperText="Enter the fee for trips that occur on holidays."
              />
            </div>
            <div>
              <ObservedHolidaysField fieldPath="observed_holidays" inline />
            </div>
            <div>
              <CurrencyFormContextField
                fieldPath="weekend_fee_cents"
                label="Weekend Fee"
                inline
                helperText="Enter the fee for trips that occur during weekends."
              />
            </div>

            <FieldRowDivider />

            <div>
              <CurrencyFormContextField
                fieldPath="stair_fee_cents"
                label="Stair Fee"
                inline
                helperText="Enter the fee charged for trips involving stairs."
              />
            </div>
            <div>
              <ContextFormFieldNumber
                fieldPath="minimum_steps"
                label="Minimum Steps"
                inline
                helperText="Specify the minimum number of steps required for the stair fee to apply."
              />
            </div>
            <div>
              <StairFeeTypeField fieldPath="stair_fee_type" />
            </div>

            <FieldRowDivider />

            <div>
              <CurrencyFormContextField
                fieldPath="wheelchair_rental_fee_cents"
                label="Wheelchair Rental"
                inline
                helperText="Enter the fee for providing a wheelchair."
              />
            </div>
            <div>
              <CurrencyFormContextField
                fieldPath="oxygen_fee_cents"
                label="Oxygen Fee"
                inline
                helperText="Enter the fee for providing oxygen during transport."
              />
            </div>
            <div>
              <CurrencyFormContextField
                fieldPath="additional_attendant_rate_cents"
                label="Additional Attendant"
                inline
                helperText="Enter the fee for each additional attendant required."
              />
            </div>

            <FieldRowDivider />

            <div>
              <CurrencyFormContextField
                fieldPath="additional_passenger_rate_cents"
                label="Additional Passenger"
                inline
                helperText="Enter the fee for each additional passenger."
              />
            </div>
            <div>
              <CurrencyFormContextField
                fieldPath="bariatric_passenger_fee_cents"
                label="Bariatric Passenger"
                inline
                helperText="Enter the fee for transporting bariatric passengers."
              />
            </div>
            <div>
              <CurrencyFormContextField
                fieldPath="infectious_disease_fee_cents"
                label="Infectious Disease Fee"
                inline
                helperText="Enter the fee for transporting passengers with infectious diseases."
              />
            </div>

            <FieldRowDivider />

            <div>
              <CurrencyFormContextField
                fieldPath="same_day_booking_fee_cents"
                label="Same Day Booking Fee"
                inline
                helperText="Enter the fee for same day bookings."
              />
            </div>
          </div>

          <div className="mt-4 flex justify-between gap-2">
            <Button
              onClick={onCancel}
              className="flex-grow"
              disabled={methods.formState.isSubmitting}
            >
              Cancel
            </Button>
            <FormSubmitButton className="flex-grow" buttonName="Save" />
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

/*
  Using these instead of colspan to keep label and inputs close and preserve alignment
*/
function ConfigurationGridSpacer() {
  return <div className="hidden md:block" />;
}
