import { useCallback, useEffect, useState } from 'react';

import {
  InvoiceRefundRead,
  TilledPaymentMethodResponse,
  useLazyInvoicesRefundsRetrieveQuery,
} from 'src/common/external/bambi-api/bambiApi';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { InvoicePaymentWithPaymentMethod } from '../paymentColumns';

export type InvoiceRefundWithPaymentMethod = InvoiceRefundRead & {
  paymentMethod: TilledPaymentMethodResponse | null;
};

export function useHydratePaymentRefunds(
  refunds?: string[],
  payments?: InvoicePaymentWithPaymentMethod[]
) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<InvoiceRefundWithPaymentMethod[]>([]);
  const [fetchRefund] = useLazyInvoicesRefundsRetrieveQuery();

  const handleFetchRefunds = useCallback(
    async (refundsToHydrate: string[]) => {
      try {
        const hydratedRefunds = await Promise.all(
          refundsToHydrate.map((id) =>
            fetchRefund({
              id,
            }).unwrap()
          )
        );

        // Join payment method data with refunds
        const fullyHydratedRefunds: InvoiceRefundWithPaymentMethod[] =
          hydratedRefunds.map((refund) => {
            const payment =
              payments?.find((p) => p.id === refund.payment) ?? null;

            const paymentMethod = payment?.paymentMethod ?? null;

            return {
              ...refund,
              paymentMethod,
            };
          });
        setData(fullyHydratedRefunds);
      } catch (e) {
        setError(formatServerError(e));
      } finally {
        setIsLoading(false);
      }
    },
    [fetchRefund, payments]
  );

  useEffect(() => {
    if (!refunds?.length) {
      setIsLoading(false);
      setData([]);
      setError(null);
      return;
    }

    handleFetchRefunds(refunds);
  }, [handleFetchRefunds, refunds]);

  return {
    isLoading,
    data,
    error,
  };
}
