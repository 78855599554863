import { useEffect } from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';

import { RootState, useAppSelector } from 'src/app/store';
import { states } from 'src/common/data/states';
import { FormField } from 'src/common/FormField';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormFieldContainer } from 'src/common/FormField/FormFieldContainer';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { FormFieldSet } from 'src/common/FormField/FormFieldSet';
import {
  ContextFormFieldLocationTypeahead,
  ContextFormFieldNumber,
  ContextFormFieldSwitch,
  ContextFormFieldText,
  ContextFormFieldTextArea,
} from 'src/common/FormField/v2/context/components';
import { FormSubmitButton } from 'src/common/FormSubmitButton';
import { Button } from 'src/common/primitives/Button';
import { Select, SelectOption } from 'src/common/primitives/Select';

import { assignDefaultFormValues } from './assignDefaultFormValues';
import { DeleteAction } from './DeleteAction';
import { FormValues } from './FormValues';
import { isValidUSALicensePlate } from './isValidUSALicensePlate';
import { isValidVin } from './isValidVin';

const vehicleTypeOptions = [
  { value: 'wheelchair', label: 'Wheelchair' },
  { value: 'ambulatory', label: 'Ambulatory' },
  { value: 'stretcher', label: 'Stretcher' },
];

interface VehicleFormStep1Props {
  onSubmit: (values: FormValues) => void;
  onCancel: () => void;
  onDelete: () => void;
  onFormDirtyChange: (isDirty: boolean) => void;
}

export function VehicleFormStep1({
  onSubmit,
  onCancel,
  onDelete,
  onFormDirtyChange,
}: VehicleFormStep1Props) {
  const selectedVehicle = useAppSelector(
    (state: RootState) => state.fleet.selectedVehicle
  );

  const methods = useForm({
    defaultValues: assignDefaultFormValues(selectedVehicle),
  });

  useEffect(() => {
    onFormDirtyChange(methods.formState.isDirty);
  }, [methods.formState.isDirty, onFormDirtyChange]);

  const { control, handleSubmit, reset, getValues } = methods;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit((val) => {
          onSubmit(getValues());
        })}
        data-testid="vehicle-form-step-1"
      >
        <div className="flex max-h-[65vh] min-w-[80vw] flex-col gap-4 overflow-y-scroll sm:min-w-full">
          <FormFieldSet
            Fields={
              <>
                <Controller
                  name="category"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormField
                        label="Vehicle Type"
                        type="select"
                        placeholder="Select Vehicle Type"
                        inputProps={{
                          id: 'category',
                        }}
                        onChange={(option) => {
                          onChange(option.value);
                        }}
                        value={
                          value
                            ? vehicleTypeOptions.find((x) => x.value === value)
                            : ''
                        }
                        options={vehicleTypeOptions}
                      />
                    );
                  }}
                />
                <ContextFormFieldSwitch
                  fieldPath="can_be_scheduled"
                  label="Is this vehicle active?"
                />
                <ContextFormFieldText
                  fieldPath="nickname"
                  label="Name"
                  rules={{
                    required: 'Vehicle name is required',
                  }}
                />
                <ContextFormFieldLocationTypeahead
                  fieldPath="storage_location"
                  label="Overnight storage location"
                  rules={{
                    // Unfortunately required validation doesn't fire for
                    // location typeaheads. My current theory is something with
                    // ref passing in the impl of LocationTypeahead since there are
                    // two inputs: The query input and the combobox (which has the committed value)
                    // We could try some different base inputs if we can't figure out Combobox
                    // Adding the duplication for now
                    required: 'Storage location is required',
                    validate: (value) => {
                      if (!value.value) {
                        return 'Storage location is required';
                      }
                      return true;
                    },
                  }}
                />
              </>
            }
          />
          <FormFieldSet
            legend="Vehicle Details"
            Fields={
              <>
                <ContextFormFieldText
                  fieldPath="make"
                  label="Make"
                  colSpan={3}
                />
                <ContextFormFieldText
                  fieldPath="model"
                  label="Model"
                  colSpan={3}
                />
                <ContextFormFieldText
                  fieldPath="color"
                  label="Color"
                  colSpan={3}
                />
                <ContextFormFieldNumber
                  fieldPath="year"
                  label="Year"
                  colSpan={3}
                />
                <ContextFormFieldText
                  fieldPath="vin"
                  label="VIN"
                  colSpan={6}
                  rules={{
                    // Validation func handles requirement check
                    // Label rendering checks for required in rules hash
                    // so we provide it
                    required: 'Vin is required',
                    validate: (value) => {
                      return typeof value === 'string'
                        ? isValidVin(value)
                        : 'Invalid VIN';
                    },
                  }}
                />
                <ContextFormFieldText
                  fieldPath="license_plate"
                  label="License Plate"
                  placeholder="e.g. 123 455 678"
                  colSpan={3}
                  rules={{
                    validate: (value) => {
                      const licensePlateState = getValues(
                        'license_plate_state'
                      );
                      return typeof value === 'string'
                        ? isValidUSALicensePlate(value, licensePlateState)
                        : 'Invalid license plate';
                    },
                  }}
                  helperText="Between 2 and 7 or 8 (depending on state) characters. Can have 1 hyphen"
                />
                <FormFieldController name="license_plate_state">
                  {({ field, fieldState }) => {
                    return (
                      <FormFieldContainer colSpan={3}>
                        <DefaultFormFieldLayout
                          label="License Plate State"
                          error={fieldState.error?.message?.toString()}
                          inputProps={{ id: 'license-plate-state' }}
                        >
                          <Select
                            placeholder="Select State"
                            options={states}
                            {...field}
                            onChange={(value?: SelectOption) => {
                              field.onChange(value?.value);
                            }}
                            error={fieldState.error?.message?.toString()}
                            testIdSuffix="license-plate-state"
                          />
                        </DefaultFormFieldLayout>
                      </FormFieldContainer>
                    );
                  }}
                </FormFieldController>
                <ContextFormFieldTextArea
                  fieldPath="notes"
                  label="Additional Notes"
                  colSpan={12}
                />
              </>
            }
          />
        </div>
        {selectedVehicle.id && <DeleteAction onDelete={onDelete} />}
        <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <Button
            className="sm:col-span-3"
            onClick={() => {
              onCancel();
              reset();
            }}
          >
            Cancel
          </Button>
          <FormSubmitButton className="sm:col-span-3" />
        </div>
      </form>
    </FormProvider>
  );
}
