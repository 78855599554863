import { useAppDispatch } from 'src/app/store';
import { Invoice, InvoiceRead } from 'src/common/external/bambi-api/bambiApi';

import {
  editInvoice,
  resetEditing,
  setHydratedInvoice,
  setInvoiceModalOpen,
} from '../invoice.slice';
import { HydratedInvoice } from '../useHydratedInvoice';

export function useCreateEmptyInvoice() {
  const dispatch = useAppDispatch();

  return (invoice?: Partial<Invoice>) => {
    dispatch(resetEditing());
    dispatch(editInvoice((invoice as InvoiceRead) ?? null));
    dispatch(setInvoiceModalOpen(true));
  };
}

export function useCreateHydratedInvoice() {
  const dispatch = useAppDispatch();

  return (invoice: Partial<HydratedInvoice>) => {
    dispatch(resetEditing());
    // Upcasting to satisfy the type, the logic within InvoiceController can
    // handle resolving the editing state correctly
    dispatch(editInvoice(invoice as unknown as InvoiceRead));
    dispatch(setHydratedInvoice(invoice as unknown as HydratedInvoice));
    dispatch(setInvoiceModalOpen(true));
  };
}
