// OneSchema has no documentation on their error codes. 3 is the only one we've seen so far.

import { OneSchemaLaunchError } from '@oneschema/react';

const oneSchemaErrorCodeToMessageMap: Record<OneSchemaLaunchError, string> = {
  // This is thrown for a missing template key -- weird it's not MissingTemplate error.
  // That must be the case when we send no template at all
  [OneSchemaLaunchError.LaunchError]:
    'The import flow failed due to a template key mismatch. Please contact us and let us know which template you are trying to use.',
  [OneSchemaLaunchError.MissingJwt]:
    'Failed to launch import session. Please contact us and let us know which template you are trying to use.',
  [OneSchemaLaunchError.MissingTemplate]:
    'Failed to launch import session due to missing template. Please contact us and let us know which template you are trying to use.',
  [OneSchemaLaunchError.MissingSessionToken]:
    'Failed to launch import session. Please contact us and let us know which template you are trying to use.',
};

const DEFAULT_ERROR_MESSAGE =
  'The import flow failed to launch potentially due to a template key mismatch. Please retry and contact us if it continues.';

export function getOneSchemaErrorMessageFromCode(code: string) {
  return (
    oneSchemaErrorCodeToMessageMap[code as unknown as OneSchemaLaunchError] ||
    DEFAULT_ERROR_MESSAGE
  );
}
