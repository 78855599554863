import { Outlet } from 'react-router-dom';

import { useAppSelector } from 'src/app/store';
import { HeaderToolbarAndContentLayout } from 'src/common/primitives/HeaderToolbarAndContentLayout';

import ChatProvider from './ChatProvider';

export function ChatLayout() {
  const { sendbirdAccessToken } = useAppSelector((state) => state.chat);
  return (
    <HeaderToolbarAndContentLayout
      actionSection={<></>}
      content={
        <ChatProvider>{sendbirdAccessToken ? <Outlet /> : <></>}</ChatProvider>
      }
    />
  );
}
