import { View } from '@react-pdf/renderer';

import { AdditionalRequirementDetails } from './AdditionalRequirementDetails';
import { AdditionalRequirementDetailsPDF } from './AdditionalRequirementDetailsPDF';
import { AfterHoursFeeDetails } from './AfterHoursFeeDetails';
import { AfterHoursFeeDetailsPDF } from './AfterHoursFeeDetailsPDF';
import { HolidayFeeDetails } from './HolidayFeeDetails';
import { HolidayFeeDetailsPDF } from './HolidayFeeDetailsPDF';
import { LateCancellationFeeDetails } from './LateCancellationFeeDetails';
import { LateCancellationFeeDetailsPDF } from './LateCancellationFeeDetailsPDF';
import { MileageDetails } from './MileageDetails';
import { MileageDetailsPDF } from './MileageDetailsPDF';
import { NoShowFeeDetails } from './NoShowFeeDetails';
import { NoShowFeeDetailsPDF } from './NoShowFeeDetailsPDF';
import { StairFeeDetails } from './StairFeeDetails';
import { StairFeeDetailsPDF } from './StairFeeDetailsPDF';
import {
  PriceSummaryData,
  PriceSummaryLabel,
  PriceSummaryRenderer,
} from './types';
import { WaitTimeDetails } from './WaitTimeDetails';
import { WaitTimeDetailsPDF } from './WaitTimeDetailsPDF';

const labelDetailsMap: Record<PriceSummaryLabel, PriceSummaryRenderer> = {
  'Base Fee': () => <td colSpan={3}></td>,
  'Minimum Trip Price': () => <td colSpan={3}></td>,
  'Loaded Mileage Fee': (data) => <MileageDetails data={data} />,
  'Long Distance Mileage Rate': (data) => <MileageDetails data={data} />,
  'Unloaded Pickup Mileage Fee': (data) => <MileageDetails data={data} />,
  'Unloaded Return Mileage Fee': (data) => <MileageDetails data={data} />,
  'Wait Time Fee': (data) => <WaitTimeDetails data={data} />,
  'No Show Fee': (data) => <NoShowFeeDetails data={data} />,
  'Late Cancellation Fee': (data) => <LateCancellationFeeDetails data={data} />,
  'Pickup Stair Fee': (data) => <StairFeeDetails data={data} />,
  'Dropoff Stair Fee': (data) => <StairFeeDetails data={data} />,
  'Additional Attendants Fee': (data) => (
    <AdditionalRequirementDetails
      label="Attendants"
      property={data?.additional_attendants}
    />
  ),
  'Additional Passengers Fee': (data) => (
    <AdditionalRequirementDetails
      label="Passengers"
      property={data?.additional_passengers}
    />
  ),
  'Bariatric Fee': (data) => (
    <AdditionalRequirementDetails
      label="Bariatric"
      property={data?.is_bariatric ? 'Yes' : 'No'}
    />
  ),
  'Wheelchair Rental Fee': (data) => (
    <AdditionalRequirementDetails
      label="Provided"
      property={data?.is_wheelchair_provided ? 'Yes' : 'No'}
    />
  ),
  'Oxygen Fee': (data) => (
    <AdditionalRequirementDetails
      label="Provided"
      property={data?.is_oxygen_provided ? 'Yes' : 'No'}
    />
  ),
  'After Hours Fee': (data) => <AfterHoursFeeDetails data={data} />,
  'Weekend Fee': (data) => (
    <AdditionalRequirementDetails
      label="Day of Week"
      property={data?.day_of_week}
    />
  ),
  'Holiday Fee': (data) => <HolidayFeeDetails data={data} />,
  'Infectious Disease Fee': () => <td colSpan={3}></td>,
  'Same Day Booking Fee': () => <td colSpan={3}></td>,
};

const labelDetailsPdfMap: Record<PriceSummaryLabel, PriceSummaryRenderer> = {
  'Base Fee': () => <View />,
  'Minimum Trip Price': () => <View />,
  'Loaded Mileage Fee': (data) => <MileageDetailsPDF data={data} />,
  'Long Distance Mileage Rate': (data) => <MileageDetailsPDF data={data} />,
  'Unloaded Pickup Mileage Fee': (data) => <MileageDetailsPDF data={data} />,
  'Unloaded Return Mileage Fee': (data) => <MileageDetailsPDF data={data} />,
  'Wait Time Fee': (data) => <WaitTimeDetailsPDF data={data} />,
  'No Show Fee': (data) => <NoShowFeeDetailsPDF data={data} />,
  'Late Cancellation Fee': (data) => (
    <LateCancellationFeeDetailsPDF data={data} />
  ),
  'Pickup Stair Fee': (data) => <StairFeeDetailsPDF data={data} />,
  'Dropoff Stair Fee': (data) => <StairFeeDetailsPDF data={data} />,
  'Additional Attendants Fee': (data) => (
    <AdditionalRequirementDetailsPDF
      label="Attendants"
      property={data?.additional_attendants}
    />
  ),
  'Additional Passengers Fee': (data) => (
    <AdditionalRequirementDetailsPDF
      label="Passengers"
      property={data?.additional_passengers}
    />
  ),
  'Bariatric Fee': (data) => (
    <AdditionalRequirementDetailsPDF
      label="Bariatric"
      property={data?.is_bariatric ? 'Yes' : 'No'}
    />
  ),
  'Wheelchair Rental Fee': (data) => (
    <AdditionalRequirementDetailsPDF
      label="Provided"
      property={data?.is_wheelchair_provided ? 'Yes' : 'No'}
    />
  ),
  'Oxygen Fee': (data) => (
    <AdditionalRequirementDetailsPDF
      label="Provided"
      property={data?.is_oxygen_provided ? 'Yes' : 'No'}
    />
  ),
  'After Hours Fee': (data) => <AfterHoursFeeDetailsPDF data={data} />,
  'Weekend Fee': (data) => (
    <AdditionalRequirementDetailsPDF
      label="Day of Week"
      property={data?.day_of_week}
    />
  ),
  'Holiday Fee': (data) => <HolidayFeeDetailsPDF data={data} />,
  'Infectious Disease Fee': () => <View />,
  'Same Day Booking Fee': () => <View />,
};

interface LabelDetailsCellMapProps {
  label: PriceSummaryLabel;
  data?: PriceSummaryData | null;
  type?: 'web' | 'pdf';
}
export function LabelDetailsCellMap({
  label,
  data,
  type = 'web',
}: LabelDetailsCellMapProps) {
  if (type === 'pdf') {
    return <>{labelDetailsPdfMap[label](data)}</>;
  }

  return <>{labelDetailsMap[label](data)}</>;
}
