import 'src/app/app.css';

import {
  ColumnDef,
  ColumnSort,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  PaginationState,
  Row,
  RowData,
  RowSelectionState,
  TableOptions,
  Updater,
  useReactTable,
} from '@tanstack/react-table';

import { DEFAULT_PAGINATION_PAGE_SIZE } from 'src/common/external/bambi-api/constants';

import { classNames } from '../classNames';
import { useLocalStorage } from '../util/useLocalStorage/useLocalStorage';
import { DataGridPagination } from './DataGridPagination';
import { DataGridTableBody } from './DataGridTableBody';
import { DataGridTableHeader } from './DataGridTableHeader';

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData?: (rowIndex: number, columnId: string, value: unknown) => void;
    deleteRow?: (rowIndex: number, row: TData) => void;
    isEditingRow?: (row: TData) => boolean;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    columnClassName?: string;
    // Component which handles filtering
    filterComponent?: (
      column: ColumnDef<TData, TValue>,
      open: boolean,
      onClose: () => void
    ) => React.ReactNode;
    // Number of filters applied to display on the badge
    filterCount?: () => number;
    enableHeaderMenu?: boolean;
    // Currently, we rely on flexible column widths so while size is supported
    // in columnDef, it defaults to 150px. This lets us override the width of
    // specific columns, without affecting the rest or doing some janky !150px
    // size comparison
    headerWidth?: number;
  }
}

export interface DataGridProps<TData> {
  tableId: string;
  data: TData[];
  columns: TableOptions<TData>['columns'];
  pagination?: {
    pageIndex: number;
    pageSize: number;
  };
  totalCount?: number;
  handlePaginationChange?: (
    paginationUpdater: Updater<PaginationState>
  ) => void;
  rowSelection?: RowSelectionState;
  setRowSelection?: React.Dispatch<
    React.SetStateAction<Record<string | number | symbol, boolean>>
  >;
  emptyState?: React.ReactNode;
  dataTestId?: string;
  orderedBy?: string;
  expandableRows?: boolean;
  onExpandedChange?: any;
  expandedState?: ExpandedState;
  includePagination?: boolean;
  headerClassName?: string;
  containerClassName?: string;
  rowClassName?: (row: Row<TData>) => string;
  cellClassName?: string;
  onUpdateData?: (rowIndex: number, columnId: string, value: unknown) => void;
  onDeleteRow?: (rowIndex: number, row: TData) => void;
  isEditingRow?: (row: TData) => boolean;
  getRowId?: (row: TData) => string;
  enableRowSelection?: TableOptions<TData>['enableRowSelection'];
  enableGridScrolling?: boolean;
  onOverRow?: (row: Row<TData>) => void;
  onOutRow?: (row: Row<TData>) => void;
}

export function DataGrid<TData extends RowData>({
  tableId,
  data,
  columns,
  pagination,
  totalCount,
  handlePaginationChange,
  rowSelection,
  setRowSelection,
  emptyState,
  dataTestId,
  orderedBy,
  onExpandedChange = () => {},
  expandedState,
  includePagination = true,
  headerClassName = '',
  containerClassName = '',
  rowClassName,
  cellClassName = '',
  onUpdateData = () => {},
  onDeleteRow = () => {},
  isEditingRow = () => false,
  getRowId,
  enableRowSelection,
  enableGridScrolling = true,
  onOverRow = () => {},
  onOutRow = () => {},
}: DataGridProps<TData>) {
  const sorting: ColumnSort[] = [];
  const [columnVisibility, setColumnVisibility] = useLocalStorage(
    `${tableId}-column-visibility`,
    {},
    true
  );

  if (orderedBy) {
    const isDescending = orderedBy.startsWith('-');
    const orderedById = orderedBy.slice(isDescending ? 1 : 0, orderedBy.length);
    const headerIds = columns.map((c) => c.id);

    if (headerIds.includes(orderedById)) {
      sorting.push({
        id: orderedById,
        desc: isDescending,
      });
    }
  }

  const table = useReactTable({
    data,
    columns,
    pageCount: totalCount
      ? Math.ceil(
          totalCount / (pagination?.pageSize || DEFAULT_PAGINATION_PAGE_SIZE)
        )
      : 1,
    state: {
      pagination: pagination || {
        pageIndex: 0,
        pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
      },
      rowSelection: rowSelection,
      sorting,
      expanded: expandedState,
      columnVisibility,
    },
    manualPagination: !!pagination,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: (paginationUpdater) => {
      if (handlePaginationChange) handlePaginationChange(paginationUpdater);
    },
    onRowSelectionChange: setRowSelection,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowCanExpand: expandedState ? () => true : () => false,
    onExpandedChange: onExpandedChange,
    getExpandedRowModel: getExpandedRowModel(),
    getRowId,
    enableRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    meta: {
      updateData: onUpdateData,
      deleteRow: onDeleteRow,
      isEditingRow,
    },
  });

  return (
    <>
      <div
        className={classNames(
          'min-h-[75px] max-w-[95vw] shadow ring-1 ring-black ring-opacity-5 md:rounded-lg xl:max-w-[97vw]',
          containerClassName,
          enableGridScrolling ? 'max-h-[73vh] overflow-scroll' : ''
        )}
      >
        <table
          data-testid={dataTestId}
          className="min-w-full divide-y divide-gray-300"
        >
          <DataGridTableHeader table={table} className={headerClassName} />
          <DataGridTableBody
            cellClassName={cellClassName}
            table={table}
            emptyState={emptyState}
            rowClassName={rowClassName}
            onOverRow={onOverRow}
            onOutRow={onOutRow}
          />
        </table>
      </div>

      {includePagination ? (
        <div className="px-[24px] py-[16px]">
          <DataGridPagination totalCount={totalCount} table={table} />
        </div>
      ) : null}
    </>
  );
}
