import { useCallback, useEffect } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { useAppDispatch } from 'src/app/store';
import {
  useBillingBillingSettingsPartialUpdateMutation,
  useBillingBillingSettingsRetrieveQuery,
  useOrganizationSettingsPartialUpdateMutation,
  useOrganizationSettingsRetrieveQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import BillingSettingsForm, {
  BillingSettingsFormValues,
} from './BillingSettingsForm';

export default function BillingSettingsScreen() {
  const dispatch = useAppDispatch();
  const form = useForm<BillingSettingsFormValues>({
    defaultValues: {},
  });

  const { data: currentSettings, isLoading } =
    useBillingBillingSettingsRetrieveQuery({});

  const { data: orgSettings, isLoading: isLoadingOrgSettings } =
    useOrganizationSettingsRetrieveQuery({});

  useEffect(() => {
    if (isLoading || isLoadingOrgSettings) {
      return;
    }

    form.reset({
      ...currentSettings,
      billing_address: orgSettings?.billing_address,
    });
  }, [
    isLoading,
    currentSettings,
    form,
    isLoadingOrgSettings,
    orgSettings?.billing_address,
  ]);

  const [updateBillingSettings] =
    useBillingBillingSettingsPartialUpdateMutation();

  const [updateOrgSettings] = useOrganizationSettingsPartialUpdateMutation();

  const handleSubmit = useCallback<SubmitHandler<BillingSettingsFormValues>>(
    async (data) => {
      const billing_address = data.billing_address;
      delete data.billing_address;

      try {
        await Promise.all([
          updateBillingSettings({
            patchedBillingSettings: {
              ...data,
              phone_number: data.phone_number ?? '',
            },
          }).unwrap(),
          updateOrgSettings({
            patchedOrganizationSettings: {
              billing_address,
            },
          }).unwrap(),
        ]);

        dispatch(
          show({
            type: 'success',
            title: 'Updated billing settings',
          })
        );
      } catch (e) {
        dispatch(
          show({
            type: 'error',
            title: 'Unable to update billing settings',
            description: formatServerError(e),
          })
        );
      }
    },
    [dispatch, updateBillingSettings, updateOrgSettings]
  );

  return (
    <FormProvider {...form}>
      {isLoading || isLoadingOrgSettings ? (
        <LoadingIndicator />
      ) : (
        <div>
          <BillingSettingsForm
            minimumInvoiceNumber={currentSettings?.invoice_seed_number ?? 1}
          />
        </div>
      )}
      <div className="flex justify-end pt-4">
        <Button
          type="submit"
          variant="primary"
          onClick={form.handleSubmit(handleSubmit)}
        >
          Save
        </Button>
      </div>
    </FormProvider>
  );
}
