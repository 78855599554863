import { useCallback } from 'react';

import { MbscEventUpdatedEvent } from '@mobiscroll/react';

import { useAppDispatch } from 'src/app/store';

import { dispatcherSlice } from '../dispatcher.slice';
import { useTripMenuActions } from './useTripMenuActions';

export function useOnEventUpdated() {
  const menuActions = useTripMenuActions();
  const dispatch = useAppDispatch();

  const onEventUpdated = useCallback(
    async (calendarUpdatedEvent: MbscEventUpdatedEvent) => {
      // This is where we want to do all our api updates and whatnot
      // We're starting with just reassigning. We'll use onEventCreated for new assignments
      // We also only support dragging on the dispatch screen so there's an assumption that
      // we're only dealing with DispatchTripRead instances here. If that changes we'll have
      // to firm up types
      if (
        calendarUpdatedEvent.oldEvent &&
        calendarUpdatedEvent.event.resource !==
          calendarUpdatedEvent.oldEvent.resource
      ) {
        const originalTripData = calendarUpdatedEvent.event.trip;
        const newAssignmentId = calendarUpdatedEvent.event.resource as string;
        await menuActions.reassign(
          originalTripData.id,
          newAssignmentId,
          originalTripData.status,
          originalTripData.multiload_id
        );
      }
      // We don't use onEventDragEnd because it immediately starts a dispatch poll that
      // resets the calendar event which looks weird
      dispatch(dispatcherSlice.actions.onCalendarEventDragEnd());
    },
    [dispatch, menuActions]
  );

  return onEventUpdated;
}
