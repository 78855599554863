import { cloneDeep } from 'lodash-es';
import { DateTime } from 'luxon';

import { DriverProfile } from '../driver-profile/DriverProfile';
import { defaultFormValues } from './defaultFormValues';
import { FormValues } from './FormValues';

export function assignFormDate(defaultValue: string, date?: string): string {
  return date ? DateTime.fromISO(date).toFormat('yyyy-MM-dd') : defaultValue;
}

export function assignDefaultFormValues(
  driverProfile: DriverProfile | null
): FormValues {
  const driver = cloneDeep(driverProfile);
  const obj = {
    id: driver?.id ?? '',
    member_id: driver?.memberId || defaultFormValues.member_id,
    first_name: driver?.firstName || defaultFormValues.first_name,
    last_name: driver?.lastName || defaultFormValues.last_name,
    can_be_scheduled: driver?.status || defaultFormValues.can_be_scheduled,
    license_number: driver?.licenseNumber ?? defaultFormValues.license_number,
    license_dob: assignFormDate(
      defaultFormValues.license_dob || '',
      driver?.dateOfBirth
    ),
    license_state: driver?.issuedState ?? defaultFormValues.license_state,
    license_expiration_date: assignFormDate(
      defaultFormValues.license_expiration_date,
      driver?.expirationDate
    ),
    can_do_wheelchair_service:
      driver?.vehicleSkills.includes('wheelchair') ??
      defaultFormValues.can_do_wheelchair_service,
    can_do_stretcher_service:
      driver?.vehicleSkills.includes('stretcher') ??
      defaultFormValues.can_do_stretcher_service,
    can_do_ambulatory_service:
      driver?.vehicleSkills.includes('ambulatory') ??
      defaultFormValues.can_do_ambulatory_service,
    can_do_broda_chair_service:
      driver?.vehicleSkills.includes('broda-chair') ??
      defaultFormValues.can_do_broda_chair_service,
    can_do_geri_chair_service:
      driver?.vehicleSkills.includes('geri-chair') ??
      defaultFormValues.can_do_geri_chair_service,
    can_do_curb_to_curb:
      driver?.serviceSkills.includes('curb-to-curb') ??
      defaultFormValues.can_do_curb_to_curb,
    can_do_door_to_door:
      driver?.serviceSkills.includes('door-to-door') ??
      defaultFormValues.can_do_door_to_door,
    can_do_door_through_door:
      driver?.serviceSkills.includes('door-through-door') ??
      defaultFormValues.can_do_door_through_door,
    can_do_bariatric:
      driver?.otherSkills.includes('bariatric') ??
      defaultFormValues.can_do_bariatric,
    can_do_stairs:
      driver?.otherSkills.includes('stairs') ?? defaultFormValues.can_do_stairs,
    deleted_at: driver?.deleted_at,
  };

  return obj;
}
