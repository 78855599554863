import formatServerError from 'src/common/util/serverErrorFormatter';

export function formatBillingServerError(e: any) {
  if (typeof e === 'string' && hasErrorFromTilled(e)) {
    return e;
  }

  if (
    typeof e === 'object' &&
    'message' in e &&
    hasErrorFromTilled(e.message)
  ) {
    return e.message;
  }

  return formatServerError(e);
}

function hasErrorFromTilled(message: string) {
  const messageSearches = ['Bad request:', 'card.'];

  return messageSearches.some((v) => message.includes(v));
}
