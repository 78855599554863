import { useMemo } from 'react';

import { Text, View } from '@react-pdf/renderer';

import { PriceSummary } from 'src/common/external/bambi-api/bambiApi';
import { commonStyles } from 'src/common/pdf/common.styles';
import { usePriceSummaryTableData } from 'src/features/pricing/PriceSummaryModal/PriceSummaryTable/usePriceSummaryTableData';

import { InvoiceExportCondensedReportRow } from '../types';
import { InvoicePDFPriceSummaryTable } from './InvoicePDFPriceSummaryTable';

interface InvoicePDFPriceSummaryProps {
  items: InvoiceExportCondensedReportRow[];
  invoiceNumber: string;
}

export function InvoicePDFPriceSummary({
  items,
  invoiceNumber,
}: InvoicePDFPriceSummaryProps) {
  return (
    <View style={[commonStyles.vstack, { gap: 2 }]}>
      <View style={[commonStyles.vstack, { marginBottom: 8, gap: 2 }]}>
        <Text style={commonStyles.textXl}>Trip Price Summary</Text>
        <Text style={[commonStyles.textMd, { color: '#71717a' }]}>
          Detailed fee breakdown per trip for invoice: {invoiceNumber}
        </Text>
      </View>
      <View style={commonStyles.separatorSm} />
      {items.map((item) => {
        return <TripPriceSummaryItem key={item.TripID} item={item} />;
      })}
    </View>
  );
}

interface TripPriceSummaryItemProps {
  item: InvoiceExportCondensedReportRow;
}
function TripPriceSummaryItem({ item }: TripPriceSummaryItemProps) {
  const summary: PriceSummary | null = useMemo(() => {
    try {
      return JSON.parse(item.PriceSummary);
    } catch (e) {
      return null;
    }
  }, [item.PriceSummary]);

  const { feeSummaries } = usePriceSummaryTableData(summary);

  return (
    <>
      <View style={[commonStyles.hstack]}>
        <View
          style={[
            commonStyles.hstack,
            commonStyles.fullWidth,
            {
              gap: 2,
              alignItems: 'center',
            },
          ]}
        >
          <Text>Trip ID: </Text>
          <Text style={[commonStyles.strong, commonStyles.textSmedium]}>
            {item.TripID}
          </Text>
        </View>
        <View
          style={[
            commonStyles.hstack,
            commonStyles.fullWidth,
            {
              gap: 2,
              alignItems: 'center',
            },
          ]}
        >
          <Text>Trip Date: </Text>
          <Text style={[commonStyles.strong, commonStyles.textSmedium]}>
            {item.TripDate}
          </Text>
        </View>
        <View
          style={[
            commonStyles.hstack,
            commonStyles.fullWidth,
            {
              gap: 2,
              alignItems: 'center',
            },
          ]}
        >
          <Text>Passenger: </Text>
          <Text style={[commonStyles.strong, commonStyles.textSmedium]}>
            {item.PassengerName}
          </Text>
        </View>
      </View>
      {summary ? (
        <InvoicePDFPriceSummaryTable rows={feeSummaries} />
      ) : (
        <View style={{ marginVertical: 8 }}>
          <Text
            style={[
              commonStyles.textSmedium,
              { textAlign: 'center', color: '#71717a' },
            ]}
          >
            No price summary available for trip.
          </Text>
          <View style={commonStyles.separatorSm} />
        </View>
      )}
    </>
  );
}
