import { MbscEventCreatedEvent } from '@mobiscroll/react';

import { useAppDispatch } from 'src/app/store';

import { dispatcherSlice } from '../dispatcher.slice';
import { useTripMenuActions } from './useTripMenuActions';

export function useOnEventCreated() {
  const dispatch = useAppDispatch();
  const menuActions = useTripMenuActions();

  return async (newEvent: MbscEventCreatedEvent) => {
    await menuActions.assign(
      newEvent.event.trip.id,
      newEvent.event.resource as string,
      newEvent.event.trip.status
    );
    dispatch(dispatcherSlice.actions.onCalendarEventDragEnd());
  };
}
