import { Fragment } from 'react';

import { Document, Font, Page, View } from '@react-pdf/renderer';
import { HyphenationCallback } from '@react-pdf/types';
import { sumBy, uniqBy } from 'lodash-es';
import { DateTime } from 'luxon';

import { SelectOption } from 'src/common/primitives/Select';

import { commonStyles } from '../../../../common/pdf/common.styles';
import { InvoiceColumnKey } from '../modals/DownloadInvoices/steps/components/InvoiceColumnSelect';
import { InvoiceExportCondensedReportItem } from '../types';
import { InvoicePDFFooter } from './InvoicePDFFooter';
import { InvoicePDFHeader } from './InvoicePDFHeader';
import { InvoicePDFOrganizationHeader } from './InvoicePDFOrganizationHero';
import { InvoicePDFPriceSummary } from './InvoicePDFPriceSummary';
import { InvoicePDFStats } from './InvoicePDFStats';
import { InvoicePDFTripTable } from './InvoicePDFTripTable';

// Support for emojis, see: https://react-pdf.org/fonts
Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

// Avoid hyphenations
const hyphenationCallback: HyphenationCallback = (word) => [word];
Font.registerHyphenationCallback(hyphenationCallback);

interface InvoicePDFDocumentProps {
  organizationBillingAddress: string;
  organizationBillingEmail: string;
  organizationBillingPhone: string;
  organizationName: string;
  organizationLogo: string;
  organizationTaxId?: string;
  invoiceNote: string;
  invoiceItems: InvoiceExportCondensedReportItem[];
  includePriceSummary?: boolean;
  selectedInvoiceColumns: SelectOption<InvoiceColumnKey>[];
}

export function InvoicePDFDocument({
  organizationBillingAddress,
  organizationBillingEmail,
  organizationBillingPhone,
  organizationLogo,
  organizationName,
  organizationTaxId,
  invoiceItems,
  invoiceNote,
  includePriceSummary = false,
  selectedInvoiceColumns,
}: InvoicePDFDocumentProps) {
  return (
    <Document>
      {invoiceItems.map((ii) => {
        const tripLineItems = ii.items.filter(
          (item) => item.LineItemType === 'trip'
        );
        const totalTrips = tripLineItems.length;
        const totalMiles = sumBy(ii.items, (item) => parseInt(item.Miles));
        const totalUniqPassengers = uniqBy(
          tripLineItems,
          (item) => item.PassengerID
        ).length;

        const invoiceDate = DateTime.fromFormat(ii.InvoiceDate, 'M/dd/yyyy');
        const invoiceDueDate = invoiceDate
          .plus({
            days: ii.ItemPayerInvoiceDueDays
              ? parseInt(ii.ItemPayerInvoiceDueDays)
              : 0,
          })
          .toLocaleString(DateTime.DATE_SHORT);

        const invoiceDueAmount = ii.InvoiceDueAmount
          ? parseInt(ii.InvoiceDueAmount) / 100
          : 0;

        const paymentsTotalAmount = ii.PaymentsTotalAmount
          ? parseInt(ii.PaymentsTotalAmount) / 100
          : 0;
        const tripsTotalAmount = ii.TripsTotalAmount
          ? parseInt(ii.TripsTotalAmount) / 100
          : 0;
        const modifiersTotalAmount = ii.ModifiersTotalAmount
          ? parseInt(ii.ModifiersTotalAmount) / 100
          : 0;

        const priceSummaryCompatibleLineItems = ii.items.filter(
          (item) =>
            item.LineItemType === 'trip' &&
            item.TripPriceMatchesPriceCalculation === 'True' &&
            item.PriceSummary
        );

        return (
          <Fragment key={ii.InvoiceNo}>
            <Page size="A4" orientation="landscape">
              <View style={[commonStyles.page, { paddingTop: 0 }]}>
                <InvoicePDFHeader
                  invoiceNumber={ii.InvoiceNo}
                  issueDate={invoiceDate.toLocaleString(DateTime.DATE_SHORT)}
                  amountDue={invoiceDueAmount}
                  billingAddress={organizationBillingAddress}
                  billingEmail={organizationBillingEmail}
                  billingPhone={organizationBillingPhone}
                  organizationName={organizationName}
                  logo={organizationLogo}
                />
                <InvoicePDFOrganizationHeader
                  organizationName={organizationName}
                  invoiceNote={invoiceNote}
                />
                <InvoicePDFStats
                  amountDue={invoiceDueAmount}
                  dueDate={invoiceDueDate}
                  miles={totalMiles}
                  passengerCount={totalUniqPassengers}
                  payerAddress={ii.ItemPayerBillingAddress}
                  payerName={ii.ItemPayerName}
                  payerContactName={ii.ItemPayerBillingContactName}
                  payerPhone={ii.ItemPayerBillingContactPhone}
                  tripCount={totalTrips}
                  organizationTaxId={organizationTaxId}
                />
                <InvoicePDFTripTable
                  rows={ii.items}
                  selectedInvoiceColumns={selectedInvoiceColumns}
                />
                <InvoicePDFFooter
                  adjustmentAmount={modifiersTotalAmount}
                  invoiceNotes={ii.InvoiceNotes}
                  paymentAmount={paymentsTotalAmount}
                  subTotalAmount={tripsTotalAmount}
                  totalAmount={invoiceDueAmount}
                />
              </View>
            </Page>
            {includePriceSummary && priceSummaryCompatibleLineItems.length ? (
              <Page size="A4" orientation="landscape">
                <View style={[commonStyles.page, { paddingTop: 0 }]}>
                  <InvoicePDFHeader
                    invoiceNumber={ii.InvoiceNo}
                    issueDate={invoiceDate.toLocaleString(DateTime.DATE_SHORT)}
                    amountDue={invoiceDueAmount}
                    billingAddress={organizationBillingAddress}
                    billingEmail={organizationBillingEmail}
                    billingPhone={organizationBillingPhone}
                    organizationName={organizationName}
                    logo={organizationLogo}
                  />
                  <InvoicePDFPriceSummary
                    invoiceNumber={ii.InvoiceNo}
                    items={priceSummaryCompatibleLineItems}
                  />
                </View>
              </Page>
            ) : null}
          </Fragment>
        );
      })}
    </Document>
  );
}
