import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useFeature } from 'src/app/FeatureToggle';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import { DefaultRouteLayout } from '../dispatch';

export function ReportsLayout() {
  const reportsFeatureState = useFeature('reporting');
  const navigate = useNavigate();
  const { isBambi, isAdmin, isOwner } = useUserRoles();

  useEffect(() => {
    if (reportsFeatureState.isLoading || isBambi) {
      return;
    }

    if (!reportsFeatureState.isEnabled || !(isAdmin || isOwner)) {
      navigate('/');
    }
  }, [
    reportsFeatureState.isEnabled,
    navigate,
    reportsFeatureState.isLoading,
    isBambi,
    isAdmin,
    isOwner,
  ]);

  return <DefaultRouteLayout />;
}
