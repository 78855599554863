import { useCallback, useEffect, useState } from 'react';

import {
  NewspaperIcon,
  PlusIcon,
  PrinterIcon,
} from '@heroicons/react/24/outline';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { useFeature } from 'src/app/FeatureToggle';
import { useAppSelector } from 'src/app/store';
import { DeleteModal } from 'src/common/DeleteModal';
import {
  InvoiceRead,
  InvoiceTripsRead,
} from 'src/common/external/bambi-api/bambiApi';
import { FormField } from 'src/common/FormField';
import { Button } from 'src/common/primitives/Button';
import { TripFinderModal } from 'src/features/trip/finder/TripFinderModal';

import { PrintableInvoiceTrigger } from '../print/PrintableInvoice';
import { useExportInvoices } from '../useExportInvoices';
import { useGroupLineItems } from '../useGroupLineItems';
import { HydratedInvoice } from '../useHydratedInvoice';
import { invoiceDefaultValuesFactory } from './defaultValues';
import {
  InvoiceFormContextProvider,
  InvoiceNestedLineItemWithId,
} from './InvoiceFormContext';
import { InvoiceFormEmptyState } from './InvoiceFormEmptyState';
import { InvoiceLineItemContainer } from './InvoiceLineItemContainer';

export function isCreateMode(invoice?: InvoiceRead | null) {
  return !invoice || !invoice.id;
}

export function InvoiceForm() {
  const { control, reset } = useFormContext<HydratedInvoice>();
  const editingInvoice = useAppSelector(
    (state) => state.invoice.editingInvoice
  );
  const hydratedInvoice = useAppSelector(
    (state) => state.invoice.hydratedInvoice
  );

  const {
    fields: lineItems,
    append,
    remove,
    // Updates originate from the field itself
  } = useFieldArray({
    control,
    name: 'line_items',
    keyName: '__id',
  });

  const [showTripSelector, setShowTripSelector] = useState(false);

  const { isEnabled: isPricingFeatureEnabled } =
    useFeature('pricing version 1');

  const handleAddTripLineItem = useCallback(
    (lineItem: InvoiceNestedLineItemWithId) => {
      const hasTrip = lineItems.find(
        (item) => item.trip.id === lineItem.trip.id
      );

      if (hasTrip) {
        return;
      }

      if (isPricingFeatureEnabled) {
        append(lineItem);

        append({
          ...lineItem,
          description: '',
          line_item_type: 'price_summary',
          created_at: new Date().toLocaleString(),
        });
      } else {
        append(lineItem);
      }
    },
    [append, isPricingFeatureEnabled, lineItems]
  );

  // Track any updates to invoice
  useEffect(() => {
    reset(invoiceDefaultValuesFactory(hydratedInvoice ?? null));
  }, [hydratedInvoice, reset]);

  const lineItemsByTrip = useGroupLineItems(lineItems);

  // Since we've grouped the line items, we need to find the correct index.
  const findLineItemIndex = useCallback(
    (lineItem: InvoiceNestedLineItemWithId): number => {
      return lineItems.findIndex((item) => item.__id === lineItem.__id);
    },
    [lineItems]
  );

  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [removingLineItem, setRemovingLineItem] =
    useState<InvoiceNestedLineItemWithId>();

  const { handleExport, isLoading: isFetchingInvoice } = useExportInvoices();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-4">
          <h3 className="text-[24px] font-medium leading-6 text-gray-900">
            {isCreateMode(editingInvoice) ? 'Create' : 'Edit'} Invoice
          </h3>
          <Controller
            control={control}
            name="number"
            rules={{
              required: 'This field is required',
            }}
            render={({ field, fieldState }) => (
              <FormField
                error={fieldState.error?.message}
                type="text"
                inputProps={{
                  id: 'invoice-number',
                  placeholder: 'INV-0001',
                  value: field.value,
                  onChange: field.onChange,
                  required: true,
                  'aria-label': 'Invoice Number',
                }}
              />
            )}
          />
        </div>
        <div className="flex flex-row justify-center gap-2">
          {editingInvoice ? (
            <>
              <PrintableInvoiceTrigger
                invoice={editingInvoice}
                className="flex flex-row gap-1"
              >
                <PrinterIcon className="w-[24px]" />
                Print
              </PrintableInvoiceTrigger>
              <Button
                variant="secondary-outline"
                className="flex flex-row gap-1 border-mint"
                loading={isFetchingInvoice}
                onClick={() => handleExport('condensed', [editingInvoice.id])}
              >
                <NewspaperIcon className="w-6" />
                Download Invoice (PDF)
              </Button>
            </>
          ) : null}
          {/* <Button>Send Invoice</Button> */}
        </div>
      </div>
      <div>
        {showTripSelector ? (
          <TripFinderModal
            open={showTripSelector}
            setOpen={setShowTripSelector}
            onConfirm={(trips) => {
              trips.forEach((trip) => {
                const lineItem = {
                  description: `Trip - ${trip.id}`,
                  amount_cents: trip.input_price_cents ?? 0,
                  // InvoiceTripsRead is a subset of TripRead so this should be okay
                  trip: trip as unknown as InvoiceTripsRead,
                  line_item_type: 'trip',
                };

                handleAddTripLineItem(lineItem as InvoiceNestedLineItemWithId);
              });
              setShowTripSelector(false);
            }}
          />
        ) : (
          <Button onClick={() => setShowTripSelector(true)}>
            <PlusIcon className="w-5" />
            Add Trip(s)
          </Button>
        )}
      </div>
      {lineItems.length === 0 && (
        <InvoiceFormEmptyState onClick={() => setShowTripSelector(true)} />
      )}
      {/* Trips + line items */}
      {/* Group by trip, render this block */}
      <InvoiceFormContextProvider findLineItemIndex={findLineItemIndex}>
        {Object.values(lineItemsByTrip).map((group, i) => {
          return (
            <InvoiceLineItemContainer
              key={`${group[0].trip.id}-${i}`}
              lineItems={group as InvoiceNestedLineItemWithId[]}
              onAddLineItem={(item) => {
                append({
                  ...item,
                  trip: group[0].trip,
                });
              }}
              onDeleteLineItem={(_rowIndex, lineItem) => {
                const itemIndex = findLineItemIndex(
                  lineItem as InvoiceNestedLineItemWithId
                );

                const shouldConfirmClose =
                  lineItem.line_item_type === 'trip' &&
                  lineItemsByTrip[lineItem.trip.id].length > 1;

                if (!shouldConfirmClose) {
                  remove(itemIndex);
                } else {
                  setRemovingLineItem(lineItem as InvoiceNestedLineItemWithId);
                  setDeleteConfirmationModalOpen(true);
                }
              }}
            />
          );
        })}
      </InvoiceFormContextProvider>
      <DeleteModal
        title="Remove trip and related line items?"
        description="Removing this trip will also remove its modifiers, is that okay?"
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        onCancel={() => {
          setDeleteConfirmationModalOpen(false);
          setRemovingLineItem(undefined);
        }}
        onConfirm={() => {
          if (!removingLineItem) {
            setDeleteConfirmationModalOpen(false);
            setRemovingLineItem(undefined);
            return;
          }

          // Find line items related to the trip to remove
          const relatedLineItemIndexes: number[] = [];
          lineItems.forEach((item, index) => {
            if (item.trip.id === removingLineItem.trip.id) {
              relatedLineItemIndexes.push(index);
            }
          });

          remove(relatedLineItemIndexes);

          setDeleteConfirmationModalOpen(false);
          setRemovingLineItem(undefined);
        }}
      />
    </div>
  );
}
