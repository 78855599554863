import { useMemo } from 'react';

import { sumBy } from 'lodash-es';
import { DateTime } from 'luxon';

import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import {
  InvoicePaymentRead,
  InvoiceRefundRead,
} from 'src/common/external/bambi-api/bambiApi';

export function formatDate(date: string) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
}

export function useRefundTooltipText(
  payment: InvoicePaymentRead,
  refunds?: InvoiceRefundRead[]
) {
  const totalRefund = useMemo(
    () => sumBy(refunds ?? [], (r) => r.amount_cents),
    [refunds]
  );

  if (!refunds || !refunds.length) {
    return null;
  }

  // One full refund
  if (refunds.length === 1 && payment.amount_total_cents === 0) {
    return `This payment was completely refunded on: ${formatDate(
      refunds[0].created_at
    )}`;
  }

  // Many refunds, but totals up to payment
  if (refunds.length > 1 && payment.amount_total_cents === 0) {
    return 'The payment was completely refunded across multiple refunds.';
  }

  // Partially refunded
  return `The payment was partially refunded in the amount of: ${defaultCurrencyFormat(
    totalRefund
  )}`;
}
