import type { MobilePlatform, VersionNumber } from './parseAppVersion';

export type LatestAppVersions = {
  [platform in MobilePlatform]: VersionNumber;
};

/**
 * Due to the relative complexity of fetching this data from the app stores properly
 * and adding it to our API, we are hardcoding the latest versions here.
 * Revisit this if the tradeoffs change.
 *
 * This started as a hook to support dynamically fetching the versions and
 * it may be useful to keep the hook interface for a future implemntation.
 *
 * This should be updated and deployed after each mobile release.
 *
 * @returns The latest app versions for iOS and Android.
 */
export const useLatestAppVersions = (): LatestAppVersions | undefined => {
  // Both platforms are likely to be the same
  const sharedVersion: VersionNumber = {
    major: 2,
    minor: 4,
    patch: 0,
    ota: undefined,
  };
  return {
    ios: sharedVersion,
    android: sharedVersion,
  };
};
