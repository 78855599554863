import { DateTime } from 'luxon';

/**
 * Changing dates of trips in the past is not allowed because
 * things get weird with payers when you change dates along
 * with other data integrity issues.
 * @param value
 * @returns string if error, true if valid
 */
export function validateTripDateOnEditingTripInThePast(value: any) {
  if (!value) {
    return 'Trip Date is required';
  }

  const date = DateTime.fromISO(value).startOf('day');
  if (date >= DateTime.local().startOf('day')) {
    return 'Trip Date must be in the past';
  }

  return true;
}
