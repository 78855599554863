import { useEffect } from 'react';

import { UserEventHandler } from '@sendbird/chat';
import {
  sendbirdSelectors,
  useSendbirdStateContext,
} from '@sendbird/uikit-react';
import { useDispatch } from 'react-redux';

import { useAuth } from '../auth/useAuth';
import { setUnreadMessageCount } from './chat.slice';

const useSdkEventHandler = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const globalStore = useSendbirdStateContext();
  const sdkInstance = sendbirdSelectors.getSdk(globalStore);

  useEffect(() => {
    if (!sdkInstance.addUserEventHandler || !auth.currentMembership?.id) {
      return;
    }

    const userEventHandler = new UserEventHandler({
      onTotalUnreadMessageCountUpdated: (totalCount) => {
        dispatch(setUnreadMessageCount(totalCount));
      },
    });

    sdkInstance.addUserEventHandler(
      auth.currentMembership.id,
      userEventHandler
    );

    return () => {
      sdkInstance.removeUserEventHandler(auth.currentMembership?.id || '');
    };
  }, [sdkInstance, auth, dispatch]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // the types are wrong, sdkInstance can be undefined
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      sdkInstance?.disconnect();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [sdkInstance]);
};

export default useSdkEventHandler;
