import { FormField, InputType, SupportedGridSizes } from 'src/common/FormField';
import {
  FormFieldController,
  FormFieldControllerRulesProps,
} from 'src/common/FormField/FormFieldController';

import { HelperText } from './FormFieldHelperText';

export interface SimpleContextFormFieldProps {
  fieldPath: string;
  rules?: FormFieldControllerRulesProps;
  disabled?: boolean;
  label: string;
  placeholder?: string;
  inline?: boolean;
  type?: InputType;
  helperText?: string | HelperText;
  // provides standard way of aligning form fields
  colSpan?: SupportedGridSizes;
}

export function SimpleContextFormField({
  fieldPath,
  rules,
  disabled,
  label,
  inline,
  placeholder,
  type = 'text',
  helperText,
  colSpan,
}: SimpleContextFormFieldProps) {
  return (
    <FormFieldController name={fieldPath} rules={rules}>
      {({ field, fieldState }) => {
        return (
          <FormField
            label={rules?.required ? `${label} *` : label}
            error={fieldState.error?.message?.toString()}
            inputProps={{
              id: fieldPath,
              ...field,
              onChange: field.onChange,
              disabled,
              placeholder,
              'data-testid': fieldPath,
            }}
            {...field}
            type={type}
            inline={inline}
            helperText={helperText}
            colSpan={colSpan}
          />
        );
      }}
    </FormFieldController>
  );
}
