import { StyleSheet } from '@react-pdf/renderer';

import colors from '../theme/colors';

// Create styles
export const commonStyles = StyleSheet.create({
  // Layout/utility
  page: {
    flexDirection: 'column',
    padding: 10,
    fontSize: 12,
  },
  between: {
    justifyContent: 'space-between',
  },
  separatorSm: {
    height: 1,
    width: '100%',
    backgroundColor: colors.mint,
    marginVertical: 4,
  },
  separatorMd: {
    height: 4,
    width: '100%',
    backgroundColor: colors.mint,
    marginVertical: 8,
  },
  hstack: {
    flexDirection: 'row',
    gap: 8,
  },
  vstack: {
    flexDirection: 'column',
    gap: 8,
  },
  fullWidth: { width: '100%' },
  // Text
  strong: {
    fontFamily: 'Helvetica-Bold',
  },
  textSm: {
    fontSize: 8,
  },
  textSmedium: {
    fontSize: 10,
  },
  textMd: {
    fontSize: 12,
  },
  textXl: {
    fontSize: 24,
  },
  textRight: {
    textAlign: 'right',
  },
  upper: {
    textTransform: 'uppercase',
  },
  letterSpacingSm: {
    letterSpacing: 1,
  },
});
