import { useCallback, useMemo, useState } from 'react';

import { Draggable } from '@mobiscroll/react';
import { CheckboxProps } from '@radix-ui/react-checkbox';

import { useAppSelector } from 'src/app/store';
import {
  DispatchTripRead,
  RunBambiRunTripRead,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { CheckBox } from 'src/common/primitives/CheckBox';

import { getCalendarEventStartAndEndTime } from '../DispatchCalendar/getCalendarEventStartAndEndTime';
import { TripCard } from './TripCard/TripCard';

type TripListItemProps = {
  trip: DispatchTripRead | RunBambiRunTripRead | TripRead;
  hideMenu?: boolean;
} & CheckboxProps;

export function TripListItem({
  trip,
  checked,
  onCheckedChange,
  disabled,
  hideMenu = false,
}: TripListItemProps) {
  const [draggable, setDraggable] = useState<HTMLDivElement>();
  const isDragging = useAppSelector(
    (state) => state.dispatcher.calendarEventDragState.isDragging
  );

  const isDraggable = useMemo(() => {
    return (
      // Not a TripRead
      !('timing' in trip && trip.timing) &&
      ['pending', 'requested'].includes(trip.status)
    );
  }, [trip]);
  const setDragElm = useCallback(
    (elm: HTMLDivElement) => {
      if (isDraggable) {
        setDraggable(elm);
      }
    },
    [isDraggable]
  );
  return (
    <div
      className="flex items-center justify-between gap-x-3"
      data-testid={`trip-list-item-${trip.id}`}
    >
      <div>
        <CheckBox
          id={`trip-selection-checkbox-${trip.id}`}
          checked={checked}
          onCheckedChange={(val) => onCheckedChange?.(val)}
          disabled={disabled}
        />
      </div>

      <div ref={setDragElm}>
        <div
          className={`${isDraggable ? 'cursor-grab' : ''} ${
            isDragging ? 'opacity-40' : ''
          }`}
        >
          <TripCard trip={trip} hideMenu={hideMenu} />
        </div>
        {isDraggable && (
          <Draggable
            dragData={{
              id: trip.id,
              // @ts-ignore
              start: getCalendarEventStartAndEndTime(trip).start,
              // @ts-ignore
              end: getCalendarEventStartAndEndTime(trip).end,
              title: trip.passenger.full_name,
              trip: trip,
              bufferBefore:
                'travel_time_minutes' in trip &&
                typeof trip.travel_time_minutes === 'number'
                  ? trip.travel_time_minutes
                  : undefined,
              // TODO: make createEventFromUnassignedTrip util
              color: 'black',
            }}
            element={draggable}
          />
        )}
      </div>
    </div>
  );
}
