import { parse } from 'csv-parse/browser/esm/sync';

export function parseInvoiceExport(records: string, fileName: string) {
  // Pandas export returns Python specific types for JSON which cannot
  // be parsed by the browser, fix here.
  const parsedRecords = parse(records, { columns: true });
  const fixedRecords = parsedRecords.map((record: Record<string, string>) => {
    if (record['PriceSummary']) {
      const fixedSummary = record['PriceSummary']
        .replace(/'/g, '"')
        .replace(/True/g, 'true')
        .replace(/False/g, 'false')
        .replace(/None/g, 'null');
      return {
        ...record,
        PriceSummary: fixedSummary,
      };
    }

    return record;
  });

  return {
    data: {
      records: fixedRecords,
      fileName: fileName.replace(/\.csv$/, ''),
    },
  };
}
