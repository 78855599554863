import { Provider as ReduxProvider } from 'react-redux';

import store from 'src/app/store';
import { configureAuth } from 'src/common/external/aws';
import { PaymentMethodCaptureProvider } from 'src/common/forms/PaymentMethodCaptureForm/PaymentMethodCaptureContext';
import { RequiresConfirmationModal } from 'src/common/modals/RequiresConfirmationModal/RequiresConfirmationModal';
import { ToastConfigProvider } from 'src/common/primitives/Toast';
import { TooltipProvider } from 'src/common/primitives/Tooltip';
import { RequireAuth } from 'src/features/auth/requireAuth';

import { useDatadogLogger } from '../common/external/datadog/useDatadogLogger';
import { Routing } from './app.routes';
import { DelinquentBanner } from './banner/DelinquentBanner';

configureAuth();

function App() {
  useDatadogLogger();
  
  return (
      <div className="App">
        <ReduxProvider store={store}>
          <ToastConfigProvider>
            <TooltipProvider delayDuration={200}>
              <RequireAuth>
                <PaymentMethodCaptureProvider>
                  <DelinquentBanner />
                  <Routing />
                  <RequiresConfirmationModal />
                </PaymentMethodCaptureProvider>
              </RequireAuth>
            </TooltipProvider>
          </ToastConfigProvider>
        </ReduxProvider>
      </div>
  );
}

export default App;
