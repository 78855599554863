import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/store';

import { setSelectedInvoiceRows } from '../../invoice.slice';

interface RowSelectionCellProps {
  id: string;
}

export function RowSelectionCell({ id }: RowSelectionCellProps) {
  const selectedInvoiceRows = useAppSelector(
    (state) => state.invoice.selectedInvoiceRows
  );
  const isChecked: boolean | undefined = selectedInvoiceRows[id];
  const dispatch = useAppDispatch();
  const handleToggleRowSelected = useCallback(() => {
    dispatch(
      setSelectedInvoiceRows({
        ...selectedInvoiceRows,
        [id]: !isChecked,
      })
    );
  }, [dispatch, id, isChecked, selectedInvoiceRows]);

  return (
    <input
      data-testid="select-invoice-checkbox"
      type="checkbox"
      checked={isChecked || false}
      onChange={handleToggleRowSelected}
    />
  );
}
