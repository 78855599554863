import { TilledLogo } from 'src/common/primitives/logos/TilledLogo';

export function WithdrawnTilledMerchantApplication() {
  return (
    <>
      <div
        data-testid="withdrawn-tilled-merchant-application"
        className="flex flex-grow flex-row items-center gap-2"
      >
        <TilledLogo />
        <div>
          Your application was withdrawn. Please contact support if you want to
          start a new application.
        </div>
      </div>
    </>
  );
}
