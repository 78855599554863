import { keyBy } from 'lodash-es';

import {
  MultiSelectContextField,
  MultiSelectContextFieldProps,
} from 'src/common/FormField/MultiSelectContextField';
import { SelectOption } from 'src/common/primitives/Select';

export type InvoiceColumnKey =
  | 'id'
  | 'date'
  | 'miles'
  | 'price'
  | 'pickup_location'
  | 'dropoff_location'
  | 'space_type'
  | 'service_type'
  | 'passenger'
  | 'passenger_dob'
  | 'status'
  | 'completion_date'
  | 'payer_name'
  | 'payer_member_id'
  | 'passenger_first_name'
  | 'passenger_last_name';

export type InvoiceSelectedOptions = (
  | SelectOption<InvoiceColumnKey>
  | InvoiceColumnKey
)[];

export const columnOptions: SelectOption<InvoiceColumnKey>[] = [
  {
    label: 'Trip ID',
    value: 'id',
  },
  {
    label: 'Trip Date',
    value: 'date',
  },
  {
    label: 'Trip Miles',
    value: 'miles',
  },
  {
    label: 'Trip Price',
    value: 'price',
  },
  {
    label: 'Pickup Location',
    value: 'pickup_location',
  },
  {
    label: 'Dropoff Location',
    value: 'dropoff_location',
  },
  {
    label: 'Space Type',
    value: 'space_type',
  },
  {
    label: 'Service Type',
    value: 'service_type',
  },
  {
    label: 'Passenger First Name',
    value: 'passenger_first_name',
  },
  {
    label: 'Passenger Last Name',
    value: 'passenger_last_name',
  },
  {
    label: 'Passenger DOB',
    value: 'passenger_dob',
  },
  {
    label: 'Status',
    value: 'status',
  },
  {
    label: 'Completion Date',
    value: 'completion_date',
  },
  {
    label: 'Payer Name',
    value: 'payer_name',
  },
  {
    label: 'Payer Passenger ID',
    value: 'payer_member_id',
  },
];

export const columnOptionsMap = keyBy(columnOptions, 'value');

export function InvoiceColumnSelect(
  props: Omit<
    MultiSelectContextFieldProps,
    'options' | 'searchable' | 'allowNew'
  >
) {
  return (
    <MultiSelectContextField
      {...props}
      rules={{
        validate(value) {
          return value.length >= 1 || 'Select at least one column';
        },
      }}
      options={columnOptions}
      searchable={false}
      allowNew={false}
    />
  );
}
