import { useCallback } from 'react';

import { PencilIcon } from '@heroicons/react/24/outline';
import { ReloadIcon } from '@radix-ui/react-icons';

import { useAppDispatch } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import {
  setSelectedDriverProfile,
  setShowDriverProfileModal,
  setShowRestoreDriverProfileModal,
} from '../team.slice';
import { DriverProfile } from './DriverProfile';

export function DriverProfileRowActions({
  driverProfile,
}: {
  driverProfile: DriverProfile;
}) {
  const dispatch = useAppDispatch();
  const { isRestrictedSupport } = useUserRoles();

  const handleEditDriverProfile = useCallback(() => {
    if (isRestrictedSupport) return;
    dispatch(
      setSelectedDriverProfile({
        ...driverProfile,
      })
    );
    dispatch(setShowDriverProfileModal(true));
  }, [dispatch, driverProfile, isRestrictedSupport]);

  const handleRestoreDriverProfile = useCallback(() => {
    if (isRestrictedSupport) return;
    dispatch(
      setSelectedDriverProfile({
        ...driverProfile,
      })
    );
    dispatch(setShowRestoreDriverProfileModal(true));
  }, [dispatch, driverProfile, isRestrictedSupport]);

  return (
    <div className="w-[24px]">
      {driverProfile.deleted_at ? (
        <Button
          onClick={handleRestoreDriverProfile}
          variant="ghost"
          disabled={isRestrictedSupport}
          dataTestId="restore-driver-profile-button"
        >
          <ReloadIcon className="h-6 w-6 cursor-pointer hover:text-mint" />
        </Button>
      ) : (
        <Button
          onClick={handleEditDriverProfile}
          variant="ghost"
          disabled={isRestrictedSupport}
          dataTestId="edit-driver-profile-button"
        >
          <PencilIcon className="h-6 w-6 cursor-pointer hover:text-mint" />
        </Button>
      )}
    </div>
  );
}
