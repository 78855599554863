import { useMemo } from 'react';

import { Text, View } from '@react-pdf/renderer';
import { DateTime } from 'luxon';

import { formatPhoneNumber } from 'src/common/formatPhoneNumber';
import { commonStyles } from 'src/common/pdf/common.styles';
import { PDFAddressBlock } from 'src/common/pdf/PDFAddressBlock';

import { currencyFormatter } from '../forms/cells/utils';

interface InvoicePDFStatsProps {
  payerContactName: string;
  payerName: string;
  payerPhone: string;
  payerAddress: string;
  tripCount: number;
  passengerCount: number;
  miles: number;
  dueDate: string;
  amountDue: number;
  organizationTaxId?: string;
}
export function InvoicePDFStats({
  payerAddress,
  payerName,
  payerPhone,
  payerContactName = '',
  dueDate,
  miles,
  passengerCount,
  tripCount,
  amountDue,
  organizationTaxId,
}: InvoicePDFStatsProps) {
  const dueDates = useMemo(() => {
    if (amountDue <= 0) {
      return;
    }

    // Compare today at midnight to duedate at midnight
    const today = DateTime.now().startOf('day');
    const comparableDueDate = DateTime.fromFormat(dueDate, 'M/dd/yyyy').startOf(
      'day'
    );

    return {
      isPastDue: today > comparableDueDate,
      isDueToday: today.equals(comparableDueDate),
    };
  }, [dueDate, amountDue]);

  const isPastDue = dueDates?.isPastDue;
  const isDueToday = dueDates?.isDueToday;

  return (
    <View style={[commonStyles.hstack]}>
      <View style={[commonStyles.vstack, { width: '100%' }]}>
        <View style={commonStyles.separatorSm} />
        <View>
          <Text
            style={[
              commonStyles.textMd,
              commonStyles.upper,
              commonStyles.strong,
              commonStyles.letterSpacingSm,
            ]}
          >
            Bill To
          </Text>
        </View>
        <View style={{ gap: 3 }}>
          <View style={{ gap: 1 }}>
            <Text style={commonStyles.textSmedium}>{payerName}</Text>
            {payerContactName ? (
              <Text style={commonStyles.textSmedium}>
                ATTN: {payerContactName}
              </Text>
            ) : null}
          </View>
          <Text style={commonStyles.textSmedium}>
            {formatPhoneNumber(payerPhone)}
          </Text>
          <View style={{ gap: 1 }}>
            <PDFAddressBlock
              address={payerAddress}
              style={commonStyles.textSmedium}
            />
          </View>
        </View>
      </View>
      <View style={[commonStyles.vstack, { width: '100%' }]}>
        <View style={commonStyles.separatorSm} />
        <View>
          <Text
            style={[
              commonStyles.textMd,
              commonStyles.upper,
              commonStyles.strong,
              commonStyles.letterSpacingSm,
            ]}
          >
            Details
          </Text>
        </View>
        <View style={{ gap: 1 }}>
          <Text style={commonStyles.textSmedium}>{tripCount} trips</Text>
        </View>
      </View>
      <View style={[commonStyles.vstack, { width: '100%' }]}>
        <View style={commonStyles.separatorSm} />
        <View>
          <Text
            style={[
              commonStyles.textMd,
              commonStyles.upper,
              commonStyles.strong,
              commonStyles.letterSpacingSm,
            ]}
          >
            Payment
          </Text>
        </View>
        <View style={{ gap: 1 }}>
          {organizationTaxId ? (
            <Text style={commonStyles.textSmedium}>
              Tax ID:{' '}
              <Text style={commonStyles.strong}>{organizationTaxId}</Text>
            </Text>
          ) : null}
          <Text style={commonStyles.textSmedium}>
            Due date: <Text style={commonStyles.strong}>{dueDate}</Text>
          </Text>
          <Text style={commonStyles.textSmedium}>
            Amount due:{' '}
            <Text style={commonStyles.strong}>
              {currencyFormatter(amountDue)}
            </Text>
          </Text>
          {isPastDue ? (
            <Text
              style={[
                commonStyles.textSmedium,
                commonStyles.strong,
                { color: 'red' },
              ]}
            >
              PAST DUE
            </Text>
          ) : null}
          {isDueToday ? (
            <Text style={[commonStyles.textSmedium, commonStyles.strong]}>
              DUE TODAY
            </Text>
          ) : null}
        </View>
      </View>
    </View>
  );
}
