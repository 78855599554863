import { PropsWithChildren } from 'react';

import * as Dialog from '@radix-ui/react-dialog';

import { TimeDriftBanner } from 'src/app/banner/TimeDriftBanner';
import { useAppDispatch, useAppSelector } from 'src/app/store';
import { ModalOnPointerDownOutsideHandler } from 'src/common/primitives/Modal/ModalOnPointerDownOutsideHandler';

import { addATrip } from './addATrip.slice';

export function AddATripFlowDialog({ children }: PropsWithChildren) {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.addATrip.isOpen);

  return (
    <Dialog.Root
      open={isOpen}
      onOpenChange={(newValue) => {
        if (!newValue) {
          dispatch(addATrip.actions.close());
        }
      }}
    >
      <Dialog.Portal>
        <Dialog.Content
          className="fixed inset-0 z-20 h-full w-full translate-x-0 overflow-hidden data-[state=open]:animate-tripFormSlideIn data-[state=closed]:animate-tripFormSlideOut"
          onPointerDownOutside={ModalOnPointerDownOutsideHandler}
        >
          <div className="pointer-events-auto relative flex h-full flex-col bg-white shadow-xl">
            <TimeDriftBanner />
            {children}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
