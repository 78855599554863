import { InvoiceTripsRead } from 'src/common/external/bambi-api/bambiApi';
import { PriceSummaryTable } from 'src/features/pricing/PriceSummaryModal/PriceSummaryTable/PriceSummaryTable';

interface TripPriceSummaryCellProps {
  summary: InvoiceTripsRead['price_summary'];
}
export function TripPriceSummaryCell({ summary }: TripPriceSummaryCellProps) {
  return (
    <div>
      <PriceSummaryTable
        summary={summary}
        includeFooter={false}
        headerClassName="text-left px-2"
        cellClassName="px-2"
        centerSummaryName={false}
        rightAlignPrice
      />
      <div className="mt-2 text-right font-bold">Trip Total</div>
    </div>
  );
}
